import axios from 'axios';
// axios.defaults.baseURL = 'http://localhost:3012'; //local
// axios.defaults.baseURL = 'http://44.230.62.131:3012'; //dev
// axios.defaults.baseURL = 'http://54.252.99.222:3012'; //live
axios.defaults.baseURL = 'https://api.storval.net/'; //live

let isErrorRedirected = false;

axios.interceptors.request.use(
    async (config) => {
        const authUser = localStorage.getItem('sv-authUser');
        const user = JSON.parse(authUser);
        if (user) {
            const token = user.token;
            config.headers.accessToken = token;            
            if(
                (config.url.includes('/api/users/updateProfile')) || 
                (config.url.includes('/api/uploadStormanReportData')) || 
                (config.url.includes('/api/uploadSiteLinkReportData'))
            ){ 
                config.headers= {"Content-Type": "multipart/form-data",'x-access-token': token};
            }
            else{
                config.headers= {"Content-Type": "application/json",'x-access-token': token};
            }
        }
        else{
            config.headers= {"Content-Type": "application/json"};
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
// Add the response interceptor to handle errors
axios.interceptors.response.use(
    (response) => response, 
    (error) => {
        if (error.response && error.response.status === 403) {
            window.location.href = '/unauthorized';
            // window.location.href = '/stor_val/unauthorized';
        }
        if (error.response && (error.response.status === 404)) {
            // console.log('dfhhhhhhhhhhhhhhhhhhh')
            window.location.href = '/404';
            // window.location.href = '/stor_val/404';
        }
        return Promise.reject(error);
    }
);
