
import { Link } from "react-router-dom";
import Header from "../Header/Header";

export default function ContactUs(){
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);

    return(
        <>
        <Header pageTitle="Contact Us" />      
        <div className="container-xxl flex-grow-1 container-p-y">
            <div className="card">                
                <h3>Coming Soon!</h3>
                <Link to={ user 
                        ? user.role === "SuperAdmin" ? "/" 
                        : `/portfolio-dashboard/${user.portfolio.id}`
                    :"/"
                    }> Home
                </Link>
            </div>
        </div>
        </>
    );
}