import { Routes, Route} from 'react-router-dom';
import PublicRoutes from "./Components/Routes/PublicRoutes";
import ProtectedRoutes from "./Components/Routes/ProtectedRoutes";
import Login from './Components/Login/Login';
import Verify from './Components/Login/Verify';
import SignUp from './Components/SignUp/SignUp';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import PortfolioDashboard from './Components/PortfolioDashboard/PortfolioDashboard';
import FacilityDashboard from './Components/FacilityDashboard/FacilityDashboard';
import AccountSettings from './Components/AccountSettings/Profile';
import Users from './Components/Users/Users';
import ChangePassword from './Components/AccountSettings/ChangePassword';
import Profile from './Components/AccountSettings/Profile';
import ClientInput from './Components/Users/ClientInput';
import AdminInput from './Components/Users/AdminInput';
import OperatingExpenses from './Components/OperatingExpenses/OperatingExpenses';
import NonStorageInputSheet from './Components/NonStorageInputSheet/NonStorageInputSheet';
import ApiData from './Components/AdminPages/ApiData';
import ContactUs from './Components/PublicPages/ContactUs';
import StaticValuation from './Components/AdminPages/StaticValuation';
import BuildUpValuation from './Components/AdminPages/BuildUpValuation';
import CashFlow from './Components/AdminPages/CashFlow';
import CashflowOutput from './Components/AdminPages/CashflowOutput';
import SensitivityOutput from './Components/AdminPages/SensitivityOutput';
import './App.css';
import "./intercepter";
import FacilityListing from './Components/FacilityListing/FacilityListing';
import AddFacility from './Components/FacilityListing/AddFacility';
import EmailAssistance from './Components/AccountSettings/EmailAssistance';
import PageNotFound from './Components/PublicPages/PageNotFound';
import Unauthorized from './Components/PublicPages/Unauthorized';
import { useEffect } from 'react';
import Portfolios from './Components/PortfolioDashboard/List';
import AccountLocked from './Components/PublicPages/AccountLocked';

function App() {
  const events = ["load","mousemove","mousedown","click","scroll","keypress"];
  let timer;
  
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 1800000); // 60000 = 1min
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  const logoutAction = () => {
    localStorage.clear();
    // window.location.pathname = "/login";
    // window.location.pathname = "/stor_val/login";
    window.location.pathname = "/";
  };

  return (
      <Routes>
        <Route element={<ContactUs />} path="/contact-us" />
          <Route path="/" element={<PublicRoutes />}>
          <Route element={<Login />} path="/" />
          <Route element={<Login />} path="/login" />
          <Route element={<Verify />} path="/verify" />
          <Route element={<SignUp />} path="/sign-up" />
          <Route element={<ForgotPassword />} path="/forgot-password" />
          <Route element={<AccountLocked />} path="/request-unlock/:unlockRequestToken" />
        </Route>

        <Route path="/" element={<ProtectedRoutes />}>
          <Route element={<PortfolioDashboard />} path="/portfolio-dashboard/:portfolioId" />
          <Route element={<FacilityDashboard />} path="/facility-dashboard/:queryParamFacilityId" />
          <Route element={<Users />} path="/users" />
          <Route element={<Portfolios />} path="/portfolios" />
          <Route element={<Profile />} path="/profile" />
          <Route element={<EmailAssistance />} path="/email_assistance" />
          <Route element={<AccountSettings />} path="/account-settings" />
          <Route element={<ChangePassword />} path="/change-password" />
          <Route element={<ClientInput />} path="/client_input" />
          <Route element={<FacilityListing />} path="/facility_listing" />
          <Route element={<AddFacility />} path="/facility" />
          <Route element={<AdminInput />} path="/admin_input" />

          <Route element={<OperatingExpenses />} path="/operating_expenses" />
          <Route element={<NonStorageInputSheet />} path="/non_storage_income" />
          <Route element={<ApiData />} path="/api_data" />
          <Route element={<StaticValuation />} path="/static_valuation" />
          <Route element={<BuildUpValuation />} path="/buildUp_valuation" />

          <Route element={<CashFlow />} path="/cash_flow" />
          <Route element={<SensitivityOutput />} path="/sensitivity_output" />
          <Route element={<CashflowOutput />} path="/cashflow_output" />

        </Route>

        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        
      </Routes>
  );
}

export default App;