import Sidebar from "../Sidebar/Sidebar";
import Footer from "../Footer/Footer";

export const Layout = (props) => {
    const {children} = props    

    return(
        <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
            <Sidebar />
            <div className="layout-page">
                <div className="content-wrapper">
                    {children}                    
                    <Footer />
                </div>
            </div>
        </div>
    </div>
    );
}