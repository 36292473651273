import { useState } from "react";
import { Link } from "react-router-dom";
import ContactUs from "./ContactUsPopup";

export default function Footer(){
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const [showContactUsPopup, setShowContactUsPopup] = useState(false);
    return(  
        <>   
         {/* Footer */}
            <div className="container-xxl">
            <section className="Rj-wrapper Rj-theme-bg">
                <div className="container-xxl">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-xl-8 col-12 align-items-center d-md-flex text-center text-md-start">
                            <Link to={user.role === "SuperAdmin" ? "/" : `/portfolio-dashboard/${user.portfolio.id}`}>
                                <img src={process.env.REACT_APP_BASE_URL+"assets/img/storval-logo-invert.png"} alt="" />
                            </Link>
                        </div>
                        <div className="col-md-4 text-center text-md-end">
                            {/* <Link to="/contact-us"><button type="button" className="btn btn-primary">CONTACT US</button></Link> */}
                            <button type="button" className="btn btn-primary" onClick={() => setShowContactUsPopup(true)}>CONTACT US</button>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="Rj-footer">
                <div className="container-xxl">
                    <footer>
                        <div className="row">
                            <div className="col-6 col-md-4 mb-3">
                                <h5>QUICK LINKS</h5>
                                <ul className="nav flex-column">
                                    <li><Link to={user.role === "SuperAdmin" ? "/" : `/portfolio-dashboard/${user.portfolio.id}`}>SOFTWARE</Link></li>
                                    <li><Link to={user.role === "SuperAdmin" ? "/" : `/portfolio-dashboard/${user.portfolio.id}`}>NEWS</Link></li>
                                    <li><Link to="/contact-us">CONTACT</Link></li>
                                </ul>
                            </div>

                            <div className="col-6 col-md-4 mb-3">
                                <h5>CONTACT US</h5>
                                <ul className="nav flex-column">
                                    <li><Link>AUSTRALIA / NEW ZEALAND</Link></li>
                                    <li><Link to="tel:+61039070297">TEL: +61 (0) 3 9070 2979</Link></li>
                                    <li><Link to="mailto:TEAM@STORVAL.COM.AU">EMAIL: TEAM@STORVAL.COM.AU</Link></li>
                                </ul>
                            </div>

                            <div className="col-6 col-md-4 mb-3">
                                <h5>FOLLOW</h5>
                                <ul className="nav flex-column">
                                    <li><Link to={user.role === "SuperAdmin" ? "/" : `/portfolio-dashboard/${user.portfolio.id}`}><img src={process.env.REACT_APP_BASE_URL+"assets/img/linkedin.png"} alt="linkedin" /></Link></li>
                                </ul>
                            </div>
                        </div>

                        <div className="d-flex flex-column flex-sm-row justify-content-center mt-4">
                            <p className="mb-0">© Copyright Storval {new Date().getFullYear()} | Powered by FD Digital</p>
                        </div>
                    </footer>
                    {/* <div className="mb-2 mb-md-0">
                    ©
                    <script>
                        document.write(new Date().getFullYear());
                    </script>
                    , Powered by <Link to="/" target="_blank">
                        Storeval Application</Link>
                </div>
                <div>
                    <Link href="privacy-policy.php" className="footer-link me-4" target="_blank">Privacy & Policy</Link>
                </div> */}
                </div>
            </footer>
            
            </div>
            <ContactUs 
                showContactUsPopup={showContactUsPopup}
                setShowContactUsPopup={setShowContactUsPopup} 
            />
        {/* / Footer */}
        </>
    )
}