import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import html2pdf from 'html2pdf.js';
import FacilityPDF from "./Report";
import Loader from "../Loader/Loader";
import Header from "../Header/Header";
import { Layout } from "../Layout/Layout";
import HistoricValueChart from "./charts/historicValue";
import HistoricRevenueChart from "./charts/historicRevenue";
import ArrowTooltips from "../PortfolioDashboard/PopHover/ToolTip";
import RequestAssistance from "../PortfolioDashboard/RequestAsssistancePopup";

const trailEmptyRowAfter = [
    "Occupancy (by area) across entire NSA",
    "Discount From Rack Rate",
    "'Other Income' % of Gross Revenue",
    "Monthly Storage Revenue",
    "Non-Storage Income",
    "Total Gross Revenue",
    "Annualised Total Revenue",
    "Adopted Total Expenses",
    "Adopted Net Operating Profit",
    "Applied Capitalisation Rate",
    "Core Indicative Market Value",
    "Other 'below the line' adjustment",
    // "Adopt Rounded Estimated Value",
];

const borderNoneForAverages = [
    "Occupancy (by area) across entire NSA",
    "Discount From Rack Rate",
    "'Other Income' % of Gross Revenue"
]
const highlightHeadingsBackground = [
    "Occupancy (by area) across entire NSA",
    "Effective Storage Unit Revenue",
    "Annualised Average Storage Fee Rate ($/m²)",
    "Monthly Storage Revenue",
    "Total Gross Revenue",
    "Annualised Total Revenue",
    "Adopted Total Expenses",
    "Adopted Net Operating Profit",
    "Applied Capitalisation Rate",
    "Core Indicative Market Value",
    "Adopt Rounded Estimated Value"
]

const highlightRow = [
    "Area Occupied (m²)",
    "Effective Storage Unit Revenue",
    "Other Income",
    "Monthly Storage Revenue"
]

const noFormatHeadings = [
    "Total Net Storage Area (m²)",
    "Area Occupied (m²)",
    "WALE (Years ) by Lettable Area"
];

const feeRateHeadings = [
    "Monthly Average Storage Fee Rate ($/m²)",
    "Annualised Average Storage Fee Rate ($/m²)"
]

const highlightTextWithRed = [
    "Adopted Operating Expenses",
    "Adopted Management Fee",
    "Adopted Total Expenses",
    "Storage Revenue Stabilisation Adjustment",
    "Non-Storage Income Rental Adjustment",
    "Cost of Expansion",
    "Capital Expenditure",
]

const showValuesInPercent = [
    "Occupancy (by area) across entire NSA",
    "Discount From Rack Rate",
    "'Other Income' % of Gross Revenue",
    "% Gross Revenue",
    "Profit Margin",
    "Applied Capitalisation Rate",
    "% Passing Revenue of Mature Revenue",
    "Profit Margin",
]

const adjustedHeadings = [
    "% Gross Revenue",
    "Profit Margin",
    "$/m² of NSA",
    "Capital Value Rate $/m² of NSA",
    "WALE (Years ) by Lettable Area"
]

export default function FacilityDashboard() {
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    let { queryParamFacilityId } = useParams();
    const navigate = useNavigate();
    const [currentFacility, setCurrentFacility] = useState({});
    const [facilityValuation, setFacilityValuation] = useState([]);
    const [currentYearApiData, setCurrentYearApiData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const [historicValueFilters, setHistoricValueFilters] = useState('monthly');
    const [historicRevenueFilters, setHistoricRevenueFilters] = useState('monthly');
    const [previousMonthValuationData, setPreviousMonthValuationData] = useState({})

    const portfolioId = user?.portfolio?.id
        || (localStorage.getItem('selectedPortfolio')
            ? parseInt(localStorage.getItem('selectedPortfolio'))
            : null);

    let facilityId = localStorage.getItem('selectedFacility')
        ? parseInt(localStorage.getItem('selectedFacility'))
        : queryParamFacilityId;

    const adoptedRoundedEstimatedValue = facilityValuation.find(value => value.heading === 'Adopt Rounded Estimated Value')?.adoptInValuation || 0;
    const actualTotalNetStorageArea = facilityValuation.find(value => value.heading === 'Total Net Storage Area (m²)')?.actual || 0;
    const actualAreaOccupied = facilityValuation.find(value => value.heading === 'Area Occupied (m²)')?.actual || 0;
    const averageUnitSize = (parseFloat(actualTotalNetStorageArea) !== 0 && parseFloat(currentYearApiData && currentYearApiData.length > 0 ? currentYearApiData[0].totalUnits : 0) !== 0) ? (parseFloat(actualTotalNetStorageArea) / parseFloat(currentYearApiData && currentYearApiData.length > 0 ? currentYearApiData[0].totalUnits : 0)) : 0;
    const averageUnitSizeOccupied = (parseFloat(actualAreaOccupied) !== 0 && parseFloat(currentYearApiData && currentYearApiData.length > 0 ? currentYearApiData[0].unitsOccupied : 0) !== 0) ? (parseFloat(actualAreaOccupied) / parseFloat(currentYearApiData && currentYearApiData.length > 0 ? currentYearApiData[0].unitsOccupied : 0)) : 0;

    const wALEByLettableArea = facilityValuation.find(value => value.heading === 'WALE (Years ) by Lettable Area')?.adoptInValuation || 0;
    const percentageGrossRevenue = facilityValuation.find(value => value.heading === '% Gross Revenue')?.adoptInValuation || 0;
    const profitMargin = facilityValuation.find(value => value.heading === 'Profit Margin')?.adoptInValuation || 0;
    const perMetreSquareOfNSA = facilityValuation.find(value => value.heading === '$/m² of NSA')?.adoptInValuation || 0;
    const capitalValueRatePerMetreSquareOfNSA = facilityValuation.find(value => value.heading === 'Capital Value Rate $/m² of NSA')?.adoptInValuation || 0;

    useEffect(() => {
        if(queryParamFacilityId !== '' && Number.isInteger(queryParamFacilityId)){
            localStorage.setItem('selectedFacility', queryParamFacilityId)
        }
    }, [queryParamFacilityId])

    useEffect(() => {
        facilityId = localStorage.getItem('selectedFacility') ? parseInt(localStorage.getItem('selectedFacility')) : facilityId;
        if (facilityId) {
            getFacilityDetails(facilityId);
            getPreviousMonthValuationData(facilityId);
        }
    }, [facilityId]);

    const getFacilityDetails = async (facilityId) => {
        try{
            const response = await axios.get(`api/facility?id=${facilityId}`);
            if(response.data.success){
                const result = response.data.data;
                setCurrentFacility(result);
                if (result.adoptedAssessmentType) {
                    findFacilityValuationData(facilityId, result.adoptedAssessmentType);
                }
            }
            else{
                Swal.fire(response.data.message, '', "error");
            }
        }
        catch (err){
            Swal.fire("Error", `Error in getting Facility Details: ${err.message}`, "error");
        }        
    }

    const getPreviousMonthValuationData = async (facilityId) => {
        const repsonse = await axios.get(`/api/facility/previousMonthValuationData?facilityId=${facilityId}`);
        setPreviousMonthValuationData(repsonse.data.data);
    }

    const findFacilityValuationData = async (facilityId, adoptedAssessmentType) => {
        try {
            const response = await axios.get(`/api/facility/findFacilityValuationData?facilityId=${facilityId}&adoptedAssessmentType=${adoptedAssessmentType}`);
            let result = response.data;
            if (result.success) {
                setFacilityValuation(result.data);
                setCurrentYearApiData(result.currentYearApiData);
            } else {
                Swal.fire("Error", result.message, "info");
            }
        } catch (err) {
            console.log(err);
            Swal.fire("Error", err.message || err, "error");
        }
    };

    const formatterWithoutDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const formatterWithDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatValue = (value) => {
        const numericValue = parseFloat(value);
        const hasSixDigitsBeforeDecimal = Math.floor(Math.abs(numericValue)).toString().length >= 3;

        return hasSixDigitsBeforeDecimal
            ? formatterWithoutDecimals.format(value)
            : formatterWithDecimals.format(value);
    };

    const facilityPDFRef = useRef(null);
    const [isPdfGenerating, setIsPdfGenerating] = useState(false);

    const handleGeneratePdf = async () => {
        setIsPdfGenerating(true);
    };

    useEffect(() => {
        if (isPdfGenerating) {
            setLoading(true)
            setTimeout(() => {
                const element = facilityPDFRef.current;
                if (!element) {
                    console.error('Ref not set correctly');
                    setIsPdfGenerating(false);
                    setLoading(false);
                    return;
                }

                const opt = {
                    margin: 0,
                    filename: 'FacilityPDF.pdf',
                    image: { type: 'jpeg', quality: 0.98 },
                    html2canvas: { scale: 2 },
                    jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
                };

                console.log('Generating PDF for element:', element);
                html2pdf().from(element).set(opt).outputPdf('blob').then((pdfBlob) => {
                    const pdfUrl = URL.createObjectURL(pdfBlob);
                    window.open(pdfUrl, '_blank');
                    setIsPdfGenerating(false);
                    setLoading(false);
                }).catch((err) => {
                    console.error('PDF generation error:', err);
                    setIsPdfGenerating(false);
                    setLoading(false);
                });
            }, 1000);
        }
    }, [isPdfGenerating]);

    const annualisedTotalRevenueIndex = facilityValuation.findIndex(
        (item) => item.heading === 'Annualised Total Revenue'
    );

    const calculateFacilityValuation = async (facilityId) => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/calculateFacilityValuation?facilityId=${facilityId}`);
            setLoading(false)
            let result = response.data;
            if (result.success) {
                console.log("response.data.data", response.data);
            } else {
                Swal.fire("Facility Details Missing!", result.message, "info");
            }
        } catch (err) {
            setLoading(false)
            Swal.fire("Error", err.message, "error");
        }
    };

    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <div id="facility-dashboard">
                    {/* Public Storage Northpoint */}
                    <Header
                        pageTitle={currentFacility?.name ? currentFacility.name : ""}
                        handleGeneratePdf={handleGeneratePdf}
                        verificationStatus={currentFacility?.RecommendValuationIsVerified || ''}
                        refreshData={() => calculateFacilityValuation(currentFacility?.id)}
                    />
                    <div className="container-xxl flex-grow-1 container-p-y" style={{ backgroundColor: "#f6f6f6" }}>
                        <div className="tab-content p-0" id="myTabContent">
                            <div className="tab-pane fade show active" id="aud" role="tabpanel" aria-labelledby="aud-tab">
                                <div className="row mb-3">
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-20">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body ss-cardBody">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="d-block fw-12 mb-0">Property Summary</h6>
                                                    <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span>
                                                </div>
                                                <div className="card-details">
                                                    <p>Address: {currentFacility?.address || 'N/A'}</p>
                                                    <p>Title: {currentFacility?.titleReference || 'N/A'}</p>
                                                    <p>Ownership: {currentFacility?.interestValued || 'N/A'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-20">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body ss-cardBody">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="d-block fw-12 mb-0">Estimated Value</h6>
                                                    <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span>
                                                </div>
                                                {/* <div className="d-flex align-items-center mt-27"> */}
                                                    <div className="card-amount">
                                                        {formatValue(adoptedRoundedEstimatedValue) + " " + currentFacility?.currency || 'N/A'} 
                                                    </div>
                                                    <div className="d-flex align-items-center card-details">
                                                        <p className="me-2">From last month:</p>
                                                        <p className={`card-percentage fw-700 fs-color-${previousMonthValuationData && 
                                                            parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue) !== 0 &&     adoptedRoundedEstimatedValue !== 0 && (((adoptedRoundedEstimatedValue - parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue)) / (parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue) || 1)) * 100) > 0
                                                            ? 'green' : 'red'}`}>
                                                            {previousMonthValuationData && parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue) !== 0 && adoptedRoundedEstimatedValue !== 0
                                                                ? ((adoptedRoundedEstimatedValue - parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue)) / (parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue) || 1)) * 100 > 0
                                                                    ? `+${(((adoptedRoundedEstimatedValue - parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue)) / 
                                                                        parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue)) * 100).toFixed(2)}`
                                                                    : `${(((adoptedRoundedEstimatedValue - parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue)) / 
                                                                        parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue)) * 100).toFixed(2)}`
                                                                : 0}% 
                                                        </p>
                                                    </div>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-20">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body ss-cardBody">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="d-block fw-12 mb-0">Optimisation Review Recommended</h6>
                                                    <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span>
                                                </div>
                                                <div className="ss-flex mt-27">
                                                    <div
                                                        className={`${currentFacility?.RecommendValuationIsVerified === "Yes" ? "text-danger" : ""} card-Allow`}
                                                    >{currentFacility?.RecommendValuationIsVerified || ''}</div>
                                                    <div>
                                                        <button type="button" className="btn btn-primary ms-2" onClick={() => setShow(true)}>Request Review</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-20">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body ss-cardBody">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="d-block fw-12 mb-0">Trading Status</h6>
                                                    <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span>
                                                </div>
                                                <div className="card-details">
                                                    <div className="ss-flex">
                                                        <p>Is trading at Maturity?</p>
                                                        <p className="fw-700 fs-right">{currentFacility?.isFacilityTradingAtMaturity || ''}</p>
                                                    </div>
                                                    <div className="ss-flex">
                                                        <p>Expected Maturity date</p>
                                                        <p className="fw-700 fs-right">{moment().add(parseInt(currentFacility?.monthRevenueReachesMaturity), 'months').format('DD-MMM-YY')}</p>
                                                    </div>
                                                    <div className="ss-flex">
                                                        <p>No. of months to Maturity</p>
                                                        <p className="fs-right">{parseInt(currentFacility?.monthRevenueReachesMaturity) || 0}</p>
                                                    </div>
                                                    <div className="ss-flex">
                                                        <p>Expansion included?</p>
                                                        <p className="fs-right">{currentFacility?.isFacilityExpandedIn12Months || ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-20">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body ss-cardBody">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="d-block fw-12 mb-0">Facility Units</h6>
                                                    <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span>
                                                </div>
                                                <div className="card-details">
                                                    <div className="ss-flex">
                                                        <p>Total no. of units</p>
                                                        <p className="fw-700 fs-right">{currentYearApiData?.[0]?.totalUnits ?? 0}</p>
                                                    </div>
                                                    <div className="ss-flex">
                                                        <p>Total no. of units occupied</p>
                                                        <p className="fs-right">{currentYearApiData?.[0]?.unitsOccupied ?? 0}</p>
                                                    </div>
                                                    <div className="ss-flex">
                                                        <p>Avg unit size</p>
                                                        <p className="fw-700 fs-right">{averageUnitSize.toFixed(2)}</p>
                                                    </div>
                                                    <div className="ss-flex">
                                                        <p>Avg unit size occupied</p>
                                                        <p className="fs-right">{averageUnitSizeOccupied.toFixed(2)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-20">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body ss-cardBody">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="d-block fw-12 mb-0">Net Storage Area</h6>
                                                    <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span>
                                                </div>
                                                <div className="card-details">
                                                    <div className="ss-flex">
                                                        <p>Total Net Storage Area</p>
                                                        <p className="fs-right">
                                                            {parseFloat(facilityValuation?.[0]?.adoptInValuation ?? 0).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                        </p>
                                                    </div>
                                                    <div className="ss-flex">
                                                        <p>Area occupied</p>
                                                        <p className="fs-right">
                                                            {parseFloat(facilityValuation?.[1]?.adoptInValuation ?? 0).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                        </p>
                                                    </div>
                                                    <div className="ss-flex">
                                                        <p>Currency occupied by area</p>
                                                        <p className="fw-700 fs-right">
                                                            {(facilityValuation?.[0]?.adoptInValuation ?? null) && (facilityValuation?.[1]?.adoptInValuation ?? null)
                                                                ? (parseFloat(facilityValuation[1].adoptInValuation) / parseFloat(facilityValuation[0].adoptInValuation) * 100).toFixed(2) + '%'
                                                                : '0.00%'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Graph */}
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-20">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body ss-cardBody">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="d-block fw-12 mb-0">Historic Value Trend</h6>
                                                    <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span>
                                                </div>
                                                <div className="text-center">
                                                    <div className="graph">
                                                        {currentFacility ?
                                                            <HistoricValueChart
                                                                facilityId={facilityId}
                                                                filterType={historicValueFilters}
                                                            />
                                                            : ""}
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <ul className="nav ss-nav nav-tabs border-bottom" id="myTab" role="tablist">
                                                        <li className={`nav-item ${historicValueFilters === 'monthly' && 'active'}`} onClick={() => setHistoricValueFilters('monthly')}>
                                                            <button>Monthly</button>
                                                        </li>
                                                        <li className={`nav-item ${historicValueFilters === '6-monthly' && 'active'}`} onClick={() => setHistoricValueFilters('6-monthly')}>
                                                            <button>6 Monthly</button>
                                                        </li>
                                                        <li className={`nav-item ${historicValueFilters === 'yearly' && 'active'}`} onClick={() => setHistoricValueFilters('yearly')}>
                                                            <button>Annually</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-20">
                                        <div className="card card-border-shadow-primary h-100">
                                            <div className="card-body ss-cardBody">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="d-block fw-12 mb-0">Historic Revenue Trend</h6>
                                                    <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span>
                                                </div>
                                                <div className="text-center">
                                                    <div className="graph">
                                                        {currentFacility ?
                                                            <HistoricRevenueChart
                                                                facilityId={facilityId}
                                                                filterType={historicRevenueFilters}
                                                            />
                                                            : ""}
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <ul className="nav ss-nav nav-tabs border-bottom" id="myTab" role="tablist">
                                                        <li className={`nav-item ${historicRevenueFilters === 'monthly' && 'active'}`} onClick={() => setHistoricRevenueFilters('monthly')}>
                                                            <button>Monthly</button>
                                                        </li>
                                                        <li className={`nav-item ${historicRevenueFilters === '6-monthly' && 'active'}`} onClick={() => setHistoricRevenueFilters('6-monthly')}>
                                                            <button>6 Monthly</button>
                                                        </li>
                                                        <li className={`nav-item ${historicRevenueFilters === 'yearly' && 'active'}`} onClick={() => setHistoricRevenueFilters('yearly')}>
                                                            <button>Annually</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Graph */}

                                    {/* Facility Table */}
                                    {/* <div className="row mb-3">
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1">
                                            <div className="card card-border-shadow-primary h-100">
                                                <div className="card-body"> */}
                                    <div className="facilityTable table-responsive ss-facilityTable border-highlighted" style={{ marginTop: '12px' }}>
                                        <table className="table table-bordered" style={{ width: '100%', }}>
                                            <thead>
                                                <tr key="header_tr">
                                                    <th style={{ width: '20%' }} className="table-color" scope="col">
                                                        <div className="d-flex align-items-baseline">
                                                            Valuation / Performance Summary
                                                            <span className="badge badge-center rounded-pill bg-label-success ms-3"><ArrowTooltips /></span>
                                                        </div>
                                                    </th>
                                                    <th scope="col" style={{ width: '10%' }}>Adopt in<br /> Valuation</th>
                                                    <th scope="col" style={{ width: '10%' }}>Actual</th>
                                                    <th scope="col" style={{ width: '10%' }} className="border-avg-right">Actual /<br /> Passing</th>
                                                    <th className="bg-table-facility tb-color border-avg-top border-avg-left" scope="col" style={{ width: '10%' }}>Actual Avg <br /> 3-months</th>
                                                    <th className="bg-table-facility tb-color border-avg-top" scope="col" style={{ width: '10%' }}>Actual Avg <br /> 6-months</th>
                                                    <th className="bg-table-facility tb-color border-avg-top border-avg-right" scope="col" style={{ width: '10%' }}>Actual  Avg <br />12-months</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {facilityValuation.map((values, keyIndex) => (
                                                    <React.Fragment key={keyIndex}>
                                                        <tr key={keyIndex}
                                                            // style={{ height: '50px' }}
                                                            style={{
                                                                height: ["", "", "$/m² of NSA", "Capital Value Rate $/m² of NSA", "WALE (Years ) by Lettable Area"].includes(values.heading) ? '0px' : '50px'
                                                            }}
                                                        >
                                                            {/* Render Headings or an empty cell for adjusted headings in headings column */}
                                                            {adjustedHeadings.includes(values.heading) ? (
                                                                <td className="bg-table-facility centered-content"></td>
                                                            ) : (
                                                                <td className={
                                                                    `${highlightHeadingsBackground.includes(values.heading) ? "theme-color-bg" : "bg-white"} 
                                                                                    ${highlightRow.includes(values.heading) && "bg-table"}
                                                                                    sticky-col first-col centered-content`
                                                                }>
                                                                    {values.heading}
                                                                </td>
                                                            )}

                                                            {/* Render Adopt in Valuation Values or an empty cell for adjusted headings in adopt in valuation column*/}
                                                            {adjustedHeadings.includes(values.heading) ? (
                                                                <td className="bg-table-facility centered-content"></td>
                                                            ) : (
                                                                <td className={`bg-white fw-700 centered-content
                                                                    ${highlightTextWithRed.includes(values.heading) ? "color-red-table" : ""} 
                                                                    ${values.heading === 'Adopt Rounded Estimated Value' ? 'theme-color-bg' : ''}
                                                                    ${highlightRow.includes(values.heading) && "bg-table"}
                                                                    `}
                                                                >
                                                                    {showValuesInPercent.includes(values.heading) && !isNaN(parseFloat(values.adoptInValuation))
                                                                        ? (`${parseFloat(values.adoptInValuation).toFixed(2)}%`)
                                                                        : (
                                                                            values.adoptInValuation === ''
                                                                                ? (" ")
                                                                                : (
                                                                                    noFormatHeadings.includes(values.heading)
                                                                                        ? (parseFloat(values.adoptInValuation).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                                                                                        : (
                                                                                            feeRateHeadings.includes(values.heading)
                                                                                                ? formatterWithDecimals.format(values.adoptInValuation)
                                                                                                : formatterWithoutDecimals.format(values.adoptInValuation)
                                                                                        )
                                                                                )
                                                                        )
                                                                    }
                                                                </td>
                                                            )}

                                                            {/* % Gross Revenue Row */}
                                                            {values.heading === 'Adopted Total Expenses' ? (
                                                                <>
                                                                    <td className="bg-table centered-content" >
                                                                        % Gross Revenue
                                                                    </td>
                                                                    <td className="bg-white centered-content">
                                                                        {!isNaN(parseFloat(percentageGrossRevenue)) ? (
                                                                            parseFloat(percentageGrossRevenue).toFixed(2) + "%"
                                                                        ) : ''}
                                                                    </td>
                                                                </>
                                                            ) : ''}

                                                            {/* Profit Margin Row */}
                                                            {values.heading === 'Adopted Net Operating Profit' ? (
                                                                <>
                                                                    <td className="bg-table centered-content" colSpan={1}>
                                                                        Profit Margin
                                                                    </td>
                                                                    <td className="bg-white centered-content">
                                                                        {!isNaN(parseFloat(profitMargin)) ? (
                                                                            parseFloat(profitMargin).toFixed(2) + "%"
                                                                        ) : ''}
                                                                    </td>
                                                                </>
                                                            ) : ''}

                                                            {/* $/m² of NSA Row */}
                                                            {values.heading === 'Core Indicative Market Value' ? (
                                                                <>
                                                                    <td className="bg-table centered-content" colSpan={1}>
                                                                        $/m² of NSA
                                                                    </td>
                                                                    <td className="bg-white centered-content">
                                                                        {!isNaN(parseFloat(perMetreSquareOfNSA)) ? (
                                                                            formatValue(parseFloat(perMetreSquareOfNSA))
                                                                        ) : ''}
                                                                    </td>
                                                                </>
                                                            ) : ''}

                                                            {/* Capital Value Rate $/m² of NSA Row */}
                                                            {values.heading === 'Adopt Rounded Estimated Value' ? (
                                                                <>
                                                                    <td className="bg-table centered-content" colSpan={2}>
                                                                        Capital Value Rate $/m² of NSA
                                                                    </td>
                                                                    <td className="bg-white centered-content">
                                                                        {!isNaN(parseFloat(capitalValueRatePerMetreSquareOfNSA)) ? (
                                                                            formatValue(parseFloat(capitalValueRatePerMetreSquareOfNSA))
                                                                        ) : ''}
                                                                    </td>
                                                                </>
                                                            ) : ''}

                                                            {/* Render Actual Values */}
                                                            <td className={`bg-white centered-content
                                                                                ${highlightTextWithRed.includes(values.heading) ? "color-red-table" : ""} 
                                                                                ${values.actual === '' ? "bg-table-facility border-remove" : ""}
                                                                                ${highlightRow.includes(values.heading) && "bg-table"}
                                                                                `}
                                                            >
                                                                {showValuesInPercent.includes(values.heading) && values.actual !== ''
                                                                    ? (`${parseFloat(values.actual).toFixed(2)}%`)
                                                                    : (
                                                                        values.actual === ''
                                                                            ? ("")
                                                                            : (
                                                                                noFormatHeadings.includes(values.heading)
                                                                                    ? (parseFloat(values.actual).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                                                                                    : (
                                                                                        feeRateHeadings.includes(values.heading)
                                                                                            ? formatterWithDecimals.format(values.actual)
                                                                                            : formatterWithoutDecimals.format(values.actual)
                                                                                    )
                                                                            )
                                                                    )
                                                                }
                                                            </td>

                                                            {/* Render Actual By Passing Values */}
                                                            <td className={`bg-white centered-content
                                                                                ${highlightTextWithRed.includes(values.heading) ? "color-red-table" : ""} 
                                                                                ${values.actual === '' ? "bg-table-facility border-remove" : ""}
                                                                                ${highlightRow.includes(values.heading) && "bg-table"} 
                                                                                `}
                                                            >
                                                                {values.actualByPassing && values.actualByPassing !== null
                                                                    ? (`${parseFloat(values.actualByPassing).toFixed(2)}%`)
                                                                    : (
                                                                        keyIndex <= annualisedTotalRevenueIndex
                                                                            ? (values.actualByPassing === '' ? (" ") : '0.00%')
                                                                            : ('')
                                                                    )
                                                                }
                                                            </td>

                                                            {/* WALE (Years) by Lettable Area Value */}
                                                            {values.heading === 'Non-Storage Income' ? (
                                                                <>
                                                                    <td className="bg-table centered-content" colSpan={2}>
                                                                        WALE (Years) by Lettable Area
                                                                    </td>
                                                                    <td className="bg-white centered-content">
                                                                        {parseFloat(wALEByLettableArea).toFixed(2)}
                                                                    </td>
                                                                </>
                                                            ) : ''}

                                                            {/* Render Past 3 Months Average Values */}
                                                            <td className={`bg-white centered-content border-avg-left
                                                                                ${values.heading === "Monthly Storage Revenue" && "border-avg-bottom"}
                                                                                ${highlightTextWithRed.includes(values.heading) ? "color-red-table" : ""} 
                                                                                ${values.actualPast3MonthsAvg === '' ? "bg-table-facility border-remove" : ""}
                                                                                ${values.actual === '' ? "bg-table-facility border-remove" : ""}
                                                                                ${highlightRow.includes(values.heading) && "bg-table"} 
                                                                                `}
                                                            >
                                                                {showValuesInPercent.includes(values.heading) && values.actualPast3MonthsAvg !== ''
                                                                    ? (`${parseFloat(values.actualPast3MonthsAvg).toFixed(2)}%`)
                                                                    : (
                                                                        values.actualPast3MonthsAvg === ''
                                                                            ? ("")
                                                                            : (
                                                                                noFormatHeadings.includes(values.heading)
                                                                                    ? (parseFloat(values.actualPast3MonthsAvg).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                                                                                    : (
                                                                                        feeRateHeadings.includes(values.heading)
                                                                                            ? formatterWithDecimals.format(values.actualPast3MonthsAvg)
                                                                                            : formatterWithoutDecimals.format(values.actualPast3MonthsAvg)
                                                                                    )
                                                                            )
                                                                    )
                                                                }
                                                            </td>

                                                            {/* Render Actual Past 6 Months Average Values */}
                                                            <td className={`bg-white centered-content
                                                                                ${values.heading === "Monthly Storage Revenue" && "border-avg-bottom"}
                                                                                ${highlightTextWithRed.includes(values.heading) ? "color-red-table" : ""} 
                                                                                ${values.actualPast6MonthsAvg === '' ? "bg-table-facility" : ""}
                                                                                ${values.actual === '' ? "bg-table-facility" : ""}
                                                                                ${highlightRow.includes(values.heading) && "bg-table"} 
                                                                                `}
                                                            >
                                                                {showValuesInPercent.includes(values.heading) && values.actualPast6MonthsAvg !== ''
                                                                    ? (`${parseFloat(values.actualPast6MonthsAvg).toFixed(2)}%`)
                                                                    : (
                                                                        values.actualPast6MonthsAvg === ''
                                                                            ? ("")
                                                                            : (
                                                                                noFormatHeadings.includes(values.heading)
                                                                                    ? (parseFloat(values.actualPast6MonthsAvg).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                                                                                    : (
                                                                                        feeRateHeadings.includes(values.heading)
                                                                                            ? formatterWithDecimals.format(values.actualPast6MonthsAvg)
                                                                                            : formatterWithoutDecimals.format(values.actualPast6MonthsAvg)
                                                                                    )
                                                                            )
                                                                    )
                                                                }
                                                            </td>

                                                            {/* Render Actual Past 12 Months Average Values */}
                                                            <td className={`bg-white centered-content border-avg-right
                                                                                ${values.heading === "Monthly Storage Revenue" && "border-avg-bottom"}
                                                                                ${highlightTextWithRed.includes(values.heading) ? "color-red-table" : ""} 
                                                                                ${values.actualPast12MonthsAvg === '' ? "bg-table-facility" : ""}
                                                                                ${values.actual === '' ? "bg-table-facility" : ""}
                                                                                ${highlightRow.includes(values.heading) && "bg-table"} 
                                                                                `}
                                                            >
                                                                {showValuesInPercent.includes(values.heading) && values.actualPast12MonthsAvg !== ''
                                                                    ? (`${parseFloat(values.actualPast12MonthsAvg).toFixed(2)}%`)
                                                                    : (
                                                                        values.actualPast12MonthsAvg === ''
                                                                            ? ("")
                                                                            : (
                                                                                noFormatHeadings.includes(values.heading)
                                                                                    ? (parseFloat(values.actualPast12MonthsAvg).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                                                                                    : (
                                                                                        feeRateHeadings.includes(values.heading)
                                                                                            ? formatterWithDecimals.format(values.actualPast12MonthsAvg)
                                                                                            : formatterWithoutDecimals.format(values.actualPast12MonthsAvg)
                                                                                    )
                                                                            )
                                                                    )
                                                                }
                                                            </td>
                                                        </tr>
                                                        {trailEmptyRowAfter.includes(values.heading) &&
                                                        !['Core Indicative Market Value', 'Adopted Net Operating Profit', 'Adopted Total Expenses'].includes(values.heading) && (
                                                            <React.Fragment key={`empty_1row`}>
                                                                <tr key={`empty_1row`} height="40px">
                                                                    <td colSpan={4} className="bg-table-facility ss-borderNone">&nbsp;</td>
                                                                    <td 
                                                                        className={`bg-table-facility 
                                                                            ${borderNoneForAverages.includes(values.heading) ? 'border-avg-left borderRight0' : 'ss-borderNone'}`}
                                                                    >
                                                                        &nbsp;
                                                                    </td>

                                                                    <td 
                                                                        className="bg-table-facility ss-borderNone"
                                                                    >
                                                                        &nbsp;
                                                                    </td>
                                                                    <td 
                                                                        className={`bg-table-facility 
                                                                            ${borderNoneForAverages.includes(values.heading) ? 'border-avg-right' : 'ss-borderNone '}`}
                                                                    >
                                                                        &nbsp;
                                                                    </td>
                                                                </tr>
                                                            </React.Fragment>
                                                        )}

                                                    </React.Fragment>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* Facility Table */}

                                    {/* Disclaimer */}
                                    <div className="col-md-12 mb-20">
                                        <div className="card">
                                            <div className="card-body ss-cardBody">
                                                <div className="card-title">
                                                    <h3 className="d-block fw-12 mb-0">
                                                        Disclaimer
                                                    </h3>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 col-12 mb-20 card-body">
                                                        <p>
                                                            This indicative assessment of value is an estimate only and provides the “estimated market value (EMV)” of the
                                                            facility on a going concern (self storage use) basis. <span className="border-bottom-disclaimer">It does not constitute a formal valuation.</span> The EMV is based off
                                                            third party trading performance data and user inputs. Specific research into the property and the trading catchment
                                                            has not been undertaken. The EMV does not take into account any property specific attributes or external factors
                                                            which may impact value. An inspection of the facility has not occurred.
                                                        </p>
                                                        <p>
                                                            The EMV is a desktop estimate that can only be used by the licensee (“user”) as a tool for informative purposes. It
                                                            should not be relied upon for decision-making purposes. Specifically, the EMV must not be used, and is not suitable
                                                            for, mortgage security purposes, transaction purposes or investment decisions.
                                                        </p>
                                                        <p>
                                                            The EMV is based upon financial data provided by a third party and inputs by the user. Storval Pty Ltd does not make
                                                            any warranty as to the accuracy, completeness or reliability of the information; nor does Storval Pty Ltd accept any
                                                            liability arising in any way from any omissions or errors. The EMV should not be regarded as advice, nor should it be
                                                            relied upon by any party. Storval is an estimation tool only. Professional valuation advice is recommended before
                                                            making any investment decisions. A full valuation that adheres to the International Valuation Standards, inclusive of
                                                            a physical inspection, is recommended to confirm the estimated assessment shown.</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Disclaimer */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hidden-offscreen">
                        <FacilityPDF 
                            ref={facilityPDFRef} 
                            facilityId={facilityId} 
                            currentFacility={currentFacility} 
                            adoptedRoundedEstimatedValue={adoptedRoundedEstimatedValue}
                            previousMonthEstimatedValuePercentage={previousMonthValuationData && 
                                parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue) !== 0 && adoptedRoundedEstimatedValue !== 0
                                    ? (((adoptedRoundedEstimatedValue - parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue)) / (parseFloat(previousMonthValuationData.adoptRoundedEstimatedValue) || 1)) * 100).toFixed(2)
                            : 0} 
                            currentYearApiData={currentYearApiData} 
                            averageUnitSize={averageUnitSize} 
                            averageUnitSizeOccupied={averageUnitSizeOccupied} 
                            facilityValuation={facilityValuation} 
                            annualisedTotalRevenueIndex={annualisedTotalRevenueIndex} 
                            percentageGrossRevenue={percentageGrossRevenue}
                            profitMargin={profitMargin}
                            perMetreSquareOfNSA={perMetreSquareOfNSA}
                            capitalValueRatePerMetreSquareOfNSA={capitalValueRatePerMetreSquareOfNSA}
                            wALEByLettableArea={wALEByLettableArea}
                            historicValueFilters={historicValueFilters}
                            historicRevenueFilters={historicRevenueFilters}
                        />
                    </div>
                </div>
                <RequestAssistance
                    show={show}
                    setShow={setShow}
                    portfolioId={portfolioId}
                    portfolio={currentFacility?.portfolio || ''}
                    facilityName={currentFacility?.name ? currentFacility.name : ""}
                />
            </Layout>
        </>
    )
}