
import { Link, useNavigate } from "react-router-dom";
import { Layout } from "../Layout/Layout";
import { useEffect, useState } from "react";
import { DataTableComp } from "../Common/Datatable";
import { IconButton, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import axios from "axios";
import Swal from "sweetalert2";
import LoginIcon from '@mui/icons-material/Login';
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import Add from "../Users/Add";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddIcon from '@mui/icons-material/Add';

export default function Portfolios(){
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [editId, setEditId] = useState(0);
    const [popupType, setPopupType] = useState('Add');
    const [portfolios, setPortfolios] = useState([]);
    const [loading, setLoading] = useState(false);
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);

    const getList = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/users?role=Client`);
            setLoading(false)
            if (response.data.success) {
                setPortfolios(response.data.data);
            } else {
                Swal.fire({
                    icon: 'error',
                    // title: 'Oops...',
                    text: response.data.message || 'An error occurred while fetching user list!',
                });
            }
        } catch (err) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Server Error',
                text: 'Failed to connect to the server. Please try again later.',
            });
        }
    };

    useEffect(()=>{
        if(user.role !== 'SuperAdmin'){
            navigate(-1)
        }
        getList()
    },[])

    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }

    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#45a7c4',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/api/users/delete?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        // Swal.fire('Deleted!', '', 'success')
                        Toast.fire({
                            icon: 'success',
                            title: 'Deleted successfully!'
                        });
                        getList();
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    // Swal.fire(error.message, '', 'info')  
                    Toast.fire({
                        icon: 'error',
                        title: error.message || 'Error occurred!'
                    });         
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const handleUserLogin = (email) => {
        axios.post('/api/auth/another/login', { email: email, isSuperAdmin: user.isSuperAdmin })
          .then((res) => {
            if (res.data.success) {
              localStorage.clear();
              localStorage.setItem('sv-authUser', JSON.stringify(res.data.data));
              localStorage.setItem('selectedPortfolio', res.data.data.portfolio.id)
              localStorage.setItem('selectedPortfolioName', res.data.data.portfolio.name)
              navigate(`/portfolio-dashboard/${res.data.data.portfolio.id}`);
              console.log('PortfolioId:' , res.data.data.portfolio.id)
            } else {
              Swal.fire(res.data.message, '', 'info');
            }
          })
          .catch((error) => {
            Swal.fire(error.message, '', 'info');
          });
    };

    const handlePortfolioDashboard = (portfolio) => {
        localStorage.setItem('selectedPortfolio', portfolio.id);
        localStorage.setItem('selectedPortfolioName', portfolio.name);
        localStorage.setItem('selectedFacility', '');
    }

    const handleSetPortfolioId = (portfolio) => {
        localStorage.setItem('selectedPortfolio', portfolio.id);
        localStorage.setItem('selectedPortfolioName', portfolio.name);
    }

    const handleStatus = async (e, id) => {
        const status = e.target.checked ? '1' : '0';
        try {
            await axios.put(`api/users/updateStatus?id=${id}`, { status });
            getList();
        } catch (error) {
            console.error("Error:", error);
            Swal.fire(error.message, '', 'error');
        }
    };

    const columns = [
        {
            name: 'Portfolio',
            cell: row => (
                row.portfolio && row.portfolio.name && (
                    <Link to={`/portfolio-dashboard/${row.portfolio.id}`} className="" onClick={() => handlePortfolioDashboard(row.portfolio)}>
                        {row.portfolio.name}
                    </Link>
                )
            ),
            sortable: true,
            // width: '30%'
        },
        {
            name: 'Contact Name',
            // selector: row => row.name,
            cell: row => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {row.name}
                    {row.IsUnlockRequestSent === '1' && (
                        <Tooltip title="Account locked" placement="top">
                            <span style={{
                                width: '8px',
                                height: '8px',
                                backgroundColor: '#ff0c00de',
                                borderRadius: '50%',
                                marginLeft: '5px',
                                cursor: 'pointer',
                            }} />
                        </Tooltip>
                    )}
                </div>
            ),
            sortable: true,            
            // width: '20%'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            // width: '23%'
        },
        {
            name: 'Status',
            cell: row => (
                <div className="form-check form-switch mb-2">
                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={row.status === '1' ? true : false} onClick={(e) => handleStatus(e, row.id)}/>
                </div>
            ),           
            width: '7%'
        },
        {
            name: 'Action',
            cell: row => (
                <>
                    <Link to={`/facility`} onClick={() => handleSetPortfolioId(row.portfolio)} >
                        <Tooltip title="Add Facility" size='small' className='icon icon-sm icon-secondary'>
                            <IconButton>
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </Link>
                    <Link to={`/users?portfolioId=${row.portfolio.id}`} onClick={() => handleSetPortfolioId(row.portfolio)} >
                        <Tooltip title="View Users" size='small' className='icon icon-sm icon-secondary ms-2'>
                            <IconButton>
                                <PeopleAltIcon />
                            </IconButton>
                        </Tooltip>
                    </Link>
                    <Tooltip onClick={(e) =>  {handleEdit(row.id)}} title="Edit" size='small' className='icon icon-sm icon-secondary ms-2'>
                        <IconButton>
                             <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip onClick={() => handleDelete(row.id)} title="Delete" size='small' className='icon icon-sm icon-primary ms-2'>
                        <IconButton>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </>
                
            ),    
            // width: '8%'
        },
        {
            name: 'login',
            cell: row => (
                <Link onClick={(e) =>  {handleUserLogin(row.email)}} className="clr-theme"><LoginIcon/> </Link>            
            ),            
            // width: '7%'
        },
    ];

    return(
        <>
            <Loader loading={loading} />
            <Layout>
                <Header pageTitle="Portfolios" />
                <div className="container-xxl layout-navbar navbar-detached z-index-auto ss-navbar mt-0 border-0 pt-2">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-2" id="countries-tab">
                            {/* <h4 className="mb-0">Portfolios</h4> */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-lg-start text-sm-center">
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn btn-primary" onClick={handleAdd}
                                >
                                    <span className="tf-icons bx bx-plus"></span>&nbsp; Add Portfolio
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-xxl flex-grow-1 container-p-y pt-0">
                    <div className="ss-header">
                        <DataTableComp columns={columns} data={portfolios} className="ss-data-table" />
                    </div>
                </div>
                <Add type={popupType} getList={getList} editId={editId} setEditId={setEditId} show={show} setShow={setShow} roleType="Client" />
            </Layout>
        </>
    );
}