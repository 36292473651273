import React from 'react';
import {Navigate, Outlet} from 'react-router-dom'

const useAuth = () => {
  const user = localStorage.getItem('sv-authUser')
  if(user){
    return true
  } else {
    return false
  }
}

const PublicRoutes=(props) =>{
  const user = localStorage.getItem('sv-authUser')
  const authUser = JSON.parse(user);
    const auth = useAuth()
    return auth ? <Navigate to={authUser.role === 'SuperAdmin' ? "/portfolios" : "/portfolio-dashboard" } /> : <Outlet />
}

export default PublicRoutes;