import * as XLSX from 'xlsx';
import * as yup from 'yup';
import React from 'react';
import axios from "axios";
import Swal from 'sweetalert2'
import { read, utils } from 'xlsx';
import { useFormik } from 'formik';
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

import moment from 'moment';


const validationSchema = yup.object({
    tenancy_list: yup.mixed().required('Tenancy list file is required')
});

export const Add = ({ show, setShow, handleClose, getFacilityDetails, facilityId, loading, setLoading, ...rest}) => {

    const initialValues = {
        facilityId: facilityId,
        tenancy_list: null,
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            handleSubmit(values, resetForm);
          },
    });
    
    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const handleSubmit = (values, resetForm)=>{
        setLoading(true);
        const file = values.tenancy_list;
        
        if(file){
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                const fileName = file.name;
                const fileExtension = fileName.split('.').pop();
                
                if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    if (sheets.length) {
                        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                        
                        // Process each row to convert date strings to proper format
                        rows.forEach(item => {
                            // Check if leaseExpiry is a valid date string or a number (Excel serial date)
                            if (item.leaseExpiry) {
                                if (isNaN(item.leaseExpiry)) {
                                    if (moment(item.leaseExpiry, moment.ISO_8601, true).isValid()) {
                                        item.leaseExpiry = moment(item.leaseExpiry).format("YYYY-MM-DD HH:mm:ss");
                                    } else {
                                        console.log("Invalid leaseExpiry date:", item.leaseExpiry);
                                    }
                                } else {
                                    const parsedDate = XLSX.SSF.parse_date_code(item.leaseExpiry);
                                    item.leaseExpiry = moment(new Date(parsedDate.y, parsedDate.m - 1, parsedDate.d)).format("YYYY-MM-DD HH:mm:ss");
                                }
                            }        
                            // Check if nextRentReview is a valid date string or a number (Excel serial date)
                            if (item.nextRentReview) {
                                if (isNaN(item.nextRentReview)) {
                                    if (moment(item.nextRentReview, moment.ISO_8601, true).isValid()) {
                                        item.nextRentReview = moment(item.nextRentReview).format("YYYY-MM-DD HH:mm:ss");
                                    } else {
                                        console.log("Invalid nextRentReview date:", item.nextRentReview);
                                    }
                                } else {
                                    const parsedDate = XLSX.SSF.parse_date_code(item.nextRentReview);
                                    item.nextRentReview = moment(new Date(parsedDate.y, parsedDate.m - 1, parsedDate.d)).format("YYYY-MM-DD HH:mm:ss");
                                }
                            }
                        });
                        
                        axios.post(`api/nonStorageInput/multiCreate`, {tenancies: rows, facilityId: facilityId}).then(res => {
                            setLoading(false);
                            if(res.data.success){
                                setShow(false);
                                Toast.fire({
                                    icon: 'success',
                                    title: res.data.message || 'File Uploaded!'
                                });
                                getFacilityDetails(facilityId);  
                                // Swal.fire(res.data.message, '', 'success').then(result => {
                                //     getFacilityDetails(facilityId);  
                                // })  
                                resetForm();              
                            } 
                            else{
                                setShow(false);
                                Toast.fire({
                                    icon: 'error',
                                    title: res.data.message || 'Upload Failed!'
                                });
                                getFacilityDetails(facilityId);
                                // Swal.fire(res.data.message, '', 'error').then(result => {
                                //     getFacilityDetails(facilityId);  
                                // })
                            }
                        }).catch((error) => {
                            setLoading(false)
                            Toast.fire({
                                icon: 'error',
                                title: error.response?.data?.message || error.message
                            });;
                            // Swal.fire(error.response.data.message, '', 'error')
                        });
                    
                    }
                } else {
                    setLoading(false);
                    Swal.fire('Must be Upload excel file', '', 'error')
                }
            }
            reader.readAsArrayBuffer(file);
        }
    
    }
    
    const handleCloseModal = () => {
        // if(facilityId === 0){
            formik.resetForm(); // Reset formik form when modal is closed
        // }
        handleClose(); // Close the modal
    };
    return (
        <Modal show={show} onHide={handleCloseModal} dialogClassName="">
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <h5 className="modal-title fs-14 mb-0">Upload Excel</h5>
                    <CancelIcon style={{cursor: 'pointer'}} onClick={handleCloseModal}/>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="mb-4">
                            <label htmlFor="upload" className="btn btn-outline-primary w-100 mb-2">
                                <span className="d-none d-sm-block"><span className="tf-icons bi bi-cloud-upload"></span>&nbsp; Upload File</span>
                                <i className="bx bx-upload d-block d-sm-none"></i>
                                <input 
                                type="file" 
                                id="upload"
                                hidden=''
                                accept={".csv, .xlsx, .xls"}
                                name="tenancy_list"
                                className={formik.touched.tenancy_list && formik.errors.tenancy_list ?
                                    'account-file-input is-invalid form-control':'form-control account-file-input'}
                                onChange={(e) => {
                                    formik.setFieldValue('tenancy_list', e.currentTarget.files[0]);
                                }}
                                onBlur={formik.handleBlur}
                                />
                            </label>
                            {formik.touched.tenancy_list && formik.errors.tenancy_list ? (
                                <div className="text-danger">{formik.errors.tenancy_list}</div>
                            ) : null }
                            <div className="text-end">
                                <a href="./documents/nonStorageTenancyList.xlsx" download>Download Sample File</a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="reset" className="btn btn-outline-primary" onClick={handleCloseModal}>Close</button>       
                    <button type="submit" className="btn btn-primary">Upload</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Add;
Add.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};