import * as React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#233446',
      },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#233446',
        color: 'White',
        boxShadow: theme.shadows[1],
        fontSize: '1rem',
        cursor: 'pointer',
        fontFamily: 'poppins'
    },
}));

export default function ArrowTooltips({title}) {
  return (
    <LightTooltip title={title} placement="top" arrow style={{cursor: 'pointer'}}>
      <i className="bi bi-question-lg"></i>
    </LightTooltip>
  );
}