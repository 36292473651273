import { Layout } from "../Layout/Layout";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Header/Header";
import ClientView from "./ClientView";
import Loader from "../Loader/Loader";

export default function OperatingExpenses() {
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const [currentFacility, setCurrentFacility] = useState([]);
    const [taxRates, setTaxRates] = useState([]);
    const [operatingExpenseRanges, setOperatingExpenseRanges] = useState([]);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const portfolioId = user.portfolio ? user.portfolio.id : null;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const portfolioId = localStorage.getItem('selectedPortfolio');
        const facilityId = localStorage.getItem('selectedFacility');

        if (portfolioId) {
            setSelectedPortfolio(JSON.parse(portfolioId));
        }

        if (facilityId) {
            setSelectedFacility(JSON.parse(facilityId));
            getFacilityDetails(facilityId);
        }
    }, []);

    useEffect(() => {
        if (portfolioId) {
            getTaxRates();
            getOperatingExpenseRange();
        }
    }, [portfolioId]);

    const initialValues = {
        portfolioId: '',
        id: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
    });

    const getOperatingExpenseRange = async () => {
        try {
            await axios.get(`/api/operatingExpenseRanges`)
                .then(function (response) {
                    let result = response.data;
                    if (result.success) {
                        setOperatingExpenseRanges(result.data)
                    }
                })
        } catch (err) {
            console.log(err)
            Swal.fire(err);
        }
    }

    const getTaxRates = async () => {
        try {
            await axios.get(`/api/taxRates`)
                .then(function (response) {
                    let result = response.data;
                    if (result.success) {
                        setTaxRates(result.data)
                    }
                })
        } catch (err) {
            console.log(err)
            Swal.fire(err);
        }
    }

    const getFacilityDetails = (facilityId) => {
        // console.log("OperatingexpensesData", facilityId);
        setLoading(true)
        if (!facilityId) {
            // console.error("Invalid facilityId", facilityId);
            setLoading(false);
            return;
        }
        // console.log("OperatingexpensesData-------------", facilityId);
        axios.get(`api/operatingExpense?facilityId=${facilityId}`).then(res => {
            setLoading(false)
            if (res.data.success) {
                const result = res.data.data;
                // console.log("operatingExpense===",res.data.data)
                setCurrentFacility(result)
            }
            else {
                Swal.fire("", res.data.message, 'error')
            }
        })
    }

    useEffect(() => {
        getTaxRates();
        getOperatingExpenseRange();
    }, [])

    // const handleFacilityChange = (facilityId) => {
    //     setSelectedFacility(facilityId)
    //     console.log("facilityId",facilityId);
    //     if(facilityId !== null){
    //         getFacilityDetails(facilityId);
    //     }
    //     else{
    //         setCurrentFacility([])
    //     }
    // }

    // const handlePortfolioChange = (newValue) => {
    //     if (newValue) {
    //         setSelectedPortfolio(newValue);
    //         getFacilitiesList(newValue.portfolio.id);
    //         setSelectedFacility(null);
    //         formik.setFieldValue('portfolioId', newValue.portfolio.id);
    //         formik.setFieldValue('id', '');
    //         setCurrentFacility([]);
    //     } else {
    //         setSelectedPortfolio(null);
    //         setFacilities([]);
    //         setSelectedFacility(null);
    //         formik.setFieldValue('portfolioId', '');
    //         formik.setFieldValue('id', '');
    //         setCurrentFacility([]);
    //     }
    // };

    // const handleFacilityChange = (event, selectedOption) => {
    //     const facilityId = selectedOption ? selectedOption.id : null;
    //     if (facilityId !== null) {
    //         setSelectedFacility(facilityId);
    //         formik.setFieldValue('id', facilityId);
    //         getFacilityDetails(facilityId);
    //     } else {
    //         setSelectedFacility(null);
    //         formik.setFieldValue('id', '');
    //         setCurrentFacility([]);
    //     }
    // };

    const facilityId = localStorage.getItem('selectedFacility') ? parseInt(localStorage.getItem('selectedFacility')) : null;

    const [currentlySelectedFacility, setCurrentlySelectedFacility] = useState({});
    useEffect(() => {
        if (facilityId) {
            getCurrentFacilityDetails(facilityId)
        }
    }, [facilityId]);

    const getCurrentFacilityDetails = (facilityId) => {
        setLoading(true)
        axios.get(`api/facility?id=${facilityId}`).then(res => {
            setLoading(false)
            if (res.data.success) {
                const result = res.data.data;
                setCurrentlySelectedFacility(result)
            }
        })
    }

    const [checked, setChecked] = useState(false);

    let currentFacilityStateTaxRate = 0.00;
    if (currentlySelectedFacility) {
        if (currentlySelectedFacility.country === 'New Zealand') {
            let stateData = taxRates.find(item => item.state === 'NZ');
            currentFacilityStateTaxRate = stateData ? stateData.rate : 0;
        }
        else {
            let stateData = taxRates.find(item => item.state === currentlySelectedFacility.state_code);
            currentFacilityStateTaxRate = stateData ? stateData.rate : 0;
        }
    }

    const handleInputChange = async (rateId, value, stateName) => {
        if (value !== undefined && value !== '') {

            Swal.fire({
                title: 'Are you sure?',
                text: `You want to update tax rate for ${stateName} to ${value}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, proceed',
                cancelButtonText: 'No, cancel'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    let rawValue = value.replace('%', '').trim();
                    try {
                        const response = await axios.put(`/api/taxRate/update`, { id: rateId, rate: rawValue });
                        setLoading(false)
                        if (response.data.success) {
                            Swal.fire(response.data.message, '', 'success').then(() => {
                                getTaxRates();
                            });
                        } else {
                            Swal.fire(response.data.message, '', 'error');
                        }
                    } catch (error) {
                        setLoading(false)
                        Swal.fire(error.response.data.message, '', 'error')
                    }

                }
                else {
                    getTaxRates();
                }
            });


        }
    };
    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <Header
                    pageTitle={localStorage.getItem('selectedFacilityName')}
                    verificationStatus={localStorage.getItem('selectedFacilityVerificationStatus')}
                />
                {/* <FilterHeader 
                    selectedPortfolio={selectedPortfolio}
                    setSelectedPortfolio={setSelectedPortfolio}
                    selectedFacility={selectedFacility}
                    setSelectedFacility={setSelectedFacility}
                    getPageData={getFacilityDetails}
                    setPageData={setCurrentFacility}
                    // setOperatingExpenseRanges={setOperatingExpenseRanges}
                    // setTaxRates={setTaxRates}
                    formik={formik}
                /> */}
                <div className="container-xxl ">
                    <div className="container-p-y">
                        {/* <NavHeader /> */}
                        <div className="">
                            {
                                <ClientView currentFacility={currentFacility} setCurrentFacility={setCurrentFacility} operatingExpenseRanges={operatingExpenseRanges} selectedFacilityId={selectedFacility} setLoading={setLoading} getFacilityDetails={getFacilityDetails} taxRates={taxRates} getTaxRates={getTaxRates} setTaxRates={setTaxRates} currentlySelectedFacility={currentlySelectedFacility} setCurrentlySelectedFacility={setCurrentlySelectedFacility} />
                            }
                        </div>
                    </div>
                </div>

                {/* <FormControlLabel control={<Switch checked={checked} onChange={() => setChecked((prev) => !prev) } />} label="Show Tax Rate List" />
                <Box sx={{ display: 'flex' }}>
                    <Fade in={checked}> */}
                {user.role === "SuperAdmin" &&
                    <div className="container-xxl ">
                        <div className="bg-white p-3 mb-3">
                            <div className="row">
                                <div className="col-xxl-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1 mt-4">
                                    <div className="facilityTable table-responsive">
                                        <div className="d-flex justify-content-between align-items-center border-bottom-0">
                                            <h3 className="">Multi Holding Land Tax Rate</h3>
                                        </div>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th className="bg-New" scope="col" width={'20%'} style={{ color: "black!important" }}>State</th>
                                                    <th scope="col">Rate</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {taxRates.map((rates, i) => (
                                                    <tr key={i}>
                                                        <td>{rates.state}</td>
                                                        <td>{
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                placeholder="0.00%"
                                                                value={rates.rate ? rates.rate + '%' : 0.00 + '%'}
                                                                onChange={(e) => {
                                                                    if (e.target.value !== undefined && e.target.value !== '') {
                                                                        let rawValue = e.target.value.replace('%', '').trim();
                                                                        setTaxRates(taxRates.map(rate =>
                                                                            rate.id === rates.id ? { ...rate, rate: rawValue } : rate
                                                                        ));
                                                                    }
                                                                }}
                                                                onBlur={(e) => handleInputChange(rates.id, e.target.value, rates.state)}
                                                            />}
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1 mt-4">
                                    <div className="facilityTable table-responsive" style={{ marginTop: '2.8rem' }}>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td className="table-red-bg">State: </td>
                                                    <td className="bg-table">{currentlySelectedFacility?.state_code + " (" + currentlySelectedFacility?.state + ")" || "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <td className="table-red-bg">Rate: </td>
                                                    <td className="bg-table">{currentFacilityStateTaxRate}% </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* </Fade>
                </Box> */}
            </Layout>
        </>
    );
}