import { useEffect, useState } from "react";
import { Layout } from "../Layout/Layout";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import Loader from "../Loader/Loader";
import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";

export default function EmailAssistance(){
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        message: Yup.string().required('Message is required'),
        receiverEmail: Yup.string().email('Invalid email address').required('Email is required'),
    });
    
    useEffect(() => {        
        if(user.role !== 'SuperAdmin'){
            navigate(-1)
        }
        getAssistanceDetails();
    }, [])

    const getAssistanceDetails = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/auth/assistanceSettings`);
            setLoading(false)
            if (response.data.success) {
                // setUsers(response.data.data);
                formik.setFieldValue('receiverEmail', response.data.data.receiverEmail);
                formik.setFieldValue('message', response.data.data.message);
            } else {
                Swal.fire({
                    icon: 'error',
                    // title: 'Oops...',
                    text: response.data.message || 'An error occurred while fetching user list!',
                });
            }
        } catch (err) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Server Error',
                text: 'Failed to connect to the server. Please try again later.',
            });
        }
    };

    const initialValues = {
        id: user.id,
        receiverEmail: '',
        message: '',
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (values) => {
        try{
            const response = await axios.put('/api/auth/update', values);
            setLoading(false);
            if (response.data.success) {
                Swal.fire('Success', response.data.message, 'success');
                getAssistanceDetails();
            } else {
                Swal.fire('Error', response.data.message || 'Failed to update settings', 'error');
            }
        }catch (err) {
            setLoading(false);
            Swal.fire('Error', 'Failed to update settings. Please try again later.' + err, 'error');
        }        
    }
    
    return(
        <>
            <Loader loading={loading} />
            <Layout>
                <Header pageTitle="Assistance Settings" />    
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-8"><div className="card mb-4">
                                    <h5 className="card-header border-0">Update Assistance Settings</h5>
                                    <div className="card-body">
                                        <form id="formAccountSettings" method="POST" onSubmit={formik.handleSubmit} encType="multipart/form-data" >
                                            <div className="row d-block">
                                                <div className="mb-3 col-md-8">
                                                    <label htmlFor="receiverEmail" className="form-label">Receiver Email</label>
                                                    <input className="form-control" type="text" id="receiverEmail" name="receiverEmail"                                        
                                                    onChange={formik.handleChange} 
                                                    onBlur={formik.handleBlur} 
                                                    value={formik.values.receiverEmail}
                                                    placeholder="Your email" />
                                                    {formik.touched.receiverEmail && formik.errors.receiverEmail && (
                                                        <div className="text-danger">{formik.errors.receiverEmail}</div>
                                                    )}
                                                </div>
                                                <div className="mb-3 col-md-8">
                                                    <label className="form-label" htmlFor="message">Message</label>
                                                    <textarea className="form-control" type="text" id="message"
                                                    rows="4"
                                                    name="message"                                 
                                                    onChange={formik.handleChange} 
                                                    onBlur={formik.handleBlur} 
                                                    value={formik.values.message}
                                                    placeholder="Your message to us..." ></textarea>
                                                    {formik.touched.message && formik.errors.message && (
                                                        <div className="text-danger">{formik.errors.message}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mt-2 d-flex justify-content-end">
                                                <button type="submit" className="btn btn-primary me-2">Save changes</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}