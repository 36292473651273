import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Layout } from "../Layout/Layout";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from 'yup';
import Swal from "sweetalert2";
import Header from "../Header/Header";

export default function Profile(){
    const [imgSrc, setImgSrc] = useState('assets/img/avatars/1.png')
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const inputFile = useRef(null);

    const onFileChange = (file) => {
        formik.setFieldValue('image', file);
        const reader = new FileReader();
        reader.onload = () => {
            setImgSrc(reader.result);
        // console.log(reader.result)
        // formik.setFieldValue('image', reader.result);
        }
        reader.readAsDataURL(file);
    };

    const resetImage = () => {
        if (inputFile.current) {
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
        }
        // if (user?.role === "Client") {
        //     setImgSrc('assets/img/avatars/logo-placeholder.png');
        // }
        // else if(user?.role === "SuperAdmin"){
        //     setImgSrc('assets/img/avatars/1.png');
        // }
        const defaultImage = user?.role === "SuperAdmin" 
            ? 'assets/img/avatars/1.png'
            : 'assets/img/avatars/logo-placeholder.png';
        setImgSrc(defaultImage);
        formik.setFieldValue('image','');
    };

    useEffect(() => {
        setImgSrc(imgSrc)
    },[imgSrc]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required')
            .test(
                'is-email-unique',
                'Email already exists!',
                async (value) => await handleCheckIsEmailAlreadyExist(value)
        ),
        portfolioName: user?.role === 'Super Admin' ? Yup.string().required('Portfolio Name is required') : Yup.string(),
        phone: Yup.string()
            .matches(
                // /^(?:\d[\d -]*){1,10}$/,
                /^(?:\d[\d -]*){1,9}$/,
                'Enter a valid phone number with up to 10 digits'
            )
            // .required('Phone number is required')
    });

    const initialValues = {
        id: user.id,
        role: user.role,
        name: user.name,
        email: user.email,
        phone: user.phone,
        image: user.image || '',
        portfolioName: user?.portfolio?.name || ''
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    useEffect(() => {
        if (Object.keys(user).length !== 0) {
            // if (user?.role === "Client") {
            //     setImgSrc(user.image ? user.image : 'assets/img/avatars/logo-placeholder.png');
            // }
            // else if(user?.role === "SuperAdmin"){
            //     setImgSrc(user.image ? user.image : 'assets/img/avatars/1.png');
            // }
            const defaultImage = user?.role === "SuperAdmin" 
                ? 'assets/img/avatars/1.png'
                : 'assets/img/avatars/logo-placeholder.png';
            setImgSrc(user?.image || defaultImage);

             //   setImgSrc(user.image ? user.image : 'assets/img/avatars/1.png');
            formik.setValues({
                id: user.id,
                role: user.role,
                name: user.name,
                email: user.email,
                phone: user.phone ? user.phone :'',
                image: null,
                portfolioName: user?.portfolio?.name || ''
            });
        }
      }, [formik.setValues]);
 
    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const handleSubmit = async (values) => {
        try {
            const formData = new FormData();
            formData.append('id', values.id);
            formData.append('role', values.role);
            formData.append('name', values.name);
            formData.append('email', values.email);
            formData.append('phone', values.phone);
            if (user?.role !== 'SuperAdmin' && values?.portfolioName !== '') {
                formData.append('portfolioName', values.portfolioName);
            }
            // Only append the image if it's been updated (i.e., it's not null)
            //   if (values.image !== '') {
            //       formData.append('image', values.image);
            //   } else {
            //       setImgSrc('assets/img/avatars/1.png')
            //       formData.append('image', imgSrc); 
            //   }

            if (values.image) {
                formData.append('image', values.image);
            } else if (imgSrc) {
                formData.append('image', imgSrc);
            }
            // console.log("formData====", formData);

            let res = await axios.post('/api/users/updateProfile', formData);
            if (res.data.success) {
                const authUserJSON = localStorage.getItem('sv-authUser');
                let authUser = JSON.parse(authUserJSON);
                if (authUser) {
                    if (values.image && res.data.data.image) {
                        authUser.image = res.data.data.image || authUser.image;
                    } else {
                        authUser.image = imgSrc;
                    }
                    // if (values.image) {
                    //     authUser.image = res.data.data.image;
                    // }else {
                    //     authUser.image = imgSrc;
                    // }
                    authUser.name = res.data.data.name;
                    authUser.email = res.data.data.email;
                    authUser.phone = res.data.data.phone;
                    if (res.data.updatedPortfolioName) {
                        authUser.portfolio.name = res.data.updatedPortfolioName.name;
                    }
                    try {
                        localStorage.setItem('sv-authUser', JSON.stringify(authUser));
                    } catch (error) {
                        console.error("Error storing authUser:", error);
                    }
                } else {
                    console.error("authUserJSON is not valid JSON:", authUserJSON);
                }
                Toast.fire({
                    icon: 'success',
                    title: res.data.message
                });
                // Swal.fire(res.data.message, '', 'success').then(result => {
                // //   window.location.reload();
                // })
            }
            else {
                // Swal.fire(res.data.message, '', 'info')
                Toast.fire({
                    icon: 'info',
                    title: res.data.message
                });
            }
        } catch (error) {
            //   Swal.fire('Error updating profile:'+ error);
            //   if (error.response && error.response.data && error.response.data.message) {
            //         Swal.fire('Error updating profile:', error.response.data.message, 'error');
            //     } else {
            //         Swal.fire('Error updating profile:', error.message, 'error');
            //     }
            Toast.fire({
                icon: 'error',
                title: error.response?.data?.message || 'Error updating profile: ' + error.message
            });
        }
    }

    const handleCheckIsEmailAlreadyExist = async (email) => {
        try {
            const response = await axios.post("/api/auth/checkIsEmailAlreadyExist", { id: formik.values.id, email });
            return !response.data.exists;
        } catch (error) {
            console.error("Error checking email existence:", error);
            return true;
        }
    };
    
    return(
        <Layout>
            <Header pageTitle="Settings" />    
            <div className="container-xxl flex-grow-1 container-p-y">
                <div className="row">
                    <div className="col-md-12">
                        <ul className="nav ss-nav nav-pills flex-column flex-md-row mb-3">
                            <li className="nav-item active">
                                <button><i className="bx bx-user me-1"></i> Profile Setting</button>
                            </li>
                            <li className="nav-item"  to="/change-password">
                                <Link to="/change-password">
                                    <button><i className="bx bx bxs-key me-1"></i> Change Password</button>
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <a className="nav-link active" href="#;"><i className="bx bx-user me-1"></i> Profile Setting</a>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/change-password"><i className="bx bx bxs-key me-1"></i> Change Password</Link>
                            </li> */}
                        </ul>
                        <form id="formAccountSettings" method="POST" onSubmit={formik.handleSubmit} encType="multipart/form-data" >
                            <div className="card mb-4">
                                <h5 className="card-header border-0">Profile Details</h5>
                                { user?.role === "Client" && 
                                <div className="card-body">
                                    <div className="d-flex align-items-start align-items-sm-center gap-4">
                                        <img src={imgSrc} alt="user-avatar" className="d-block rounded" height="100" width="100" id="uploadedAvatar" />
                                        <div className="button-wrapper">
                                            <label htmlFor="upload" className="btn btn-primary me-2 mb-4" tabIndex="0">
                                                <span className="d-none d-sm-block">Upload new {user?.role==='Client' ? 'logo' : 'photo'}</span>
                                                <i className="bx bx-upload d-block d-sm-none"></i>
                                                <input type="file" id="upload" className="account-file-input" hidden accept="image/png, image/jpeg" name='image'
                                                onChange={(e) => onFileChange(e.target.files[0])} 
                                                ref={inputFile}/>
                                            </label>
                                            <button type="button" className="btn btn-outline-secondary account-image-reset mb-4" onClick={resetImage}>
                                                <i className="bx bx-reset d-block d-sm-none"></i>
                                                <span className="d-none d-sm-block">Reset</span>
                                            </button>
                                            <p className="text-muted mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p>
                                        </div>
                                    </div>
                                </div>
                                }
                                <hr className="my-0" />
                                <div className="card-body">
                                        <div className="row">
                                            
                                            { user?.role === "Client" && 
                                                <div className="mb-3 col-md-6">
                                                    <label htmlFor="portfolioName" className="form-label">Portfolio Name</label>
                                                    <input className="form-control" type="text" id="portfolioName" name="portfolioName"                                        
                                                    onChange={formik.handleChange} 
                                                    onBlur={formik.handleBlur} 
                                                    value={formik.values.portfolioName}
                                                    placeholder="Your Portfolio name" />
                                                    {formik.touched.portfolioName && formik.errors.portfolioName && (
                                                        <div className="text-danger">{formik.errors.portfolioName}</div>
                                                    )}
                                                </div>
                                            }
                                            <div className="mb-3 col-md-6">
                                                <label htmlFor="name" className="form-label">{user?.role === "Client"?'Contact Name':'Name'}</label>
                                                <input className="form-control" type="text" id="name" name="name"
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.name}
                                                placeholder="Your name" autoFocus />
                                                {formik.touched.name && formik.errors.name && (
                                                    <div className="text-danger">{formik.errors.name}</div>
                                                )}
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label htmlFor="email" className="form-label">{user?.role === "Client"?'Contact Email':'Email'}</label>
                                                <input className="form-control" type="email" id="email" name="email"                                        
                                                onChange={formik.handleChange} 
                                                onBlur={formik.handleBlur} 
                                                value={formik.values.email}
                                                placeholder="Your email" />
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className="text-danger">{formik.errors.email}</div>
                                                )}
                                            </div>
                                            <div className="mb-3 col-md-6">
                                                <label className="form-label" htmlFor="phone">Phone Number</label>
                                                <div className="input-group input-group-merge">
                                                    <span className="input-group-text">AU (+61)</span>
                                                    <input className="form-control" type="text" id="phone" name="phone"                                 
                                                    onChange={formik.handleChange} 
                                                    onBlur={formik.handleBlur} 
                                                    value={formik.values.phone}
                                                    placeholder="Your Phone number" />
                                                </div>
                                                {formik.touched.phone && formik.errors.phone && (
                                                        <div className="text-danger">{formik.errors.phone}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mt-2">
                                            <button type="submit" className="btn btn-primary me-2">Save changes</button>
                                            {/* <button type="reset" className="btn btn-outline-secondary">Cancel</button> */}
                                        </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Layout>
    );
}