
import React, { useEffect, useState } from "react";
import { Layout } from "../Layout/Layout";
import { useFormik } from "formik";
import axios from "axios";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";

const highlightBackground = [
    "Total Gross Storage Revenue",
    "Total Gross Revenue",
    "Total Expenses",
    "Net Operating Profit (EBITDA)",
    "Core Market Value",
    "Indicative Market Value",
]

const highlightText = [
    "Operating Expenses",
    "Management Fee on Storage Revenue",
    "Management Fee on Additional Tenancies",
    "Total Expenses",
    "Non-Storage Income Rental Shortfall / Vacancy Allowance",
    "Essential Capital Expenditure",
    "Shortfall (p.a.)",
    "Deduction (assume 6 months)",
]

const showValuesInPercent = [
    "Occupancy",
    "Other Income % of Gross Revenue",
    "Non-Storage Income % of Gross Revenue",
    "Operating Expenses % of Gross Revenue",
    "Total Expenses % of Gross Revenue",
    "Profit Margin",
    "Capitalisation Rate",
    ""
]

const noFormatHeadings = [
    "NSA",
];

const specialHeadings = [
    "Shortfall (p.a.)",
    "Deduction (assume 6 months)",
];

const emptyRowAfter = [
    'Other Income % of Gross Revenue',
    'Non-Storage Income % of Gross Revenue',
    'Total Gross Revenue',
    'Total Expenses % of Gross Revenue',
    'Profit Margin',
    'Capitalisation Rate',
    'Core Market Value',
    'Additional Below the Line adjustment',
    '',
];

export default function StaticValuation() {
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [staticValuation, setStaticValuation] = useState([]);
    const [capitalValueRatePerMeterSquareOfTotalNSA, setCapitalValueRatePerMeterSquareOfTotalNSA] = useState({});
    const [loading, setLoading] = useState(false);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);

    useEffect(() => {
        if(user.role !== 'SuperAdmin'){
            navigate(-1)
        }
        const portfolioId = localStorage.getItem('selectedPortfolio');
        const facilityId = localStorage.getItem('selectedFacility');

        if (portfolioId) {
            setSelectedPortfolio(JSON.parse(portfolioId));
        }

        if (facilityId) {
            setSelectedFacility(JSON.parse(facilityId));
            getStaticValuation(facilityId)
        }
    }, []);

    const initialValues = {
        portfolioId: '',
        id: ''
    }

    const formik = useFormik({
        initialValues: initialValues,
    });

    const getStaticValuation = (facilityId) => {
        setLoading(true)
        if (!facilityId) {
            console.error("Invalid facilityId", facilityId);
            setLoading(false)
            return;
        }
        axios.get(`api/staticValuation?facilityId=${facilityId}`).then(res => {
            setLoading(false)
            if (res.data.success) {
                const result = res.data.data;
                setStaticValuation(result)
                // const capitalValueRatePerMeterSquareOfTotalNSA =  result.find(item => item.heading === "Capital Value Rate /m² of Total NSA");
                setCapitalValueRatePerMeterSquareOfTotalNSA(result.find(item => item.heading === "Capital Value Rate /m² of Total NSA"))
                // console.log("capitalValueRatePerMeterSquareOfTotalNSA===", capitalValueRatePerMeterSquareOfTotalNSA.High)
            }
        })
    }

    const formatterWithoutDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const formatterWithDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatValue = (value) => {
        console.log("value", value);
        const numericValue = parseFloat(value);
        const hasSixDigitsBeforeDecimal = Math.floor(Math.abs(numericValue)).toString().length >= 3;

        return hasSixDigitsBeforeDecimal
            ? formatterWithoutDecimals.format(value)
            : formatterWithDecimals.format(value);
    };

    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <Header
                    pageTitle={localStorage.getItem('selectedFacilityName')}
                    verificationStatus={localStorage.getItem('selectedFacilityVerificationStatus')}
                />
                {/* <FilterHeader 
                selectedPortfolio={selectedPortfolio}
                setSelectedPortfolio={setSelectedPortfolio}
                selectedFacility={selectedFacility}
                setSelectedFacility={setSelectedFacility}
                getPageData={getStaticValuation}
                setPageData={setStaticValuation}
                formik={formik}
            /> */}
                <div className="container-xxl flex-grow-1 container-p-y">
                    {selectedFacility !== null ?
                        <React.Fragment>
                            {/* <NavHeader /> */}
                            <div className="bg-white p-3 mb-3">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1 view">
                                        <div className="facilityTable table-responsive wrapper">
                                            <table className="table table-sm" id="Rj-scroll-fix">
                                                <thead>
                                                    <tr>
                                                        <th className="table-blank-space Rj-w-30 sticky-col first-col" scope="col"></th>
                                                        <th scope="col">Live Estimated Value </th>
                                                        <th scope="col">Indicative Market <br />Valuation (High)</th>
                                                        <th scope="col">Indicative Market <br />Valuation (Med)</th>
                                                        <th scope="col">Indicative Market <br />Valuation (Low)</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {staticValuation.map((values, keyIndex) => (
                                                        <React.Fragment key={keyIndex}>
                                                            <tr key={keyIndex}>
                                                                <td className={values.heading === '' ? "sticky-col first-col bg-white" : "theme-color-bg sticky-col first-col"}>{values.heading}</td>
                                                                <td style={{ fontWeight: values.heading === 'Total Expenses' ? 800 : 'normal', color: values.heading === 'Total Expenses' ? 'red!important' : 'inherit' }}
                                                                    className={
                                                                        `${highlightBackground.includes(values.heading) ? "bg-table" : ""}
                                                            ${highlightText.includes(values.heading) ? "color-red-table" : ""}
                                                            ${values.heading === 'Total Expenses' ? "color-red-table fw-600" : ""}
                                                            bg-white`
                                                                    }>

                                                                    {
                                                                        showValuesInPercent.includes(values.heading)
                                                                            ? (specialHeadings.includes(values.heading) ? "" :
                                                                                (values.heading === ""
                                                                                    ? values.Live
                                                                                    // : (values.heading === 'Occupancy' || values.heading === 'Other Income % of Gross Revenue' || values.heading === 'Capitalisation Rate')
                                                                                    //     ? parseFloat(values.Live).toFixed(2) + "%" 
                                                                                    : parseFloat(values.Live).toFixed(2) + "%"))
                                                                            : noFormatHeadings.includes(values.heading)
                                                                                ? specialHeadings.includes(values.heading) ? "" : parseFloat(values.Live).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                                : specialHeadings.includes(values.heading) ? ""
                                                                                    : (values.heading === "Average Storage Fee Rate"
                                                                                        ? formatterWithDecimals.format(values.Live)
                                                                                        : formatterWithoutDecimals.format(values.Live))
                                                                    }
                                                                </td>
                                                                <td style={{ fontWeight: values.heading === 'Total Expenses' ? 800 : 'normal', color: values.heading === 'Total Expenses' ? 'red!important' : 'inherit' }}
                                                                    className={
                                                                        `${highlightBackground.includes(values.heading) ? "bg-table" : ""}
                                                            ${highlightText.includes(values.heading) ? "color-red-table" : ""}
                                                            ${values.heading === 'Total Expenses' ? "color-red-table fw-600" : ""}
                                                            bg-white`
                                                                    }>
                                                                    {
                                                                        showValuesInPercent.includes(values.heading)
                                                                            ? values.heading === ''
                                                                                ? parseFloat(capitalValueRatePerMeterSquareOfTotalNSA.Live) > 0
                                                                                    ? (((parseFloat(capitalValueRatePerMeterSquareOfTotalNSA.High) - parseFloat(capitalValueRatePerMeterSquareOfTotalNSA.Live)) / parseFloat(capitalValueRatePerMeterSquareOfTotalNSA.Live)) * 100).toFixed(2) + "%"
                                                                                    : "0.00%"
                                                                                // : (values.heading === 'Occupancy' || values.heading === 'Other Income % of Gross Revenue' || values.heading === 'Capitalisation Rate')
                                                                                //     ? parseFloat(values.High).toFixed(2) + "%"
                                                                                : parseFloat(values.High).toFixed(2) + "%"
                                                                            : noFormatHeadings.includes(values.heading)
                                                                                ? parseFloat(values.High).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                                : (values.heading === 'Occupancy' || values.heading === 'Other Income % of Gross Revenue' || values.heading === 'Capitalisation Rate')
                                                                                    ? parseFloat(values.High).toFixed(2)
                                                                                    : (values.heading === "Average Storage Fee Rate"
                                                                                        ? formatterWithDecimals.format(values.High)
                                                                                        : formatterWithoutDecimals.format(values.High))
                                                                    }
                                                                </td>
                                                                <td style={{ fontWeight: values.heading === 'Total Expenses' ? 800 : 'normal', color: values.heading === 'Total Expenses' ? 'red!important' : 'inherit' }}
                                                                    className={
                                                                        `${highlightBackground.includes(values.heading) ? "bg-table" : ""}
                                                            ${highlightText.includes(values.heading) ? "color-red-table" : ""}
                                                            ${values.heading === 'Total Expenses' ? "color-red-table fw-600" : ""}
                                                            bg-white`
                                                                    }>
                                                                    {
                                                                        showValuesInPercent.includes(values.heading)
                                                                            ? values.heading === ''
                                                                                ? parseFloat(capitalValueRatePerMeterSquareOfTotalNSA.High) > 0
                                                                                    ? (((parseFloat(capitalValueRatePerMeterSquareOfTotalNSA.Med) - parseFloat(capitalValueRatePerMeterSquareOfTotalNSA.Live)) / parseFloat(capitalValueRatePerMeterSquareOfTotalNSA.High)) * 100).toFixed(2) + "%"
                                                                                    : "0.00%"
                                                                                // : (values.heading === 'Occupancy' || values.heading === 'Other Income % of Gross Revenue' || values.heading === 'Capitalisation Rate')
                                                                                //     ? parseFloat(values.Med).toFixed(2) + "%"
                                                                                : parseFloat(values.Med).toFixed(2) + "%"
                                                                            : noFormatHeadings.includes(values.heading)
                                                                                ? parseFloat(values.Med).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                                : (values.heading === 'Occupancy' || values.heading === 'Other Income % of Gross Revenue' || values.heading === 'Capitalisation Rate')
                                                                                    ? parseFloat(values.Med).toFixed(2)
                                                                                    : (values.heading === "Average Storage Fee Rate"
                                                                                        ? formatterWithDecimals.format(values.Med)
                                                                                        : formatterWithoutDecimals.format(values.Med))
                                                                    }
                                                                </td>
                                                                <td style={{ fontWeight: values.heading === 'Total Expenses' ? 800 : 'normal', color: values.heading === 'Total Expenses' ? 'red!important' : 'inherit' }}
                                                                    className={
                                                                        `${highlightBackground.includes(values.heading) ? "bg-table" : ""}
                                                            ${highlightText.includes(values.heading) ? "color-red-table" : ""}
                                                            ${values.heading === 'Total Expenses' ? "color-red-table fw-600" : ""}
                                                            bg-white`
                                                                    }>
                                                                    {
                                                                        showValuesInPercent.includes(values.heading)
                                                                            ? values.heading === ''
                                                                                ? parseFloat(capitalValueRatePerMeterSquareOfTotalNSA.Med) > 0
                                                                                    ? (((parseFloat(capitalValueRatePerMeterSquareOfTotalNSA.Low) - parseFloat(capitalValueRatePerMeterSquareOfTotalNSA.Live)) / parseFloat(capitalValueRatePerMeterSquareOfTotalNSA.Med)) * 100).toFixed(2) + "%"
                                                                                    : "0.00%"
                                                                                // : (values.heading === 'Occupancy' || values.heading === 'Other Income % of Gross Revenue' || values.heading === 'Capitalisation Rate')
                                                                                //     ? parseFloat(values.Low).toFixed(2) + "%"
                                                                                : parseFloat(values.Low).toFixed(2) + "%"
                                                                            : noFormatHeadings.includes(values.heading)
                                                                                ? parseFloat(values.Low).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                                                                : (values.heading === 'Occupancy' || values.heading === 'Other Income % of Gross Revenue' || values.heading === 'Capitalisation Rate')
                                                                                    ? parseFloat(values.Low).toFixed(2)
                                                                                    : (values.heading === "Average Storage Fee Rate"
                                                                                        ? formatterWithDecimals.format(values.Low)
                                                                                        : formatterWithoutDecimals.format(values.Low))
                                                                    }
                                                                </td>
                                                            </tr>
                                                            {
                                                                emptyRowAfter.includes(values.heading)
                                                                    ? <tr key={'EmptyRow'} height="20px;">
                                                                        <td className="bg-blank-space"></td>
                                                                        <td className="bg-blank-space"></td>
                                                                        <td className="bg-blank-space"></td>
                                                                        <td className="bg-blank-space"></td>
                                                                        <td className="bg-blank-space"></td>
                                                                    </tr>
                                                                    : ''
                                                            }
                                                        </React.Fragment>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        :
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1 text-center">
                                        <div className="mb-3 mt-3">
                                            <h4 style={{ color: "#697a8d" }}>Please Select a {selectedPortfolio === null ? "Portfolio" : "Facility"}!</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Layout>
        </>
    );
}