import React, { forwardRef, useEffect, useState } from 'react';
import PieChart from "./charts/pie";
import DoughnutChart from "./charts/doughnut";
import HistoricValueChart from './charts/historicValue';
import HistoricRevenueChart from './charts/historicRevenue';

const PortfolioPDF = forwardRef(({ portfolioId, portfolioName, currency, allFacilities, totalEstimatedValue, previousMonthEstimatedValuePercentage, totalNetOperatingProfit, isPortfolioValuationVerified, totalSynergisticValue, previousMonthSynergisticValuePercentage, portfolioAdoptedAndActualData, doughnutTotalGrossRevenue, doughnutTotalOperatingExpenses, doughnuNetOperatingProfit, valuationPieData, expensesPieData, historicValueFilters, historicRevenueFilters, imgSrc }, ref) => {
    const [customClass, setCustomClass] = useState("col-md-10 col-12 mb-20");
    const [pieChartClass, setPieChartClass] = useState("col-md-8 mb-20");

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const screenPercentage = (screenWidth / window.outerWidth) * 100;
            if (screenPercentage == 100) {
                setCustomClass("col-md-10 col-12 mb-20");
                setPieChartClass("col-md-8 mb-20")
            }
            else if (screenPercentage == 90) {
                setCustomClass("col-md-9 col-12 mb-20");
                setPieChartClass("col-md-8 mb-20")
            } else {
                setCustomClass("col-md-8 col-12 mb-20");
                setPieChartClass("col-md-4")
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const formatCash = Intl.NumberFormat("en-US", {
        notation: "compact",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    return (
        <div ref={ref}>
            <div className="container-xxl countries-header layout-navbar navbar-detached z-index-auto">
                <div className="flex-grow-1 container-p-y" style={{ backgroundColor: "#f6f6f6" }}>
                    {/* Portfolio Name and Logo */}
                    <div className="row align-items-md-center ss-title px-2">
                        <div className="col-md-8 col-12">
                            <div className="d-flex align-items-center">
                                <img src={imgSrc} alt="logo" height="40px" style={{ marginRight: '10px', marginLeft: '10px' }} />
                                <h3 className="fw-semi-bold text-center m-0 p-0">{portfolioName}</h3>
                            </div>
                        </div>
                    </div>
                    {/* Portfolio Name and Logo */}

                    <div className="container-xxl flex-grow-1 container-p-y" style={{ backgroundColor: "#f6f6f6" }}>
                        <div className="row mb-3">
                            {/* Portfolio Estimated Total Value Card*/}
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-20">
                                <div className="card card-border-shadow-primary h-100">
                                    <div className="card-body ss-cardBody report-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0 h-50px">Portfolio Estimated<br /> Total Value</h6>
                                            {/* <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span> */}
                                        </div>
                                        <div className="card-amount">
                                            ${formatCash.format(totalEstimatedValue) + " " + currency}
                                        </div>
                                        <div className="d-flex align-items-center card-details">
                                            <p className="me-2">From last month:</p>
                                            <p className={`fw-700 fs-color-${previousMonthEstimatedValuePercentage > 0
                                                ? 'green' : 'red'}`}>
                                                {previousMonthEstimatedValuePercentage ?? 0}%
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Weighted Average Capitalisation Rate Card*/}
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-20">
                                <div className="card card-border-shadow-primary h-100">
                                    <div className="card-body ss-cardBody report-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0 h-50px">Weighted Average<br /> Capitalisation Rate</h6>
                                            {/* <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span> */}
                                        </div>
                                        <div className="card-amount">
                                            {totalNetOperatingProfit !== 0 && totalEstimatedValue !== 0 ? ((totalNetOperatingProfit / totalEstimatedValue) * 100).toFixed(2) : 0}%
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Total no. of Facilities Card*/}
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-20">
                                <div className="card card-border-shadow-primary h-100">
                                    <div className="card-body ss-cardBody  report-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0 h-50px">Total no. of Facilities</h6>
                                            {/* <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span> */}
                                        </div>
                                        <div className="card-amount">{allFacilities?.length || 0}</div>
                                        <div className="d-flex align-items-center card-details">
                                            <p className="fs-color-red">{isPortfolioValuationVerified || 0} not verified</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Portfolio Estimated Synergistic Value Card*/}
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12 mb-20">
                                <div className="card card-border-shadow-primary h-100">
                                    <div className="card-body ss-cardBody  report-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0 h-50px">Portfolio Estimated<br /> Synergistic Value</h6>
                                            {/* <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span> */}
                                        </div>
                                        <div className="card-amount">${formatCash.format(totalSynergisticValue)}</div>
                                        <div className="d-flex align-items-center card-details">
                                            <p className="me-2">From last month:</p>
                                            <p className={`fw-700 fs-color-${previousMonthSynergisticValuePercentage > 0
                                                ? 'green' : 'red'}`}>
                                                {previousMonthSynergisticValuePercentage ?? 0}%
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Doughnut Charts */}
                            <div className="col-md-12 mb-20 mb-5">
                                <div className="card card-border-shadow-primary">
                                    <div className="card-body ss-cardBody  report-cardBody">
                                        <div className="card-title">
                                            <h3 className="d-block fw-12 mb-0">Last Month’s Portfolio Results</h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 col-12 mb-20">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="text-center card-title-padding">Total Gross Revenue <br />Performance</h6>
                                                    {/* <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span> */}
                                                </div>
                                                <div className="text-center">
                                                    <div className="graph">
                                                        {doughnutTotalGrossRevenue
                                                            ? <DoughnutChart
                                                                chartId="chart1"
                                                                // centerText={totalGrossRevenueCenterText} 
                                                                data={[portfolioAdoptedAndActualData.TotalGrossRevenueActual, portfolioAdoptedAndActualData.TotalGrossRevenueAdopted]}
                                                                chartTypeText1={'Revenue'}
                                                                chartTypeText2={'Total Gross'}
                                                                percentValue={portfolioAdoptedAndActualData && portfolioAdoptedAndActualData.TotalGrossRevenueActualByAdopted ? (parseFloat(portfolioAdoptedAndActualData.TotalGrossRevenueActualByAdopted).toFixed(2) + '%') : 0}
                                                            />
                                                            : <p className="mb-0 mt-4 text-center">No data available!</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12 mb-20">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="text-center card-title-padding">Total Operating Expenses <br />Performance</h6>
                                                    {/* <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span> */}
                                                </div>
                                                <div className="text-center">
                                                    <div className="graph">
                                                        {doughnutTotalOperatingExpenses
                                                            ? <DoughnutChart
                                                                chartId="chart2"
                                                                // centerText={totalOperatingExpensesCenterText} 
                                                                data={[
                                                                    portfolioAdoptedAndActualData.totalOperatingExpensesActual, 
                                                                    portfolioAdoptedAndActualData.totalOperatingExpensesAdopted,
                                                                    portfolioAdoptedAndActualData?.OperatingExpensesPercentageOfGrossRevenue || 0
                                                                ]}
                                                                chartTypeText1={'Expenses'}
                                                                chartTypeText2={'Total Operating'}
                                                                percentValue={portfolioAdoptedAndActualData && portfolioAdoptedAndActualData.totalOperatingExpensesActualByAdopted ? (parseFloat(portfolioAdoptedAndActualData.totalOperatingExpensesActualByAdopted).toFixed(2) + '%') : 0}
                                                            />
                                                            : <p className="mb-0 mt-4 text-center">No data available!</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-around card-details">
                                                    <p className="me-2">Expenses as % of Gross Revenue:</p>
                                                    <p className="fw-700 fs-color-green">{parseFloat(portfolioAdoptedAndActualData?.OperatingExpensesPercentageOfGrossRevenue || 0).toFixed(2)}%</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-12 mb-20">
                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                    <h6 className="text-center card-title-padding">Total Net Operating Profit <br />Performance</h6>
                                                    {/* <span className="badge badge-center rounded-pill bg-label-success"><ArrowTooltips /></span> */}
                                                </div>
                                                <div className="text-center">
                                                    <div className="graph">
                                                        {doughnuNetOperatingProfit
                                                            ? <DoughnutChart
                                                                chartId="chart3"
                                                                // centerText={netOperatingProfitCenterText} 
                                                                data={[portfolioAdoptedAndActualData.NetOperatingProfitActual, portfolioAdoptedAndActualData.NetOperatingProfitAdopted]}
                                                                chartTypeText1={'Profit'}
                                                                chartTypeText2={'Net Operating'}
                                                                percentValue={portfolioAdoptedAndActualData && portfolioAdoptedAndActualData.NetOperatingProfitActualByAdopted ? (parseFloat(portfolioAdoptedAndActualData.NetOperatingProfitActualByAdopted).toFixed(2) + '%') : 0}
                                                            />
                                                            : <p className="mb-0 mt-4 text-center">No data available!</p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-around card-details">
                                                    <p className="me-2">Actual Profit Margin:</p>
                                                    <p className="fw-700 fs-color-green">+{parseFloat(portfolioAdoptedAndActualData?.ProfitMarginActual || 0).toFixed(2)}%</p>
                                                </div>
                                                <div className="d-flex align-items-center justify-content-around card-details">
                                                    <p className="me-2">Adopted Profit Margin:</p>
                                                    <p className="">{parseFloat(portfolioAdoptedAndActualData?.ProfitMarginAdopted || 0).toFixed(2)}%</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* Doughnut Charts */}

                            {/* tree */}
                            <div className="col-md-12 mb-20 mt-5">
                                <div className="card card-border-shadow-primary  report-cardBody mt-5">
                                    <ul className="tree mt-4">
                                        <li className="tree-node row">
                                            <div className="col-md-4">
                                                <button type="button"
                                                    className={`btn tree-content btn-primary `}>Gross Revenue Details</button>
                                            </div>

                                            <div className="col-md-12">
                                                <ul className="tree-branch row">
                                                    <li className="tree-node col-md-4">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <div className="card-title d-flex align-items-baseline">
                                                                <h6 className="text-center mb-0">Storage Unit Revenue</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <table className="">
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Adopted</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-textgreen table-fs-18">
                                                                        ${formatCash.format(parseFloat(portfolioAdoptedAndActualData?.StorageUnitRevenueAdopted || 0))}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Actual</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-textDark table-fs-18">
                                                                        ${formatCash.format(parseFloat(portfolioAdoptedAndActualData?.StorageUnitRevenueActual || 0))}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Actual / Adopted</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-fs18">
                                                                        {parseFloat(portfolioAdoptedAndActualData?.StorageUnitRevenueActualByAdopted || 0).toFixed(2)}%
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </li>

                                                    <li className="tree-node col-md-4">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <div className="card-title d-flex align-items-baseline">
                                                                <h6 className="text-center mb-0">Other Income</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <table className="">
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Adopted</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-textgreen table-fs-18">
                                                                        ${formatCash.format(parseFloat(portfolioAdoptedAndActualData?.OtherIncomeAdopted || 0))}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Actual</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-textDark table-fs-18">
                                                                        ${formatCash.format(parseFloat(portfolioAdoptedAndActualData?.OtherIncomeActual || 0))}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Actual / Adopted</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-fs18">
                                                                        {parseFloat(portfolioAdoptedAndActualData?.OtherIncomeActualByAdopted || 0).toFixed(2)}%
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </li>

                                                    <li className="tree-node col-md-4">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <div className="card-title d-flex align-items-baseline">
                                                                <h6 className="text-center mb-0">Non-Storage Income</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <table className="">
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Adopted</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-textgreen table-fs-18">
                                                                        ${formatCash.format(parseFloat(portfolioAdoptedAndActualData?.NonStorageIncomeAdopted || 0))}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Actual</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-textDark table-fs-18">
                                                                        ${formatCash.format(parseFloat(portfolioAdoptedAndActualData?.NonStorageIncomeActual || 0))}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Actual / Adopted</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-fs18">
                                                                        {parseFloat(portfolioAdoptedAndActualData?.NonStorageIncomeActualByAdopted || 0).toFixed(2)}%
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="tree mt-4 ss-tree-node">
                                        <li className="tree-node row">
                                            <div className="col-md-4">
                                                <button type="button"
                                                    className={`btn tree-content btn-primary`}
                                                >Storage Unit Performance</button>
                                            </div>

                                            <div className="col-md-12">
                                                <ul className="tree-branch row">
                                                    <li className="tree-node col-md-4">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <div className="card-title d-flex align-items-baseline">
                                                                <h6 className="text-center mb-0">Average Storage Fee Rate</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <table className="">
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Adopted</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-textgreen table-fs-18">
                                                                        ${formatCash.format(parseFloat(portfolioAdoptedAndActualData?.AverageStorageFeeRateAdpoted || 0))}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Actual</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-textDark table-fs-18">
                                                                        ${formatCash.format(parseFloat(portfolioAdoptedAndActualData?.AverageStorageFeeRateActual || 0))}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Actual / Adopted</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-fs18">
                                                                        {parseFloat(portfolioAdoptedAndActualData?.AverageStorageFeeRateActualByAdopted || 0).toFixed(2)}%
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </li>

                                                    <li className="tree-node col-md-4">
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <div className="card-title d-flex align-items-baseline">
                                                                <h6 className="text-center mb-0">Average Occupancy (by area)</h6>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-center">
                                                            <table className="">
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Adopted</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-textgreen table-fs-18">
                                                                        ${formatCash.format(parseFloat(portfolioAdoptedAndActualData?.AverageOccupancyByAreaAdopted || 0))}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Actual</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-textDark table-fs-18">
                                                                        ${formatCash.format(parseFloat(portfolioAdoptedAndActualData?.AverageOccupancyByAreaActual || 0))}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="text-start text-color table-fs-16">Actual / Adopted</td>
                                                                    <td style={{ width: '20px' }}></td>
                                                                    <td className="table-fs18">{parseFloat(portfolioAdoptedAndActualData?.AverageOccupancyByAreaActualByAdopted || 0).toFixed(2)}%</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* tree */}

                            {/* Bar Charts */}
                            <div className="col-md-12 mb-20">
                                <div className="card card-border-shadow-primary">
                                    <div className="card-body ss-cardBody  report-cardBody">
                                        <div className="card-title">
                                            <h3 className="d-block fw-12 mb-0">Portfolio Historic Value Trend</h3>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-md-10 col-12 mb-20"> */}
                                            <div className={customClass}>
                                                <div className="text-center">
                                                    <div className="graph">
                                                        {currency ?
                                                            <HistoricValueChart
                                                                portfolioId={portfolioId}
                                                                currencyFilter={currency}
                                                                filterType={historicValueFilters}
                                                            />
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <ul className="nav ss-nav nav-tabs border-bottom" id="myTab" role="tablist">
                                                <li className={`nav-item ${historicValueFilters === "monthly" && "active"
                                                    }`}>
                                                    <button>Monthly</button>
                                                </li>
                                                <li className={`nav-item ${historicValueFilters === "6-monthly" && "active"
                                                    }`}>
                                                    <button>6 Monthly</button>
                                                </li>
                                                <li className={`nav-item ${historicValueFilters === "yearly" && "active"
                                                    }`}>
                                                    <button>Annually</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mb-20 mt-5">
                                <div className="card card-border-shadow-primary mt-3">
                                    <div className="card-body ss-cardBody  report-cardBody">
                                        <div className="card-title">
                                            <h3 className="d-block fw-12 mb-0">Portfolio Historic Revenue Trend</h3>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-md-10 col-12 mb-20"> */}
                                            <div className={customClass}>
                                                <div className="text-center">
                                                    <div className="graph">
                                                        {currency ?
                                                            <HistoricRevenueChart
                                                                portfolioId={portfolioId}
                                                                currencyFilter={currency}
                                                                filterType={historicRevenueFilters}
                                                            />
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <ul className="nav ss-nav nav-tabs border-bottom" id="myTab" role="tablist">
                                                <li className={`nav-item ${historicRevenueFilters === "monthly" && "active"
                                                    }`}>
                                                    <button>Monthly</button>
                                                </li>
                                                <li className={`nav-item ${historicRevenueFilters === "6-monthly" && "active"
                                                    }`}>
                                                    <button>6 Monthly</button>
                                                </li>
                                                <li className={`nav-item ${historicRevenueFilters === "yearly" && "active"
                                                    }`}>
                                                    <button>Annually</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Bar Charts */}

                            {/* Pie Charts */}
                            <div className="col-md-12 mb-20">
                                <div className="card card-border-shadow-primary mt-3">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title">
                                            <h3 className="d-block fw-12 mb-0">Portfolio Makeup by Facility Value</h3>
                                        </div>
                                        <div className="row">
                                            <div className={pieChartClass}>
                                                <div className="text-center">
                                                    <div className="graph">
                                                        {valuationPieData && valuationPieData.adoptedValues.length > 0 ?
                                                            <PieChart pieData={valuationPieData} pieId="pie1" />
                                                            : <p className="mb-0 mt-4 text-center">No data available!</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mb-20 mt-5">
                                <div className="card card-border-shadow-primary mt-5">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title">
                                            <h3 className="d-block fw-12 mb-0">Net Profit Makeup by Facility Value</h3>
                                        </div>
                                        <div className="row">
                                            <div className={pieChartClass}>
                                                <div className="text-center">
                                                    <div className="graph">
                                                        {valuationPieData && valuationPieData.totalOperatingExpensesValues.length > 0 ?
                                                            <PieChart pieData={expensesPieData} pieId="pie2" />
                                                            : <p className="mb-0 mt-4 text-center">No data available!</p>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Pie Charts */}

{/* Disclaimer */}
<div className="col-md-12 mb-20">
                                <div className="card">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title">
                                            <h3 className="d-block fw-12 mb-0">
                                                Disclaimer
                                            </h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-12 mb-20 card-body">
                                                <p>
                                                    This indicative assessment of value is an estimate only and provides the “estimated market value (EMV)” of the
                                                    portfolio, with each asset calculated on a going concern (self storage use) basis. <span className="border-bottom-disclaimer">It does not constitute a formal
                                                    valuation.</span> The EMV is based off third party trading performance data and user inputs. Specific research into the
                                                    property/ies and the trading catchment/s has not been undertaken. The EMV does not take into account any
                                                    property specific attributes or external factors which may impact value. Inspection of the facility/ies has not
                                                    occurred.
                                                </p>
                                                <p>
                                                    The EMV is a desktop estimate that can only be used by the licensee (“user”) as a tool for informative purposes. It
                                                    should not be relied upon for decision-making purposes. Specifically, the EMV must not be used, and is not suitable
                                                    for, mortgage security purposes, transaction purposes or investment decisions.</p>
                                                <p>
                                                    The EMV is based upon financial data provided by a third party and inputs by the user. Storval Pty Ltd does not make
                                                    any warranty as to the accuracy, completeness or reliability of the information; nor does Storval Pty Ltd accept any
                                                    liability arising in any way from any omissions or errors. The EMV should not be regarded as advice, nor should it be
                                                    relied upon by any party. Storval is an estimation tool only. Professional valuation advice is recommended before
                                                    making any investment decisions. Full valuations that adhere to the International Valuation Standards, inclusive of
                                                    physical inspections, are recommended to confirm the estimated assessments shown.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Disclaimer */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
});

export default PortfolioPDF;