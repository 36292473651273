import { useEffect, useState } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';

export default function RequestAssistance({show, setShow, portfolioId, portfolio, facilityName,  ...rest}) {
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const [preferredMethod, setPreferredMethod] = useState('');
    const handleClose = () => {
        setShow(false);
        formik.resetForm();
    }
    const [assistanceDetails, setAssistanceDetails] = useState([]);
    const [loading, setLoading] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const initialValues = {
        name: user.name,
        email: user.email,
        phone: user.phone,
        preferredMethod: '',
        assistanceMessage: '',
    }

    useEffect(() => {
        if(show === true)
            getAssistanceDetails();
    }, [show])

    const getAssistanceDetails = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/auth/assistanceSettings`);
            setLoading(false)
            if (response.data.success) {
                setAssistanceDetails(response.data.data);
            } else {
                Swal.fire({
                    icon: 'error',
                    // title: 'Oops...',
                    text: response.data.message || 'An error occurred while fetching user list!',
                });
            }
        } catch (err) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Server Error',
                text: 'Failed to connect to the server. Please try again later.',
            });
        }
    };

    const validationSchema = yup.object().shape({
        preferredMethod: yup.string().required('Preferred Method is required').test(
            'check-contact-info',
            'Please provide valid contact information based on your preferred method.',
            function(value) {
                const { email, phone } = this.parent;
                if (value === 'Phone' && !phone) {
                    return this.createError({ path: 'phone', message: 'Phone number is required for Phone method' });
                }
                if (value === 'Email' && !email) {
                    return this.createError({ path: 'email', message: 'Email is required for Email method' });
                }
                return true;
            }
        ),
        name: yup.string().required('Name is required'),
        email: yup.string().email('Enter a valid email').nullable(),
        phone: yup.string()
            .nullable()
            .notRequired()
            .min(10, "Must be more than 10 characters")
            .max(20, "Must be less than 20 characters"),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });
    
     const handleSubmit = (values) => {
        values.portfolioName = portfolio.name;
        values.facilityName = facilityName;
        values.receiverEmail = assistanceDetails.receiverEmail;
        // console.log(values);
        axios.post('/api/assistanceRequest', values).then((res) =>{
            setShow(false);
            if(res.data.success){
                // Swal.fire(res.data.message, '', 'success').then(result => {
                //     formik.resetForm();
                //     setPreferredMethod('');       
                // })
                Toast.fire({
                    icon: 'success',
                    title: res.data.message
                });
                formik.resetForm();
                setPreferredMethod('');  
            }
            else{
                // Swal.fire(res.data.message, '', 'info')
                Toast.fire({
                    icon: 'info',
                    title: res.data.message
                });
            }
        }).catch((error) => {
            setShow(false);
            Swal.fire(error.message, '', 'info')        
        });
    }   

    const handlePopupClose = () => {
        formik.resetForm();
        setPreferredMethod('');
        handleClose()
    }

    const handlePreferredMethodChange = (event) => {
        const selectedPreferredMethod = event.target.value;
        setPreferredMethod(selectedPreferredMethod);
        formik.setFieldValue('preferredMethod', selectedPreferredMethod);
    };

    return(
        <Modal show={show} onHide={handlePopupClose}>
            <Modal.Header closeButton>
            <Modal.Title>Request Assisstance</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-name">Name</label>
                                <div className="input-group input-group-merge">
                                    <span id="basic-icon-default-name" className="input-group-text"><i className="bx bx-user"></i></span>
                                    <input name="name" type="text" className="form-control" id="basic-icon-default-name" placeholder="Your name" aria-label="Rajiv Doe" aria-describedby="basic-icon-default-fullname2" 
                                    value={formik.values.name} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className="text-danger">{formik.errors.name}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-email">Email</label>
                                <div className="input-group input-group-merge">
                                    <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                                    <input name="email" type="text" id="basic-icon-default-email" className="form-control" placeholder="Your email" aria-label="Rajiv.doe@example.com" aria-describedby="basic-icon-default-email2" 
                                    value={formik.values.email} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                </div>
                                {formik.touched.email && formik.errors.email && (
                                    <div className="text-danger">{formik.errors.email}</div>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-phone">Phone No</label>
                                <div className="input-group input-group-merge">
                                    <span id="basic-icon-default-phone2" className="input-group-text"><i className="bx bx-phone"></i></span>
                                    <input name="phone" type="text" id="basic-icon-default-phone" className="form-control phone-mask" placeholder="658 799 8941" aria-label="658 799 8941" aria-describedby="basic-icon-default-phone2"
                                    value={formik.values.phone} 
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}/>
                                </div>
                                {formik.errors.phone && (
                                    <div className="text-danger">{formik.errors.phone}</div>
                                )}
                            </div>
                        </div>
                        <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-PreferredMethod">Preferred Method</label>
                                <div className="input-group input-group-merge">
                                    <input name="preferredMethod" type="radio" value="Phone" id="radio1" checked={preferredMethod === 'Phone'} onChange={handlePreferredMethodChange}/>&nbsp;
                                    <label htmlFor="radio1">Phone</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input name="preferredMethod" type="radio" value="Email" id="radio2" checked={preferredMethod === 'Email'} onChange={handlePreferredMethodChange}/>&nbsp;
                                    <label htmlFor="radio2">Email</label>&nbsp;
                                </div>
                                {formik.touched.preferredMethod && formik.errors.preferredMethod && (
                                    <div className="text-danger">{formik.errors.preferredMethod}</div>
                                )}
                            </div>
                        </div>
                        </div> 
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <p>{assistanceDetails.message}</p>
                                    <textarea name="assistanceMessage" className="form-control" rows="4" value={formik.values.assistanceMessage} onChange={formik.handleChange} onBlur={formik.handleBlur}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-primary" >Send</button>
                </Modal.Footer>
            </form>
        </Modal>
    ); 
}