import React, { useEffect, useRef, useState } from 'react';
import { Pie } from 'react-chartjs-2';

const PieChart = ({ pieData, pieId }) => {
    const chartRef = useRef(null);
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: [{
            data: [],
            backgroundColor: []
        }]
    });

    const formatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    useEffect(() => {
        if (pieData) {
            const { facilitiesNames, adoptedValues, totalOperatingExpensesValues } = pieData;
            const data = pieId === 'pie1' ? adoptedValues : totalOperatingExpensesValues;
            setChartData({
                labels: facilitiesNames.map(value => value),
                datasets: [{
                    data: data.map(value => parseFloat(value)),
                    backgroundColor: [
                        "#FFCC66", "#95C7D1", "#CBAB9B", "#F09976", "#7E7BEB", "#BD89DD", "#FB6B6E", "#71F574", "#69F2FD", "#F076DC", "#8FD7AA", "#FFD27A", "#A3D1DB", "#D1B1A3", "#F0A188", "#8783F0", "#C495E3", "#FC7A7D", "#7BFA7E", "#74F6FF", "#F28AE1", "#9BDEB5"
                    ]
                }]
            });
        }
    }, [pieData, pieId]);

    // Custom legend with progress bars
    // const generateCustomLegend = () => {
    //     if (!chartData.labels.length || !chartData.datasets[0].data.length) {
    //         return null;
    //     }

    //     const total = chartData.datasets[0].data.reduce((acc, value) => acc + value, 0);

    //     const legendEntries = chartData.labels.map((label, index) => {
    //         const value = chartData.datasets[0].data[index];
    //         const formattedValue = formatter.format(value);
    //         const percentage = total !== 0 && value !== 0 ? ((value / total) * 100).toFixed(2) : 0;
    //         const backgroundColor = chartData.datasets[0].backgroundColor[index];

    //         return { label, value, formattedValue, percentage, backgroundColor };
    //     });

    //     const displayEntries = legendEntries.length > 5 ? legendEntries.slice(0, 5) : legendEntries;
    //     const others = legendEntries.length > 5 
    //         ? legendEntries.slice(5).reduce((acc, curr) => acc + curr.value, 0)
    //         : null;

    //     return (
    //         <div>
    //             {displayEntries.map((entry, index) => (
    //                 <div key={index} style={{ marginBottom: '15px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    //                     <div style={{
    //                         marginBottom: '5px',
    //                         color: '#666',
    //                         fontSize: '12px',
    //                         fontWeight: '600'
    //                     }}>
    //                         {entry.label} ({entry.percentage}%)
    //                     </div>
    //                     <div style={{
    //                         width: '150px',
    //                         backgroundColor: '#F0F3F0',
    //                         height: '5px',
    //                         borderRadius: '5px',
    //                         overflow: 'hidden',
    //                         fontWeight: '800'
    //                     }}>
    //                         <div style={{
    //                             width: `${entry.percentage}%`,
    //                             backgroundColor: entry.backgroundColor,
    //                             height: '100%',
    //                             borderRadius: '5px'
    //                         }}>
    //                         </div>
    //                     </div>
    //                 </div>
    //             ))}
    //             {others !== null && (
    //                 <div style={{ marginBottom: '15px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
    //                     <div style={{
    //                         marginBottom: '5px',
    //                         color: '#666',
    //                         fontSize: '12px',
    //                         fontWeight: '600'
    //                     }}>
    //                         Others ({((others / total) * 100).toFixed(2)}%)
    //                     </div>
    //                     <div style={{
    //                         width: '150px',
    //                         backgroundColor: '#F0F3F0',
    //                         height: '5px',
    //                         borderRadius: '5px',
    //                         overflow: 'hidden',
    //                         fontWeight: '800'
    //                     }}>
    //                         <div style={{
    //                             width: `${((others / total) * 100).toFixed(2)}%`,
    //                             backgroundColor: '#CCCCCC', // You can choose a different color for "Others"
    //                             height: '100%',
    //                             borderRadius: '5px'
    //                         }}>
    //                         </div>
    //                     </div>
    //                 </div>
    //             )}
    //         </div>
    //     );
    // };

    const generateCustomLegend = () => {
        if (!chartData.labels.length || !chartData.datasets[0].data.length) {
            return null;
        }
    
        const total = chartData.datasets[0].data.reduce((acc, value) => acc + value, 0);
        const maxDisplayEntries = 5;
    
        // Map the legend entries
        let legendEntries = chartData.labels.map((label, index) => {
            const value = chartData.datasets[0].data[index];
            const formattedValue = formatter.format(value);
            const percentage = total !== 0 && value !== 0 ? ((value / total) * 100).toFixed(2) : 0;
            const backgroundColor = chartData.datasets[0].backgroundColor[index];
    
            return { label, value, formattedValue, percentage: parseFloat(percentage), backgroundColor };
        });
    
        legendEntries.sort((a, b) => b.percentage - a.percentage);
    
        // Show top `maxDisplayEntries`
        const displayEntries = legendEntries.slice(-maxDisplayEntries);
        
        // Only create "Others" if there are more than `maxDisplayEntries` facilities
        const others = legendEntries.length > maxDisplayEntries
            ? legendEntries.slice(0, legendEntries.length - maxDisplayEntries)
            : [];
    
        const othersTotal = others.reduce((acc, curr) => acc + curr.value, 0);
        const othersPercentage = total !== 0 ? ((othersTotal / total) * 100).toFixed(2) : 0;
    
        return (
            <div>
                {displayEntries.map((entry, index) => (
                    <div key={index} style={{ marginBottom: '15px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{
                            marginBottom: '5px',
                            color: '#666',
                            fontSize: '12px',
                            fontWeight: '600'
                        }}>
                            {entry.label} ({entry.percentage}%)
                        </div>
                        <div style={{
                            width: '150px',
                            backgroundColor: '#F0F3F0',
                            height: '5px',
                            borderRadius: '5px',
                            overflow: 'hidden',
                            fontWeight: '800'
                        }}>
                            <div style={{
                                width: `${entry.percentage}%`,
                                backgroundColor: entry.backgroundColor,
                                height: '100%',
                                borderRadius: '5px'
                            }}>
                            </div>
                        </div>
                    </div>
                ))}
                {others.length > 0 && (
                    <div style={{ marginBottom: '15px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <div style={{
                            marginBottom: '5px',
                            color: '#666',
                            fontSize: '12px',
                            fontWeight: '600'
                        }}>
                            Others ({othersPercentage}%)
                        </div>
                        <div style={{
                            width: '150px',
                            backgroundColor: '#F0F3F0',
                            height: '5px',
                            borderRadius: '5px',
                            overflow: 'hidden',
                            fontWeight: '800'
                        }}>
                            <div style={{
                                width: `${othersPercentage}%`,
                                backgroundColor: '#CCCCCC',
                                height: '100%',
                                borderRadius: '5px'
                            }}>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };
    
    const options = {
        aspectRatio: 1.5, //control the size of chart
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false, // Disable the default legend
            },
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0
            }
        },
        elements: {
            arc: {
                borderWidth: 0
            },
        }
    };

    return (
        <div className='chart-container row align-items-center' >
            {/* Chart Section */}
            <div className='chart-section col-md-7'>
                <Pie ref={chartRef} data={chartData} options={options} width={300} height={300} />
            </div>
            {/* Legend Section */}
            <div className="custom-legend col-md-5">
                {generateCustomLegend()}
            </div>
        </div>
    );
};

export default PieChart;