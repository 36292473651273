import { Link } from "react-router-dom"

export default function SignUp(){
    return(
        <div className="container-xxl">
            <div className="authentication-wrapper authentication-basic container-p-y">
                <div className="authentication-inner">
                    <div className="card">
                        <div className="card-body card-padding">
                            <div className="app-brand justify-content-center">
                                <Link to="#" className="app-brand-link gap-2">
                                    <img src={process.env.REACT_APP_BASE_URL+"assets/img/storval-logo.png"}
                                    alt="" height="55px" />  
                                </Link>
                            </div>
                            <h2 className="mb-4 text-center fw-bold">Adventure starts here 🚀</h2>
                            <h4 className="mb-4 text-center">Make your app management easy and fun!</h4>

                            <form id="formAuthentication" className="mb-3" method="POST">
                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">Username</label>
                                    <input type="text" className="form-control" id="username" name="username" placeholder="Enter your username" autoFocus />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email</label>
                                    <input type="text" className="form-control" id="email" name="email" placeholder="Enter your email" />
                                </div>
                                <div className="mb-3 form-password-toggle">
                                    <label className="form-label" htmlFor="password">Password</label>
                                    <div className="input-group input-group-merge">
                                        <input type="password" id="password" className="form-control" name="password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;" aria-describedby="password" />
                                        <span className="input-group-text cursor-pointer"><i className="bx bx-hide"></i></span>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="terms-conditions" name="terms" />
                                        <label className="form-check-label" htmlFor="terms-conditions">
                                            I agree to
                                            <Link to="#">privacy policy & terms</Link>
                                        </label>
                                    </div>
                                </div>
                                <Link to='/login'>
                                    <button className="btn btn-lg btn-primary d-grid w-100">Sign up</button>
                                </Link>
                            </form>

                            <p className="text-center">
                                <span>Already have an account?</span>
                                <Link to='/login'>
                                    <span>Sign in instead</span>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}