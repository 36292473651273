import * as yup from 'yup';
import axios from "axios";
import Swal from "sweetalert2";
import { useFormik } from 'formik';
import Loader from "../Loader/Loader";
import Header from "../Header/Header";
import { useEffect, useState } from "react";
import { Layout } from "../Layout/Layout";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object({
    // interestValued: yup
    //   .string('Select interest valued')
    //   .oneOf(['Freehold', 'Leasehold', 'part Freehold/ part Leasehold'], 'Invalid interest value')
    //   .required('Interest Valued is required'),
    // facilityGrade: yup
    //   .string('Select facility grade')
    //   .required('Facility Grade is required'),
    matureCapitalisationRate: yup
        .string('Enter a valid mature capitalisation rate')
        //   .typeError('Mature Capitalisation Rate must be a number')
        //   .min(0, 'Mature Capitalisation Rate must be greater than or equal to 0')
        //   .max(100, 'Mature Capitalisation Rate must be less than or equal to 100')
        .required('Mature Capitalisation Rate is required'),
    portfolioPremium: yup
        .string('Enter a valid portfolio premium')
        //   .typeError('Portfolio Premium must be a number')
        //   .min(0, 'Portfolio Premium must be greater than or equal to 0')
        //   .max(100, 'Portfolio Premium must be less than or equal to 100')
        .required('Portfolio Premium is required'),
    pvStorageDiscountRate: yup
        .string('Enter a valid discount rate')
        //   .typeError('Discount Rate must be a number')
        //   .min(0, 'Discount Rate must be greater than or equal to 0')
        //   .max(100, 'Discount Rate must be less than or equal to 100')
        .required('Discount Rate is required'),
    facilityManagementFeeStandAloneRate: yup
        .string('Enter a valid facility management fee stand-alone rate')
        //   .typeError('Facility Management Fee - Stand-Alone Rate must be a number')
        //   .min(0, 'Facility Management Fee - Stand-Alone Rate must be greater than or equal to 0')
        //   .max(100, 'Facility Management Fee - Stand-Alone Rate must be less than or equal to 100')
        .required('Facility Management Fee - Stand-Alone Rate is required'),
    facilityManagementFeePortfolioRate: yup
        .string('Enter a valid facility management fee portfolio rate')
        //   .typeError('Facility Management Fee Portfolio Rate must be a number')
        //   .min(0, 'Facility Management Fee Portfolio Rate must be greater than or equal to 0')
        //   .max(100, 'Facility Management Fee Portfolio Rate must be less than or equal to 100')
        .required('Facility Management Fee Portfolio Rate is required'),
    additionalTenanciesManagementFee: yup
        .string('Enter a valid Tenancies management fee')
        //   .typeError('Tenancies management fee must be a number')
        //   .min(0, 'Tenancies management fee must be greater than or equal to 0')
        //   .max(100, 'Tenancies management fee must be less than or equal to 100')
        .required('Tenancies management fee is required'),
    averageGrowthRateForExpenses: yup
        .string('Enter a valid average growth rate for expenses')
        //   .typeError('Average Growth Rate for Expenses must be a number')
        //   .min(0, 'Average Growth Rate for Expenses must be greater than or equal to 0')
        //   .max(100, 'Average Growth Rate for Expenses must be less than or equal to 100')
        .required('Average Growth Rate for Expenses is required'),
    adoptedValuation: yup
        .string('Enter the valuation adopted')
        .required('Valuation Adopted is required'),
    // assessmentAdopted: yup
    //   .string('Enter the assessment adopted')
    //   .required('Assessment Adopted is required'),
    adoptedAssessmentType: yup
        .string('Select Assessment')
        .oneOf(['Live', 'High', 'Med', 'Low', 'ProjectedMed', 'HigherOccupancy', 'HigherFeeRate'], 'Invalid assessment value')
        .required('Assessment Adopted if at Maturity is required'),
});

export default function AdminInput() {
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const navigate = useNavigate();
    const [currentFacility, setCurrentFacility] = useState({});
    const [totalFacilities, setTotalFacilities] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedPortfolio, setSelectedPortfolio] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);

    const IstheAdoptedRevenuewithin5PercentofPassing = currentFacility ? currentFacility.IstheAdoptedRevenuewithin5PercentofPassing : ''//( facility_dashboard_j29 > 95.0001%) ? "Check" : "Ok";
    const DoesTheValuationincludeanExpansion = currentFacility ? currentFacility.DoesTheValuationincludeanExpansion : ''
    const AdditionalNonStorageIncomeAtMarket = currentFacility ? currentFacility.AdditionalNonStorageIncomeAtMarket : ''//(in Non storage input sheet if c36 != c35) ? "Check" : "Ok";

    const TotalOperatingExpensesWithinRange = currentFacility ? currentFacility.TotalOperatingExpensesWithinRange : '' //in operating expenses inputs if p34 > 0 then Check else Ok
    const ManagementFeeWithinRange = currentFacility ? currentFacility.ManagementFeeWithinRange : ''
    const TotalExpensesWithinRange = currentFacility ? currentFacility.TotalExpensesWithinRange : ''

    const PositiveRevenueGrowth = currentFacility ? currentFacility.PositiveRevenueGrowth : ''//in past 12 months Api if c15 < r15 then Check else Ok
    const OtherIncomeWithinRange = currentFacility ? currentFacility.OtherIncomeWithinRange : '' // in past 12 months Api if O30<3% then Check else Ok
    const OperatingExpenseLineItemsConform = currentFacility ? currentFacility.OperatingExpenseLineItemsConform : '';// in operating expenses inputs if o32 > 0 then Check else Ok
    const ProfitabilityMarginwithinRange = currentFacility ? currentFacility.ProfitabilityMarginwithinRange : '' // in operating expenses inputs if L41==False OR N41 === false then Check else Ok

    const RecommendOptimisationReview = currentFacility ? currentFacility.RecommendOptimisationReview : ''
    const RecommendValuationIsVerified = currentFacility ? currentFacility.RecommendValuationIsVerified : ''

    let portfolioPremium = 0;

    const portfolioId = localStorage.getItem('selectedPortfolio') ? parseInt(localStorage.getItem('selectedPortfolio')) : null;
    const facilityId = localStorage.getItem('selectedFacility') ? parseInt(localStorage.getItem('selectedFacility')) : null;

    useEffect(() => {
        if(user.role !== 'SuperAdmin'){
            navigate(-1)
        }
        if (portfolioId) {
            setSelectedPortfolio(portfolioId);
        }

        if (facilityId) {
            formik.setFieldValue('id', facilityId);
            setSelectedFacility(facilityId);
            getFacilityDetails(facilityId)
        }
    }, [portfolioId, facilityId]);

    const initialValues = {
        id: '',
        interestValued: '',
        facilityGrade: '',
        matureCapitalisationRate: '',
        portfolioPremium: 0,
        pvStorageDiscountRate: '',
        facilityManagementFeeStandAloneRate: '',
        facilityManagementFeePortfolioRate: '',
        averageGrowthRateForExpenses: '',
        additionalTenanciesManagementFee: '',
        adoptedValuation: '',
        assessmentAdopted: '',
        adoptedAssessmentType: '',
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
        }
    });

    const removeFormatting = (value) => {
        return value.toString().replace(/[^0-9.-]+/g, '');
    };

    const NumberFormatter = new Intl.NumberFormat('en-AU', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatValue = (value) => {
        return isNaN(value) ? '' : NumberFormatter.format(value);
    };

    const cleanValues = async (values) => {
        const fieldsToFormat = [
            'matureCapitalisationRate',
            'portfolioPremium',
            'pvStorageDiscountRate',
            'facilityManagementFeeStandAloneRate',
            'facilityManagementFeePortfolioRate',
            'averageGrowthRateForExpenses',
            'additionalTenanciesManagementFee',
        ];

        return Object.keys(values).reduce((acc, key) => {
            const value = values[key];
            if (fieldsToFormat.includes(key) && typeof value === 'string' && value) {
                acc[key] = removeFormatting(value);
            } else {
                acc[key] = value;
            }

            return acc;
        }, {});
    }

    const calculateFacilityValuation = async (facilityId) => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/calculateFacilityValuation?facilityId=${facilityId}`);
            setLoading(false)
            let result = response.data;
            if (result.success) {
                console.log("response.data.data", response.data);
            } else {
                Swal.fire("Facility Details Missing!", result.message, "info");
            }
        } catch (err) {
            setLoading(false)
            Swal.fire("Error", err.message, "error");
        }
    };

    // const handleSubmit = async (values) => {
    //     setLoading(true)
    //     values = await cleanValues(values);
    //     try {
    //       const response = await axios.put(`/api/facility/update`, values);
    //       setLoading(false)
    //       if (response.data.success) {
    //         Swal.fire(response.data.message, '', 'success').then(() => {
    //             getFacilityDetails(formik.values.id);
    //         });
    //       } else {
    //         Swal.fire(response.data.message, '', 'error');
    //       }
    //     } catch (error) {
    //         setLoading(false)
    //         Swal.fire(error.response.data.message, '', 'error');
    //     }
    // };

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const handleSubmit = async (values) => {
        setLoading(true);
        values = await cleanValues(values);

        try {
            const response = await axios.put(`/api/facility/update`, values);

            if (response.data.success) {
                setLoading(false);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message || 'Update Successful!'
                });
                calculateFacilityValuation(formik.values.id);
                getFacilityDetails(formik.values.id);
                
                // Swal.fire(response.data.message, '', 'success').then(() => {
                //     calculateFacilityValuation(formik.values.id);
                //     getFacilityDetails(formik.values.id);
                // });
                // Swal.fire({
                //     title: response.data.message,
                //     text: "Do you want to recalculate this facility?",
                //     icon: 'success',
                //     showCancelButton: true,
                //     confirmButtonText: 'Yes, recalculate!',
                //     cancelButtonText: 'No, thanks',
                //     allowOutsideClick: false,
                //     showLoaderOnConfirm: true
                // }).then((result) => {
                //     if (result.isConfirmed) {
                //         Swal.fire({
                //             title: 'Recalculating...',
                //             allowOutsideClick: false,
                //         });

                //         calculateFacilityValuation(formik.values.id)
                //             .then(() => {
                //                 Swal.fire('Recalculation complete!', '', 'success');
                //                 getFacilityDetails(formik.values.id); 
                //             })
                //             .catch((error) => {
                //                 Swal.fire('Recalculation failed', '', 'error');
                //             });
                //     } else {
                //         getFacilityDetails(formik.values.id); 
                //         setLoading(false);
                //     }
                // });
            } else {
                Toast.fire({
                    icon: 'error',
                    title: response.data.message || 'Update Failed!'
                });
                // Swal.fire(response.data.message, '', 'error');
                setLoading(false);
            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: error.response?.data?.message || error.message
            });
            // Swal.fire(error.response?.data?.message || error.message, '', 'error');
            setLoading(false);
        }
    };

    const getFacilityDetails = (facilityId) => {
        setLoading(true)
        if (!facilityId) {
            setLoading(false);
            return;
        }
        axios.get(`api/facility?id=${facilityId}`).then(res => {
            setLoading(false)
            if (res.data.success) {
                const result = res.data.data;

                const updatedJsonObject = replaceNullsAndFormatCurrency(result);

                updatedJsonObject.mostRecentFY = updatedJsonObject.mostRecentFY === '' ? new Date().getFullYear() - 1 : updatedJsonObject.mostRecentFY;
                updatedJsonObject.financialYear = updatedJsonObject.country === 'AU' ? '1st July - 30th June' : '1st April - 31st March';
                // updatedJsonObject.assessmentAdopted =  updatedJsonObject.adoptedValuation === 'Static' ? 'Live' : 'Projected Med'
                updatedJsonObject.adoptedAssessmentType = updatedJsonObject.adoptedValuation === 'Static' && ['', 'ProjectedMed', 'HigherOccupancy', 'HigherFeeRate'].includes(updatedJsonObject.adoptedAssessmentType) ? 'Live' : updatedJsonObject.adoptedValuation === 'BuildUp' && ['', 'Live', 'High', 'Med', 'Low'].includes(updatedJsonObject.adoptedAssessmentType) ? 'ProjectedMed' : updatedJsonObject.adoptedAssessmentType
                // formik.setValues(updatedJsonObject);
                formik.setFieldValue("interestValued", res.data.data.interestValued);
                formik.setFieldValue("facilityGrade", res.data.data.facilityGrade);
                formik.setFieldValue("matureCapitalisationRate", res.data.data.matureCapitalisationRate);
                formik.setFieldValue("portfolioPremium", res.data.data.portfolioPremium);
                formik.setFieldValue("pvStorageDiscountRate", res.data.data.pvStorageDiscountRate);
                formik.setFieldValue("facilityManagementFeeStandAloneRate", res.data.data.facilityManagementFeeStandAloneRate);
                formik.setFieldValue("facilityManagementFeePortfolioRate", res.data.data.facilityManagementFeePortfolioRate);
                formik.setFieldValue("averageGrowthRateForExpenses", res.data.data.averageGrowthRateForExpenses);
                formik.setFieldValue("additionalTenanciesManagementFee", res.data.data.additionalTenanciesManagementFee);
                formik.setFieldValue("adoptedValuation", res.data.data.adoptedValuation);
                formik.setFieldValue("adoptedAssessmentType", res.data.data.adoptedAssessmentType);

                setCurrentFacility(updatedJsonObject)
                setTotalFacilities(updatedJsonObject.totalFacilitiesInPortfolio)

                // portfolioPremium = updatedJsonObject.totalFacilitiesInPortfolio > 7 ? 0.005 : (updatedJsonObject.totalFacilitiesInPortfolio >= 3 ? 0.0025 : 0);
                portfolioPremium = updatedJsonObject.totalFacilitiesInPortfolio > 7 ? 0.5 : (updatedJsonObject.totalFacilitiesInPortfolio >= 3 ? 0.25 : 0);
                formik.setFieldValue("portfolioPremium", portfolioPremium);
            }
        })
    }

    function replaceNullsAndFormatCurrency(obj) {
        for (let key in obj) {
            if (obj[key] === null) {
                obj[key] = '';
            } else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                replaceNullsAndFormatCurrency(obj[key]);
            } else if (!isNaN(obj[key]) && typeof obj[key] === 'float') {
                obj[key] = `$${obj[key].toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
            }
        }
        return obj;
    }

    const handleAssessmentAdoptedChange = (value) => {
        formik.setFieldValue("adoptedAssessmentType", value)
        formik.setFieldValue("assessmentAdopted", value)
    }

    return (
        <>
            <Loader loading={loading} />
            <Layout>
                <Header
                    pageTitle={localStorage.getItem('selectedFacilityName')}
                    verificationStatus={localStorage.getItem('selectedFacilityVerificationStatus')}
                />
                {/* <FilterHeader 
                selectedPortfolio={portfolioId}
                setSelectedPortfolio={setSelectedPortfolio}
                selectedFacility={facilityId}
                setSelectedFacility={setSelectedFacility}
                getPageData={getFacilityDetails}
                setPageData={setCurrentFacility}
                formik={formik}
            /> */}
                <div className="container-xxl flex-grow-1 container-p-y">
                    <div className="">
                        {formik.values.id ? (
                            <div>
                                {/* <NavHeader /> */}
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label className="form-label w-50" htmlFor="portfolioName">Portfolio Name</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="portfolioName" placeholder="Portfolio Name" aria-label="Portfolio" disabled value={currentFacility && currentFacility.portfolio ? currentFacility.portfolio.name : ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label className="form-label w-50" htmlFor="defaultCountryLocation">Default Country Location</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="defaultCountryLocation" placeholder="Country" aria-label="Country Location" disabled value={currentFacility?.country || ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label className="form-label w-50" htmlFor="facilityName">Facility Name</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="facilityName" placeholder="Facility Name" aria-label="Facility Name" disabled value={currentFacility?.name || ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label className="form-label w-50" htmlFor="facilityAddress">Facility Address</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="facilityAddress" placeholder="Facility Address" aria-label="Facility Address" disabled value={currentFacility?.address || ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label htmlFor="state" className="form-label w-50">State</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="state" placeholder="VIC" aria-label="State" disabled value={currentFacility?.state || ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label className="form-label w-50" htmlFor="postCode">Post Code</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="postCode" placeholder="Post Code" aria-label="Post Code" disabled value={currentFacility?.postCode || ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label htmlFor="country" className="form-label w-50">Country</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="country" placeholder="Country" aria-label="Country" disabled value={currentFacility?.country || ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label htmlFor="currency" className="form-label w-50">Currency</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="currency" placeholder="Currency" aria-label="Currency" disabled value={currentFacility?.currency || ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label htmlFor="numberOfFacilities" className="form-label w-50">No. Of Facilities</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="numberOfFacilities" placeholder="Facilities" aria-label="No. Of Facilities" disabled value={totalFacilities} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label htmlFor="isFacilityTradingAtMaturity" className="form-label w-50">Is the Facility Trading at Maturity?</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="isFacilityTradingAtMaturity" placeholder="No" aria-label="Is the Facility Trading at Maturity?" name="isFacilityTradingAtMaturity" disabled value={currentFacility?.isFacilityTradingAtMaturity || ''} />
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label htmlFor="adoptedValuation" className="form-label w-50">Valuation Adopted</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="adoptedValuation" placeholder="Build Up" aria-label="Valuation Adopted" name="adoptedValuation" value={formik.values.adoptedValuation ? formik.values.adoptedValuation : ''} onChange={formik.handleChange} onBlur={formik.handleBlur} disabled />
                                                    {formik.touched.adoptedValuation && formik.errors.adoptedValuation && (
                                                        <div className="text-danger">{formik.errors.adoptedValuation}</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                                    <label htmlFor="assessmentAdopted" className="form-label w-50">Assessment Adopted</label>
                                                    <input type="text" className="form-control form-control-sm w-50" id="assessmentAdopted" placeholder="Projected Med" aria-label="Assessment Adopted" name="assessmentAdopted" value={formik.values.adoptedAssessmentType} onChange={formik.handleChange} onBlur={formik.handleBlur} disabled />
                                                    {/* {formik.touched.adoptedAssessmentType && formik.errors.adoptedAssessmentType && (
                                                    <div className="text-danger">{formik.errors.adoptedAssessmentType}</div>
                                                )} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                    <form id="formAdminInput" method="POST" onSubmit={formik.handleSubmit}>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='d-flex mb-3'>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="adoptedAssessmentType" className="form-label">Assessment Adopted</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <select id="adoptedAssessmentType" className="select2 form-select form-control form-control-sm" name="adoptedAssessmentType"
                                                                value={formik.values.adoptedAssessmentType} onChange={(e) => handleAssessmentAdoptedChange(e.target.value)} onBlur={formik.handleBlur} disabled={currentFacility?.adoptedValuation === 'Build up'}>
                                                                <option value="">Select</option>
                                                                <option value="Live" className={currentFacility?.adoptedValuation === 'BuildUp' ? "cursor-not-allowed" : ""} disabled={currentFacility?.adoptedValuation === 'BuildUp'}>Live</option>
                                                                <option value="High" className={currentFacility?.adoptedValuation === 'BuildUp' ? "cursor-not-allowed" : ""} disabled={currentFacility?.adoptedValuation === 'BuildUp'}>High</option>
                                                                <option value="Med" className={currentFacility?.adoptedValuation === 'BuildUp' ? "cursor-not-allowed" : ""} disabled={currentFacility?.adoptedValuation === 'BuildUp'}>Med</option>
                                                                <option value="Low" className={currentFacility?.adoptedValuation === 'BuildUp' ? "cursor-not-allowed" : ""} disabled={currentFacility?.adoptedValuation === 'BuildUp'}>Low</option>
                                                                <option value="ProjectedMed" className={currentFacility?.adoptedValuation === 'Static' ? "cursor-not-allowed" : ""} disabled={currentFacility?.adoptedValuation === 'Static'}>Projected Med</option>
                                                                <option value="HigherOccupancy" className={currentFacility?.adoptedValuation === 'Static' ? "cursor-not-allowed" : ""} disabled={currentFacility?.adoptedValuation === 'Static'}>Higher Occupancy</option>
                                                                <option value="HigherFeeRate" className={currentFacility?.adoptedValuation === 'Static' ? "cursor-not-allowed" : ""} disabled={currentFacility?.adoptedValuation === 'Static'}>Higher Fee Rate</option>
                                                            </select>
                                                            {formik.touched.adoptedAssessmentType && formik.errors.adoptedAssessmentType && (
                                                                <div className="text-danger">{formik.errors.adoptedAssessmentType}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="my-0" />
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                                        <label className="form-label w-50" htmlFor="interestValued">Interest Valued</label>
                                                        <select id="interestValued" className="select2 form-select form-control form-control-sm w-50" name="interestValued" value={formik.values.interestValued} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                            <option value="">Select</option>
                                                            <option value="Freehold">Freehold</option>
                                                            <option value="Leasehold">Leasehold</option>
                                                            <option value="PartFreehold/PartLeasehold">part Freehold/ part Leasehold</option>
                                                        </select>
                                                        {formik.touched.interestValued && formik.errors.interestValued && (
                                                            <div className="text-danger">{formik.errors.interestValued}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                                        <label className="form-label w-50" htmlFor="facilityGrade">Facility Grade</label>
                                                        <input type="text" className="form-control form-control-sm w-50" id="facilityGrade" placeholder="A" aria-label="Facility Grade" name="facilityGrade" value={formik.values.facilityGrade} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                        {formik.touched.facilityGrade && formik.errors.facilityGrade && (
                                                            <div className="text-danger">{formik.errors.facilityGrade}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='d-flex mb-3'>
                                                        <div className='col-md-6'>
                                                            <label className="form-label" htmlFor="matureCapitalisationRate">Mature Capitalisation Rate </label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="matureCapitalisationRate"
                                                                placeholder="0.00%"
                                                                aria-label="Mature Capitalisation Rate"
                                                                name="matureCapitalisationRate"
                                                                value={
                                                                    formik.values.matureCapitalisationRate && !formik.values.matureCapitalisationRate.endsWith('%')
                                                                        ? formik.values.matureCapitalisationRate + '%'
                                                                        : formik.values.matureCapitalisationRate
                                                                }
                                                                onChange={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim();
                                                                    formik.setFieldValue('matureCapitalisationRate', rawValue);
                                                                }}
                                                                onBlur={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim();
                                                                    formik.setFieldValue(
                                                                        'matureCapitalisationRate',
                                                                        rawValue ? `${rawValue}%` : ''
                                                                    );
                                                                }}
                                                                onFocus={(e) => {
                                                                    let rawValue = formik.values.matureCapitalisationRate.replace('%', '').trim();
                                                                    formik.setFieldValue('matureCapitalisationRate', rawValue);
                                                                }}
                                                            />
                                                            {formik.touched.matureCapitalisationRate && formik.errors.matureCapitalisationRate && (
                                                                <div className="text-danger">{formik.errors.matureCapitalisationRate}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                                        <label className="form-label w-50" htmlFor="portfolioPremium">Portfolio Premium</label>
                                                        <input
                                                            type="text"
                                                            className="form-control form-control-sm w-50"
                                                            id="portfolioPremium"
                                                            placeholder="0.00%"
                                                            aria-label="Portfolio Premium"
                                                            name="portfolioPremium"
                                                            value={
                                                                formik.values.portfolioPremium
                                                                    ? formik.values.portfolioPremium + '%'
                                                                    : formik.values.portfolioPremium
                                                            }
                                                            onChange={(e) => {
                                                                let rawValue = e.target.value.replace('%', '').trim();
                                                                formik.setFieldValue('portfolioPremium', rawValue);
                                                            }}
                                                            onBlur={(e) => {
                                                                let rawValue = e.target.value.replace('%', '').trim();
                                                                formik.setFieldValue(
                                                                    'portfolioPremium',
                                                                    rawValue ? `${rawValue}%` : ''
                                                                );
                                                            }}
                                                            onFocus={(e) => {
                                                                let rawValue = formik.values.portfolioPremium.replace('%', '').trim();
                                                                formik.setFieldValue('portfolioPremium', rawValue);
                                                            }}
                                                            // value={formik.values.portfolioPremium} 
                                                            // onChange={formik.handleChange} 
                                                            // onBlur={formik.handleBlur} 
                                                            disabled
                                                            title="IFS(totalNoOfFacilities>7,0.5%,totalNoOfFacilities=3,0.25%,totalNoOfFacilities=4,0.25%,totalNoOfFacilities=5,0.25%,totalNoOfFacilities=6,0.25%,totalNoOfFacilities=7,0.25%,totalNoOfFacilities<=2,0%)"
                                                        />
                                                        {formik.touched.portfolioPremium && formik.errors.portfolioPremium && (
                                                            <div className="text-danger">{formik.errors.portfolioPremium}</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='d-flex mb-3'>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="facilityManagementFeePortfolioRate" className="form-label">Facility Management Fee Portfolio Rate</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="facilityManagementFeePortfolioRate"
                                                                placeholder="0.00%"
                                                                aria-label="Facility Management Fee - Stand-Alone Rate"
                                                                name="facilityManagementFeePortfolioRate"
                                                                // value={formik.values.facilityManagementFeePortfolioRate}
                                                                // onChange={formik.handleChange}
                                                                // onBlur={formik.handleBlur}
                                                                value={
                                                                    formik.values.facilityManagementFeePortfolioRate && !formik.values.facilityManagementFeePortfolioRate.endsWith('%')
                                                                        ? formik.values.facilityManagementFeePortfolioRate + '%'
                                                                        : formik.values.facilityManagementFeePortfolioRate
                                                                }
                                                                onChange={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim();
                                                                    formik.setFieldValue('facilityManagementFeePortfolioRate', rawValue);
                                                                }}
                                                                onBlur={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim();
                                                                    formik.setFieldValue(
                                                                        'facilityManagementFeePortfolioRate',
                                                                        rawValue ? `${rawValue}%` : ''
                                                                    );
                                                                }}
                                                                onFocus={(e) => {
                                                                    let rawValue = formik.values.facilityManagementFeePortfolioRate.replace('%', '').trim();
                                                                    formik.setFieldValue('facilityManagementFeePortfolioRate', rawValue);
                                                                }}
                                                            />
                                                            {formik.touched.facilityManagementFeePortfolioRate && formik.errors.facilityManagementFeePortfolioRate && (
                                                                <div className="text-danger">{formik.errors.facilityManagementFeePortfolioRate}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='d-flex mb-3'>
                                                        <div className='col-md-6'>
                                                            <label className="form-label" htmlFor="facilityManagementFeeStandAloneRate">Facility Management Fee - Stand-Alone Rate</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="facilityManagementFeeStandAloneRate"
                                                                placeholder="0.00%"
                                                                aria-label="Facility Management Fee - Stand-Alone Rate"
                                                                name="facilityManagementFeeStandAloneRate"
                                                                // value={formik.values.facilityManagementFeeStandAloneRate}
                                                                // onChange={formik.handleChange}
                                                                // onBlur={formik.handleBlur}
                                                                value={
                                                                    formik.values.facilityManagementFeeStandAloneRate && !formik.values.facilityManagementFeeStandAloneRate.endsWith('%')
                                                                        ? formik.values.facilityManagementFeeStandAloneRate + '%'
                                                                        : formik.values.facilityManagementFeeStandAloneRate
                                                                }
                                                                onChange={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim();
                                                                    formik.setFieldValue('facilityManagementFeeStandAloneRate', rawValue);
                                                                }}
                                                                onBlur={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim();
                                                                    formik.setFieldValue(
                                                                        'facilityManagementFeeStandAloneRate',
                                                                        rawValue ? `${rawValue}%` : ''
                                                                    );
                                                                }}
                                                                onFocus={(e) => {
                                                                    let rawValue = formik.values.facilityManagementFeeStandAloneRate.replace('%', '').trim();
                                                                    formik.setFieldValue('facilityManagementFeeStandAloneRate', rawValue);
                                                                }}
                                                            />
                                                            {formik.touched.facilityManagementFeeStandAloneRate && formik.errors.facilityManagementFeeStandAloneRate && (
                                                                <div className="text-danger">{formik.errors.facilityManagementFeeStandAloneRate}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='d-flex mb-3'>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="pvStorageDiscountRate" className="form-label">Discount Rate for PV Storage Rental Shortfall </label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="pvStorageDiscountRate"
                                                                placeholder="0.00%"
                                                                aria-label="Discount Rate for PV Storage Rental Shortfall"
                                                                name="pvStorageDiscountRate"
                                                                // value={formik.values.pvStorageDiscountRate}
                                                                // onChange={formik.handleChange}
                                                                // onBlur={formik.handleBlur}
                                                                value={
                                                                    formik.values.pvStorageDiscountRate && !formik.values.pvStorageDiscountRate.endsWith('%')
                                                                        ? formik.values.pvStorageDiscountRate + '%'
                                                                        : formik.values.pvStorageDiscountRate
                                                                }
                                                                onChange={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim();
                                                                    formik.setFieldValue('pvStorageDiscountRate', rawValue);
                                                                }}
                                                                onBlur={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim();
                                                                    formik.setFieldValue(
                                                                        'pvStorageDiscountRate',
                                                                        rawValue ? `${rawValue}%` : ''
                                                                    );
                                                                }}
                                                                onFocus={(e) => {
                                                                    let rawValue = formik.values.pvStorageDiscountRate.replace('%', '').trim();
                                                                    formik.setFieldValue('pvStorageDiscountRate', rawValue);
                                                                }}
                                                            />
                                                            {formik.touched.pvStorageDiscountRate && formik.errors.pvStorageDiscountRate && (
                                                                <div className="text-danger">{formik.errors.pvStorageDiscountRate}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='d-flex mb-3'>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="additionalTenanciesManagementFee" className="form-label">Additional Tenancies Management Fee </label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="additionalTenanciesManagementFee"
                                                                placeholder="0.00%"
                                                                aria-label="Additional Tenancies Management Fee"
                                                                name="additionalTenanciesManagementFee"
                                                                // value={formik.values.additionalTenanciesManagementFee}
                                                                // onChange={formik.handleChange}
                                                                // onBlur={formik.handleBlur}
                                                                value={
                                                                    formik.values.additionalTenanciesManagementFee && !formik.values.additionalTenanciesManagementFee.endsWith('%')
                                                                        ? formik.values.additionalTenanciesManagementFee + '%'
                                                                        : formik.values.additionalTenanciesManagementFee
                                                                }
                                                                onChange={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim();
                                                                    formik.setFieldValue('additionalTenanciesManagementFee', rawValue);
                                                                }}
                                                                onBlur={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim();
                                                                    formik.setFieldValue(
                                                                        'additionalTenanciesManagementFee',
                                                                        rawValue ? `${rawValue}%` : ''
                                                                    );
                                                                }}
                                                                onFocus={(e) => {
                                                                    let rawValue = formik.values.additionalTenanciesManagementFee.replace('%', '').trim();
                                                                    formik.setFieldValue('additionalTenanciesManagementFee', rawValue);
                                                                }}
                                                            />
                                                            {formik.touched.additionalTenanciesManagementFee && formik.errors.additionalTenanciesManagementFee && (
                                                                <div className="text-danger">{formik.errors.additionalTenanciesManagementFee}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1">
                                                    <div className='d-flex mb-3'>
                                                        <div className='col-md-6'>
                                                            <label htmlFor="averageGrowthRateForExpenses" className="form-label w-50">Average Growth Rate for Expenses</label>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <input
                                                                type="text"
                                                                className="form-control form-control-sm"
                                                                id="averageGrowthRateForExpenses"
                                                                placeholder="0.00%"
                                                                aria-label="Average Growth Rate for Expenses"
                                                                name="averageGrowthRateForExpenses"
                                                                // value={formik.values.averageGrowthRateForExpenses}
                                                                // onChange={formik.handleChange}
                                                                // onBlur={formik.handleBlur}
                                                                value={
                                                                    formik.values.averageGrowthRateForExpenses && !formik.values.averageGrowthRateForExpenses.endsWith('%')
                                                                        ? formik.values.averageGrowthRateForExpenses + '%'
                                                                        : formik.values.averageGrowthRateForExpenses
                                                                }
                                                                onChange={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim();
                                                                    formik.setFieldValue('averageGrowthRateForExpenses', rawValue);
                                                                }}
                                                                onBlur={(e) => {
                                                                    let rawValue = e.target.value.replace('%', '').trim();
                                                                    formik.setFieldValue(
                                                                        'averageGrowthRateForExpenses',
                                                                        rawValue ? `${rawValue}%` : ''
                                                                    );
                                                                }}
                                                                onFocus={(e) => {
                                                                    let rawValue = formik.values.averageGrowthRateForExpenses.replace('%', '').trim();
                                                                    formik.setFieldValue('averageGrowthRateForExpenses', rawValue);
                                                                }}
                                                            />
                                                            {formik.touched.averageGrowthRateForExpenses && formik.errors.averageGrowthRateForExpenses && (
                                                                <div className="text-danger">{formik.errors.averageGrowthRateForExpenses}</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2 d-flex justify-content-end">
                                                <button type="submit" className="btn btn-primary me-2">Save Changes</button>
                                                {/*<button type="reset" className="btn btn-outline-secondary">Cancel</button>*/}
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-header d-flex justify-content-between align-items-center border-bottom-0">
                                    <h5 className="mb-0">Checks for Valuer Review (Recommend Valuation Verification if False appears)</h5>
                                </div>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                <div className="facilityTable table-responsive">
                                                    <table className="table table-bordered mb-0">
                                                        <tbody>
                                                            <tr>
                                                                <td className="">Recommend Valuation is Verified</td>
                                                                <td className="bg-white"><span className={RecommendValuationIsVerified === 'Yes' ? "badge bg-danger" : "badge bg-success"}>{RecommendValuationIsVerified}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="bg-table">Is the Adopted Revenue within 5% of Passing</td>
                                                                <td className="bg-table"><span className={IstheAdoptedRevenuewithin5PercentofPassing === 'Check' ? "badge bg-danger" : "badge bg-success"}>{IstheAdoptedRevenuewithin5PercentofPassing}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="">Does the Valuation include an Expansion</td>
                                                                <td className="bg-white"><span className={DoesTheValuationincludeanExpansion === 'Check' ? "badge bg-danger" : "badge bg-success"}>{DoesTheValuationincludeanExpansion}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="bg-table">Additional Non-Storage Income at Market </td>
                                                                <td className="bg-table"><span className={AdditionalNonStorageIncomeAtMarket === 'Check' ? "badge bg-danger" : "badge bg-success"}>{AdditionalNonStorageIncomeAtMarket}</span></td>
                                                            </tr>
                                                            <tr height="20px;">
                                                                <td className=""></td>
                                                                <td className="bg-white"></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="bg-table">Total Operating Expenses within Range </td>
                                                                <td className="bg-table"><span className={TotalOperatingExpensesWithinRange === 'Check' ? "badge bg-danger" : "badge bg-success"}>{TotalOperatingExpensesWithinRange}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="">Management Fee within Range </td>
                                                                <td className="bg-white"><span className={ManagementFeeWithinRange === 'Check' ? "badge bg-danger" : "badge bg-success"}>{ManagementFeeWithinRange}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="bg-table">Total Expenses within Range </td>
                                                                <td className="bg-table"><span className={TotalExpensesWithinRange === 'Check' ? "badge bg-danger" : "badge bg-success"}>{TotalExpensesWithinRange}</span></td>
                                                            </tr>
                                                            <tr height="20px;" >
                                                                <td className="bg-white"></td>
                                                                <td className="bg-white"></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="bg-table">Recommend Optimisation Review </td>
                                                                <td className="bg-table"><span className={RecommendOptimisationReview === 'Yes' ? "badge bg-danger" : "badge bg-success"}>{RecommendOptimisationReview}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="">Positive Revenue Growth </td>
                                                                <td className="bg-white"><span className={PositiveRevenueGrowth === 'Check' ? "badge bg-danger" : "badge bg-success"}>{PositiveRevenueGrowth}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="bg-table">Other Income' within Range </td>
                                                                <td className="bg-table"><span className={OtherIncomeWithinRange === 'Check' ? "badge bg-danger" : "badge bg-success"}>{OtherIncomeWithinRange}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="">Operating Expense Line Items Conform </td>
                                                                <td className="bg-white"><span className={OperatingExpenseLineItemsConform === 'Check' ? "badge bg-danger" : "badge bg-success"}>{OperatingExpenseLineItemsConform}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td className="bg-table">Profitability Margin within Range </td>
                                                                <td className="bg-table"><span className={ProfitabilityMarginwithinRange === 'Check' ? "badge bg-danger" : "badge bg-success"}>{ProfitabilityMarginwithinRange}</span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 border-bottom-mb mb-Rg-1 text-center">
                                            <div className="mb-3 mt-3">
                                                <h4 style={{ color: "#697a8d" }}>Please Select a {selectedPortfolio === null ? "Portfolio" : "Facility"}!</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Layout>
        </>
    );
}