
import { Link } from "react-router-dom";
export default function PageNotFound(){
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    return(
        <>
            <div className="py-5">
                <div className="container text-center">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-6">
                            <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                            <h1 className="display-1">404</h1>
                            <h1 className="mb-4">Page Not Found</h1>
                            <p className="mb-4">We’re sorry, the page you have looked for does not exist in our website! Maybe go to our home page or try to use a search?</p>
                            {/* <a className="btn btn-primary py-3 px-5" href="">Go Back To Home</a> */}
                            <Link className="btn btn-primary py-3 px-5" to={ user 
                                    ? user.role === "SuperAdmin" ? "/" 
                                    : `/portfolio-dashboard/${user.portfolio.id}`
                                :"/"
                                }> Go Back To Home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    );
}