import * as yup from 'yup';
import React from 'react';
import axios from "axios";
import Swal from 'sweetalert2'
import PropTypes from "prop-types";
import { useFormik } from 'formik';
import { read, utils } from 'xlsx';
import Modal from 'react-bootstrap/Modal';
import CancelIcon from '@mui/icons-material/Cancel';


const validationSchema = yup.object({
    facility_list: yup.mixed().required('Facility list file is required')
});

export const Add = ({ show, setShow, handleClose, getFacilitiesList, portfolioId, loading, setLoading, ...rest}) => {

    const initialValues = {
        portfolioId: portfolioId,
        facility_list: null,
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {
            handleSubmit(values, resetForm);
          },
    });

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });
    
    const handleSubmit = (values, resetForm)=>{
        setLoading(true);
        const file = values.facility_list;
        
        if(file){
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                const fileName = file.name;
                const fileExtension = fileName.split('.').pop();
                
                if (fileExtension === 'xlsx' || fileExtension === 'xls') {
                    if (sheets.length) {
                        const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                            axios.post(`api/facility/multicreate`, {facilities: rows, portfolioId: portfolioId}).then(res => {
                            setLoading(false);
                            if(res.data.success){
                                setShow(false);
                                Toast.fire({
                                    icon: 'success',
                                    title: res.data.message || 'File Uploaded!'
                                });
                                getFacilitiesList(portfolioId);  
                                // Swal.fire(res.data.message, '', 'success').then(result => {
                                //     getFacilitiesList(portfolioId);  
                                // })  
                                resetForm();              
                            } 
                            else{
                                setLoading(false);
                                Toast.fire({
                                    icon: 'error',
                                    title: res.data.message || 'Upload Failed!'
                                });
                                // Swal.fire(res.data.message, '', 'error')
                            }
                        }).catch((error) => {
                            setLoading(false);
                            Swal.fire(error.response.data.message, '', 'error')
                        });
                    
                    }
                } else {
                    setLoading(false);
                    Swal.fire('Must be Upload excel file', '', 'error')
                }
            }
            reader.readAsArrayBuffer(file);
        }
    
    }
    
    const handleCloseModal = () => {
        // if(portfolioId === 0){
            formik.resetForm(); // Reset formik form when modal is closed
        // }
        handleClose(); // Close the modal
    };
    return (
        <Modal show={show} onHide={handleCloseModal} dialogClassName="">
            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Modal.Header>
                    <h5 className="modal-title fs-14 mb-0">Upload Excel</h5>
                    <CancelIcon style={{cursor: 'pointer'}} onClick={handleCloseModal}/>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="mb-4">
                            <label htmlFor="upload" className="btn btn-outline-primary w-100 mb-2" tabIndex="0">
                                <span className="d-none d-sm-block"><span className="tf-icons bi bi-cloud-upload"></span>&nbsp; Choose File</span>
                                <i className="bx bx-upload d-block d-sm-none"></i>
                                <input 
                                    type="file" 
                                    id="upload" 
                                    hidden="" 
                                    accept={".csv, .xlsx, .xls"} 
                                    name= 'facility_list'
                                    placeholder="Please select the facility list"
                                    className={formik.touched.facility_list && formik.errors.facility_list ?'account-file-input is-invalid form-control':'form-control account-file-input'}
                                    onChange={(e) => {
                                        formik.setFieldValue('facility_list', e.currentTarget.files[0]);
                                    }}
                                    onBlur={formik.handleBlur}
                                />
                            </label>
                            {formik.touched.facility_list && formik.errors.facility_list ? (
                                <div className="text-danger">{formik.errors.facility_list}</div>
                            ) : null }
                            <div className="text-end">
                                <a href="./documents/facilityList.xlsx" download>Download Sample File</a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="reset" className="btn btn-outline-primary" onClick={handleCloseModal}>Close</button>       
                    <button type="submit" className="btn btn-primary">Upload</button>    
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Add;
Add.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};