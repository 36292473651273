import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

export default function AccountLocked() {
    // const { userId } = useParams();
    const { unlockRequestToken } = useParams();
    const [isUnlockRequestAlreadySent, setIsUnlockRequestAlreadySent] = useState(0);
    const [unlockRequestTokenVerified, setUnlockRequestTokenVerified] = useState();
    const [unlockRequestJustSent, setUnlockRequestJustSent] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (unlockRequestToken) {
            getUnlockRequestStatus(unlockRequestToken);
        }
    }, []);

    const getUnlockRequestStatus = async () => {
        try {
            const response = await axios.post(`/api/auth/check-unlock-request-status/${unlockRequestToken}`);
            if (response.data.success) {
                // console.log("response.data.data.IsUnlockRequestSent--------------",response.data.data.IsUnlockRequestSent);
                setIsUnlockRequestAlreadySent(response.data.data.IsUnlockRequestSent);
                setUnlockRequestTokenVerified(response.data.data.unlockRequestToken);
                // Swal.fire(response.data.message, '', 'info');
            } else {
                setIsUnlockRequestAlreadySent(0);
            }
        } catch (error) {
            console.error("Error checking unlock request status:", error);
            Swal.fire("An error occurred while processing your request. Please try again later.", '', 'error');
        }
    };

    const handleSendUnlockRequest = async () => {
        if (isUnlockRequestAlreadySent === '1' || loading) return;
        setLoading(true);
        try {
            const response = await axios.post(`/api/request-unlock/${unlockRequestToken}`);
            if (response.data.success) {
                setUnlockRequestJustSent(true);
                setIsUnlockRequestAlreadySent(1);
                Swal.fire(response.data.message, '', 'success');
            } else {
                Swal.fire(response.data.message, '', 'info');
            }
        } catch (error) {
            console.error("Error sending unlock request:", error);
            Swal.fire("An error occurred while processing your request. Please try again later.", '', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="py-5">
            <div className="container text-center">
                <div className="row justify-content-center align-items-center">
                    {unlockRequestToken === unlockRequestTokenVerified ?
                        <div className="col-lg-6">
                            <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                            <h1 className="mb-4">Account Locked</h1>
                            <p className="mb-4">
                                Your account is locked due to multiple failed login attempts. To request an unlock, click the button below.
                            </p>

                            {unlockRequestJustSent ? (
                                <p className="mt-4">Unlock request sent. Please wait for SuperAdmin to unlock your account.</p>
                            ) : (
                                isUnlockRequestAlreadySent === '0' ? (
                                    <button
                                        onClick={handleSendUnlockRequest}
                                        className="btn btn-primary"
                                        disabled={loading}
                                    >
                                        {loading ? 'Sending...' : 'Send Unlock Request'}
                                    </button>
                                ) : (
                                    <p className="mt-4">An unlock request has already been sent. The SuperAdmin will unlock your account shortly.</p>
                                )
                            )}
                        </div>
                        :
                        <div className="col-lg-6">
                            <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                            <p className="mb-4">This link has expired.</p>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}
