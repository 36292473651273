import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { useState } from "react";
import { useFormik } from "formik";
import Modal from "react-bootstrap/Modal";

export default function ContactUs({
    showContactUsPopup,
    setShowContactUsPopup,
    ...rest
}) {
    const handleClose = () => {
        formik.resetForm();
        setShowContactUsPopup(false)
    };
    const [loading, setLoading] = useState(false);

    const initialValues = {
        name: "",
        email: "",
        message: "",
    };

    const validationSchema = yup.object().shape({
        name: yup.string().required("Name is required"),
        email: yup.string().required("Email is required").email("Enter a valid email").nullable(),
        message: yup.string().required("Message is required").nullable(),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
            formik.resetForm();
        },
    });

    const handleSubmit = (values) => {
        axios.post("/api/contactUs", values)
            .then((res) => {
                setShowContactUsPopup(false);
                if (res.data.success) {
                    Swal.fire(res.data.message, "", "success").then((result) => {
                        
                    });
                } else {
                    Swal.fire(res.data.message, "", "info");
                }
            })
            .catch((error) => {
                setShowContactUsPopup(false);
                Swal.fire(error.message, "", "info");
            });
    };

    const handlePopupClose = () => {
        formik.resetForm();
        handleClose();
    };

    return (
        <Modal show={showContactUsPopup} onHide={handlePopupClose} size="lg" backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Contact us</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-name">
                                    Name
                                </label>
                                <div className="input-group input-group-merge">
                                    <span
                                        id="basic-icon-default-name"
                                        className="input-group-text"
                                    >
                                        <i className="bx bx-user"></i>
                                    </span>
                                    <input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        id="basic-icon-default-name"
                                        placeholder="Enter your name"
                                        aria-label="your name"
                                        aria-describedby="basic-icon-default-fullname2"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className="text-danger">{formik.errors.name}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-email">
                                    Email
                                </label>
                                <div className="input-group input-group-merge">
                                    <span
                                        id="basic-icon-default-email"
                                        className="input-group-text"
                                    >
                                        <i className="bx bx-envelope"></i>
                                    </span>
                                    <input
                                        name="email"
                                        type="text"
                                        className="form-control"
                                        id="basic-icon-default-email"
                                        placeholder="Enter your email"
                                        aria-label="your email"
                                        aria-describedby="basic-icon-default-fullname2"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {formik.touched.email && formik.errors.email && (
                                    <div className="text-danger">{formik.errors.email}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label">Message</label>
                                <div className="input-group input-group-merge">
                                    <textarea
                                        name="message"
                                        className="form-control"
                                        rows="4"
                                        placeholder="Your message to us..."
                                        value={formik.values.message}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-primary">
                        Submit
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
