import React, { useEffect, useRef, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";

const DoughnutChart = ({
  chartId,
  data,
  chartTypeText1,
  chartTypeText2,
  percentValue,
}) => {
  const formatCash = Intl.NumberFormat("en-AU", {
    notation: "compact",
    maximumFractionDigits: 2,
  });

  const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
});

  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    labels: ["Actual $", "Adopted $"],
    datasets: [
      {
        data: [0, 0], //data,
        backgroundColor: ["#6bb94a", "#dddddd"],
      },
    ],
  });

  const [centerText, setCenterText] = useState([]);

  // useEffect(() => {
  //   const actualValue = data[0] || 0; 
  //   const adoptedValue = data[1] || 0;
  //   const percentage = (actualValue / (adoptedValue || 1)) * 100;
  //   setChartData({
  //     labels: ["Actual $", "Adopted $"],
  //     datasets: [
  //       // {
  //       //   data: data,
  //       //   backgroundColor: ["#6bb94a", "#dddddd"],
  //       // },
  //       {
  //         data: [percentage, 100 - percentage],
  //         backgroundColor: ["#6bb94a", "#dddddd"],
  //       },
  //     ],
  //   });

  //   if (chartId === "chart2") {
  //     setCenterText([
  //       { text: chartTypeText2, fontSize: "12", fontWeight: "normal" },
  //       { text: chartTypeText1, fontSize: "12", fontWeight: "normal" },
  //       {
  //         text: data
  //           ? `$${formatCash.format(Math.abs(parseFloat(data[1])))}`
  //           : "$0",
  //         fontSize: "22",
  //         fontWeight: "normal",
  //       },
  //     ]);
  //   } else {
  //     setCenterText([
  //       { text: chartTypeText2, fontSize: "12", fontWeight: "normal" },
  //       { text: chartTypeText1, fontSize: "12", fontWeight: "normal" },
  //       {
  //         text: data
  //           ? `$${formatCash.format(Math.abs(parseFloat(data[1])))}`
  //           : "$0",
  //         fontSize: "22",
  //         fontWeight: "normal",
  //       },
  //       { text: "Actual/Adopted", fontSize: "12", fontWeight: "normal" },
  //       { text: percentValue, fontSize: "16", fontWeight: "normal" },
  //     ]);
  //   }
  // }, [data, chartId, chartTypeText1, chartTypeText2, percentValue]);

  useEffect(() => {
    if (!data || (chartId === "chart2" && data.length < 3) || (chartId !== "chart2" && data.length < 2)) return;

    let percentage = 0;

    if (chartId === "chart2") {
      const comparisonValue = data[2] || 0;
      if (comparisonValue > 50) {
        percentage = 20;
      } else if (comparisonValue > 40) {
        percentage = 40;
      } else if (comparisonValue > 35) {
        percentage = 60;
      } else if (comparisonValue > 25) {
        percentage = 80;
      } else {
        percentage = 95;
      }
    } else {
      const actualValue = data[0] || 0;
      const adoptedValue = data[1] || 0;
      percentage = (actualValue / (adoptedValue || 1)) * 100;
    }

    setChartData({
      labels: ["Actual $", "Adopted $"],
      datasets: [
        {
          data: [percentage, 100 - percentage],
          backgroundColor: ["#6bb94a", "#dddddd"],
        },
      ],
    });

    // Set center text
    const centerText = [
      { text: chartTypeText2 || "", fontSize: "12", fontWeight: "normal" },
      { text: chartTypeText1 || "", fontSize: "12", fontWeight: "normal" },
      {
        text: data[1] !== undefined
          ? `$${formatCash.format(Math.abs(parseFloat(data[1])))}`
          : "$0",
        fontSize: "22",
        fontWeight: "normal",
      },
    ];

    if (chartId !== "chart2") {
      centerText.push(
        { text: "Actual/Adopted", fontSize: "12", fontWeight: "normal" },
        { text: percentValue || "0%", fontSize: "16", fontWeight: "normal" }
      );
    }

    setCenterText(centerText);
  }, [data, chartId, chartTypeText1, chartTypeText2, percentValue]);

  useEffect(() => {
    const plugin = {
      id: `textCenter-${chartId}`,
      beforeDraw: (chart) => {
        if (chart.canvas.id !== chartId) return;

        const { width, height, ctx } = chart;
        ctx.restore();
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#6a7377";

        const lines = centerText;
        const lineHeight = height / 10;
        const reducedLineHeight = height / 18;
        let textY = height / 2 - ((lines.length - 2.8) / 2) * lineHeight;

        // Draw center text
        lines.forEach((line, index) => {
          ctx.font = `${line.fontWeight} ${line.fontSize}px Poppins`;
          const textX = Math.round(
            (width - ctx.measureText(line.text).width) / 2
          );
          ctx.fillText(line.text, textX, textY);
          textY += index === 0 ? reducedLineHeight : lineHeight;
        });

        // Calculate "Low" and "High" labels position
        let lineIndex = chartId === "chart2" ? 2.8 : 4;
        const lineY =
          height / 2 -
          (lines.length / 1.72) * lineHeight +
          lineHeight * (lineIndex + 1);
        const radius = Math.min(width, height) / 3;
        const lowX = width / 2 - radius * 0.89;
        const highX = width / 2 + radius * 0.6;
        const labelY = lineY + lineHeight * 2.5; // Vertical position for labels

        ctx.font = "normal 12px Poppins";
        ctx.fillStyle = "black";
        ctx.fillText("Low", lowX, labelY);
        ctx.fillText("High", highX, labelY);

        if (chartId !== "chart2") {
          const lineIndex = 2.9;
          const lineWidth = width / 3.5;
          const lineX = (width - lineWidth) / 2;
          const lineY =
            height / 2 -
            (lines.length / 1.72) * lineHeight +
            lineHeight * (lineIndex + 1);

          ctx.beginPath();
          ctx.moveTo(lineX, lineY);
          ctx.lineTo(lineX + lineWidth, lineY);
          ctx.strokeStyle = "black";
          ctx.lineWidth = 0.2;
          ctx.stroke();
        }

        ctx.save();
      },
    };

    Chart.register(plugin);
    return () => Chart.unregister(plugin);
  }, [chartId, centerText]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: "60%",
    circumference: 270,
    rotation: 225,
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        align: "start",
        labels: {
          usePointStyle: true,
          boxWidth: 26,
          color: "black",
          font: {
            family: "Poppins",
            size: 10,
          },
          padding: 20,
        },
        generateLabels: (chart) => {
          const data = chart.data;
          if (data.labels.length && data.datasets.length) {
            return data.labels.map((label, i) => {
              const dataset = data.datasets[0];
              const value = dataset.data[i];
              const backgroundColor = dataset.backgroundColor[i];
              const borderColor =
                chart.getDatasetMeta(0).controller.chart.options.elements.arc
                  .borderColor;

              return {
                text: `${label}`,
                fillStyle: backgroundColor,
                strokeStyle: borderColor,
                lineWidth: 4,
                hidden: isNaN(value) || chart.getDatasetMeta(0).data[i].hidden,
                index: i,
              };
            });
          }
          return [];
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const index = tooltipItem.dataIndex;
            let actualValue = data[0] || 0; // Actual value
            const adoptedValue = data[1] || 0; // Adopted value
            if(chartId === "chart2"){
              actualValue = data[2] || 0
            }
  
            if (index === 0) {
              return chartId === "chart2" 
                ? `Actual: ${actualValue.toFixed(2)}%`
                : `Actual: ${formatter.format(actualValue)}`;
            } else if (index === 1) {
              return `Adopted: ${formatter.format(adoptedValue)}`;
            }
            return "";
          },
        },
      },
      [`textCenter-${chartId}`]: {}, // Ensure this remains registered
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    layout: {
      padding: {
        left: 50,
        right: 50,
      },
    },
  };

  return (
    <div className="chart-card">
      <Doughnut
        ref={chartRef}
        data={chartData}
        options={options}
        id={chartId}
        className="doughnut_chart"
      />
    </div>
  );
};

export default DoughnutChart;