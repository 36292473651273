import { useState } from "react";
import { useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from "axios";
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { TextField, Autocomplete, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" style={{ color: 'black' }} />;
const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: '#6bb94a' }} />;

export default function Add({ type, getList, editId, setEditId, show, setShow, roleType, portfolioId, ...rest }) {
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);
    const [role, setRole] = useState(roleType);
    const [selectedFacilities, setSelectedFacilities] = useState([]);
    const [facilities, setFacilities] = useState([]);
    const [clients, setClients] = useState([]);
    const handleClose = () => setShow(false);

    useEffect(() => {
        if (roleType) {
            setRole(roleType);
            // if (roleType === 'SubClient') {
            //     getFacilitiesList(portfolioId);
            // }
        }
    }, [roleType, role]);

    const initialValues = {
        id: editId,
        name: '',
        email: '',
        phone: '',
        portfolioName: '',
        facilities: [],
        role: role,
        parentId: user.id,
        portfolioId: portfolioId ? portfolioId : '',
    }

    const validationSchema = yup.object().shape({
        // role: yup.string().required('Role is required'),
        name: yup.string('Enter your name').required('Name is required'),
        email: yup
            .string('Enter your email')
            .email('Enter a valid email')
            .required('Email is required')
            .test(
                'is-email-unique',
                'Email already exists!',
                async (value) => await handleCheckIsEmailAlreadyExist(value)
        ),
        phone: yup.string()
            .nullable()
            .notRequired()
            .min(10, "Must be more than 10 characters")
            .max(20, "Must be less than 20 characters"),
        portfolioName: role === 'Client' ? yup.string().required('Portfolio Name is required') : yup.string(),
        // facilities: role === 'SubClient' ? yup.array().min(1, 'At least one facility must be selected') : yup.array(),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSubmit(values);
            // formik.resetForm();
            // setRole('');
            // setFacilities([]);
        }
    });

    const getFacilitiesList = async (portfolioId) => {
        try {
            await axios.get(`/api/facilities?pId=${portfolioId}`)
                .then(function (response) {
                    let result = response.data;
                    if (result.success) {
                        setFacilities(result.data);
                    }
                })
        } catch (err) {
            console.log(err)
            Swal.fire(err);
        }
    }

    useEffect(() => {
        // getList();
        if (editId > 0) {
            axios.get(`api/user?id=${editId}`).then(res => {
                if (res.data.success) {
                    // if (res.data.data.role === 'SubClient') {
                    //     getFacilitiesList(portfolioId);
                    // }

                    formik.setValues({
                        id: res.data.data.id,
                        name: res.data.data.name,
                        email: res.data.data.email,
                        phone: res.data.data.phone,
                        portfolioName: res.data.data.portfolio?.name || '',
                        role: res.data.data.role,
                        parentId: res.data.data.parentId,
                        facilities: res.data.data.user_allowed_facilities.map(allowed_facility => allowed_facility.facility),
                    });
                    setRole(res.data.data.role);
                    setSelectedFacilities(res.data.data.user_allowed_facilities.map(allowed_facility => allowed_facility.facility));
                    // getFacilitiesList(res.data.data.parentId);
                }
            })
        }
        else {
            // if(roleType === 'SubClient'){
            //     getFacilitiesList(portfolioId);
            // }
            formik.resetForm();
            setRole('');
            setFacilities([]);
            setSelectedFacilities([])
        }
    }, [show, editId]);

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });
    
    const handleSubmit = (values) => {
        values.facilities = selectedFacilities.map(facility => ({
            id: facility.id,
            name: facility.name
        }));
        console.log("values-------------------------------", values);

        const apiCall = editId > 0 ? axios.put : axios.post;
        const apiUrl = editId > 0 ? `api/users/update` : `api/users/create`;

        apiCall(apiUrl, values).then(res => {
            setShow(false);
            if (res.data.success) {
                Toast.fire({
                    icon: 'success',
                    title: res.data.message || `${editId > 0 ? 'Update' : 'Created'} Successful!`
                });
                getList();
                formik.resetForm();
                setRole('');
                setFacilities([]);
                // Swal.fire(res.data.message, '', 'success').then(() => {
                //     getList();
                //     // if(editId <= 0){
                //     formik.resetForm();
                //     setRole('');
                //     setFacilities([]);
                //     // }
                // });
            } else {
                // Swal.fire(res.data.message, '', 'error');
                Toast.fire({
                    icon: 'error',
                    title: res.data.message || `${editId > 0 ? 'Update' : 'Created'} Failed!`
                });
            }
        }).catch((error) => {
            setShow(false);
            // Swal.fire(error.response.data.message, '', 'error');
            Toast.fire({
                icon: 'error',
                title: error.response?.data?.message || error.message
            });
        });
    };

    const handlePopupClose = () => {
        formik.resetForm();
        setFacilities([]);
        handleClose()
    }

    const handleSelectFacilities = (event, selectedFacilities) => {
        setSelectedFacilities(selectedFacilities);
        formik.setFieldValue('facilities', selectedFacilities);
    };

    const handleCheckIsEmailAlreadyExist = async (email) => {
        try {
            const response = await axios.post("/api/auth/checkIsEmailAlreadyExist", { id: formik.values.id, email });
            return !response.data.exists;
        } catch (error) {
            console.error("Error checking email existence:", error);
            return true;
        }
    };

    return (
        <Modal show={show} onHide={handlePopupClose}>
            <Modal.Header closeButton>
                <Modal.Title>{type} {roleType === 'Client' ? 'Portfolio' : 'User'}</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    {role === 'Client' && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="basic-icon-default-Portfolioname">Portfolio Name</label>
                                    <div className="input-group input-group-merge">
                                        <span id="basic-icon-default-name" className="input-group-text"><i className="bx bx-user"></i></span>
                                        <input
                                            name="portfolioName"
                                            type="text"
                                            className="form-control"
                                            id="basic-icon-default-name"
                                            placeholder="Portfolio Name"
                                            aria-label="Portfolio Name"
                                            aria-describedby="basic-icon-default-fullname2"
                                            value={formik.values.portfolioName}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                    {formik.touched.portfolioName && formik.errors.portfolioName && (
                                        <div className="text-danger">{formik.errors.portfolioName}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-name">{role === 'Client' && "Contact"} Name</label>
                                <div className="input-group input-group-merge">
                                    <span id="basic-icon-default-name" className="input-group-text"><i className="bx bx-user"></i></span>
                                    <input name="name" type="text" className="form-control" id="basic-icon-default-name" placeholder="Your name" aria-label="Rajiv Doe" aria-describedby="basic-icon-default-fullname2"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                </div>
                                {formik.touched.name && formik.errors.name && (
                                    <div className="text-danger">{formik.errors.name}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-email">{role === 'Client' && "Contact"} Email</label>
                                <div className="input-group input-group-merge">
                                    <span className="input-group-text"><i className="bx bx-envelope"></i></span>
                                    <input name="email" type="text" id="basic-icon-default-email" className="form-control" placeholder="Your email" aria-label="Rajiv.doe@example.com" aria-describedby="basic-icon-default-email2"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                </div>
                                {formik.touched.email && formik.errors.email && (
                                    <div className="text-danger">{formik.errors.email}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <label className="form-label" htmlFor="basic-icon-default-phone">Phone No</label>
                                <div className="input-group input-group-merge">
                                    <span id="basic-icon-default-phone2" className="input-group-text"><i className="bx bx-phone"></i></span>
                                    <input name="phone" type="text" id="basic-icon-default-phone" className="form-control phone-mask" placeholder="658 799 8941" aria-label="658 799 8941" aria-describedby="basic-icon-default-phone2"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur} />
                                </div>
                                {formik.touched.phone && formik.errors.phone && (
                                    <div className="text-danger">{formik.errors.phone}</div>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* {role === 'SubClient' && (
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="basic-icon-default-Portfolioname">Select Facilities</label>
                                        <div className="input-group input-group-merge">
                                            <Autocomplete
                                                name="facilities"
                                                multiple
                                                id="checkboxes-tags-demo"
                                                size="small"
                                                options={facilities}
                                                value={selectedFacilities}
                                                onChange={(event, selectedFacilities) => handleSelectFacilities(event, selectedFacilities)}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) => option.name}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={icon}
                                                            checkedIcon={checkedIcon}
                                                            style={{ marginRight: 8 }}
                                                            checked={selected}
                                                        />
                                                        {option.name}
                                                    </li>
                                                )}
                                                style={{ width: 500 }}
                                                renderInput={(params) => (
                                                    <TextField {...params} className="form-control" placeholder="Facilities" />
                                                )}
                                            />
                                        </div>
                                        {formik.touched.facilities && formik.errors.facilities && (
                                            <div className="text-danger">{formik.errors.facilities}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    )} */}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal" onClick={handlePopupClose}>Close</button>
                    <button type="submit" className="btn btn-primary">{type === 'Add' ? 'Add' : 'Update'}</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}