import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Height } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

var moment = require('moment');

const tableRowsEditableInputHeadings = [
    "accounting", "advertising", "bankFeesAndProcessingCharges", "cleaning", "computerSoftware", "cogs",
    "electricity", "insurance", "membershipFees", "motorVehicle", "ownersCorp", "postage",
    "printingAndStationary", "repairsAndMaintenance", "security", "telephoneAndInternet", "travel",
    "uniforms", "staffAmenities", "staffTraining", "wagesAndSalaries", "miscellaneous", "councilRates",
    "landTax", "waterCharges","rentPayable"
];

const tableRowsEditableInputHeadingValues = {
    "accounting" : "Accounting", 
    "advertising" : "Advertising", 
    "bankFeesAndProcessingCharges" : "Bank Fees & Processing Charges", 
    "cleaning" : "Cleaning", 
    "computerSoftware" : "Computer Software", 
    "cogs" : "COGS",    
    "electricity" : "Electricity", 
    "insurance" : "Insurance", 
    "membershipFees" : "Membership Fees", 
    "motorVehicle" : "Motor Vehicle (customer use)", 
    "ownersCorp" : "Owners Corp / Strata Levies", 
    "postage" : "Postage",    
    "printingAndStationary" : "Printing & Stationary", 
    "repairsAndMaintenance" : "Repairs & Maintenance", 
    "security" : "Security", 
    "telephoneAndInternet" : "Telephone & Internet", 
    "travel" : "Travel",    
    "uniforms" : "Uniforms", 
    "staffAmenities" : "Staff Amenities", 
    "staffTraining" : "Staff Training", 
    "wagesAndSalaries" : "Wages & Salaries", 
    "miscellaneous" : "Other and Miscellaneous", 
    "councilRates" : "Council Rates",    
    "landTax" : "Land Tax", 
    "waterCharges" : "Water Charges",
    "rentPayable" : "Rent Payable",

    "totalOperatingExpenses" : "Total Operating Expenses",
    "totalAdoptedStorageIncome" : "Total Adopted Storage Income",
    "percentOfOpExToStorageRevenue" : "% of Op Ex to Storage Revenue",
    "totalAdditionalNonStorageIncome" : "Total Additional (non-Storage) Income",
    "totalIncome" : "Total Income",
    "managementFee" : "Management Fee",
    "totalExpenses" : "Total Expenses",
    "percentOfTotalRevenuetoStorageRevenue" : "% of Total Revenue to Storage Revenue",
    "netOperatingIncome" : "Net Operating Income",
    "profitability" : "Profitability"
};

const tableRowsCalculatedHeadings = [
    "totalOperatingExpenses","totalAdoptedStorageIncome","percentOfOpExToStorageRevenue","totalAdditionalNonStorageIncome","totalIncome","managementFee","totalExpenses","percentOfTotalRevenuetoStorageRevenue","netOperatingIncome","profitability"
];

const formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export default function ClientView({currentFacility, setCurrentFacility, operatingExpenseRanges, selectedFacilityId, setLoading, getFacilityDetails,taxRates, currentlySelectedFacility, setCurrentlySelectedFacility }){
    const authUser = localStorage.getItem('sv-authUser');
    const user = JSON.parse(authUser);

    
    var CurrentDate = moment().format('YYYY-MM-DD');
    var currentMonth = new Date(CurrentDate).getMonth() + 1; // getMonth() returns month from 0-11, so add 1

    var CurrentFY = currentFacility && currentFacility[0] && currentFacility[0].facility && parseInt(currentFacility[0].facility.mostRecentFY) > 0
            ? (parseInt(currentFacility[0].facility.mostRecentFY) + 1) // client input sheet R7 value
            : ( (currentMonth <= 6) ? new Date(CurrentDate).getFullYear() : new Date(CurrentDate).getFullYear() + 1 ) // client input sheet R7 logic

    const pastFourYears = Array.from({ length: 4 }, (_, i) => CurrentFY - i);

    const currentFacilityData = currentFacility.find(facility => facility.financialYear === CurrentFY);
    // console.log("currentFacilityData------>",currentFacilityData)
    
    const withoutFinancialYear = currentFacility.filter(item => item.financialYear === undefined);

    const operatingExpenseRangesNames = operatingExpenseRanges.map(item => item.name);

    let currentFacilityStateTax = 0
    if(currentFacilityData && currentFacilityData.facility){
        if(currentFacilityData.facility.country === 'New Zealand'){
            let stateData = taxRates.find(item => item.state === 'NZ');
            // find facility sate in taxRates and get rate
            currentFacilityStateTax = currentFacilityData 
                ? stateData 
                    ? stateData.rate 
                    : 0
                : 0;
        }
        else{
            let stateData = taxRates.find(item => item.state === currentFacilityData.facility.state);
            // find facility sate in taxRates and get rate
            currentFacilityStateTax = currentFacilityData 
                ? stateData 
                    ? stateData.rate 
                    : 0
                : 0;
        }
    }
    const landTax = currentFacilityData && currentFacilityData.facility ? (currentFacilityData.facility.currentSiteValueAssessment * currentFacilityStateTax)/100:0;
    
    const totalOperatingExpensesFormula = currentFacilityData && currentFacilityData.facility && currentFacilityData.totalOperatingExpenses ? ( ( parseFloat(currentFacilityData.totalOperatingExpenses) + (parseFloat(currentFacilityData.landTax) || 0)) - parseFloat(landTax) )  : '' ;

    const totalIncomeFormula = currentFacilityData && currentFacilityData.facility ? (parseInt(currentFacilityData.totalAdoptedStorageIncome) + parseInt(currentFacilityData.totalAdditionalNonStorageIncome)) : 0;
    const managementFeeFormula = currentFacilityData && currentFacilityData.facility ? ((-1 * totalIncomeFormula) * parseFloat(currentFacilityData.facility.facilityManagementFeePortfolioRate ? (currentFacilityData.facility.facilityManagementFeePortfolioRate)/100 : 0)) :'' ;
   
    const totalOperatingExpensesEstimated = currentFacilityData && currentFacilityData.facility && currentFacilityData.estimatedTotalOperatingExpenses ? formatter.format( currentFacilityData.estimatedTotalOperatingExpenses ) : '';
    const totalAdoptedStorageIncomeEstimated = currentFacilityData && currentFacilityData.facility && currentFacilityData.totalAdoptedStorageIncome ? formatter.format(currentFacilityData.totalAdoptedStorageIncome)  : '';
    const percentOfOpExToStorageRevenueEstimated = currentFacilityData && currentFacilityData.facility && currentFacilityData.estimatedPercentOfOpExToStorageRevenue !== null ? parseFloat(currentFacilityData.estimatedPercentOfOpExToStorageRevenue).toFixed(2)+"%"  : '';
    const totalAdditionalNonStorageIncomeEstimated = currentFacilityData && currentFacilityData.facility && currentFacilityData.totalAdditionalNonStorageIncome ? formatter.format(currentFacilityData.totalAdditionalNonStorageIncome)  : '';
    const totalIncomeEstimated = currentFacilityData && currentFacilityData.facility && currentFacilityData.estimatedTotalIncome ? formatter.format( currentFacilityData.estimatedTotalIncome ) : '';
    const managementFeeEstimated = currentFacilityData && currentFacilityData.facility && currentFacilityData.estimatedManagementFee ? formatter.format( currentFacilityData.estimatedManagementFee ) : '';
    const totalExpensesEstimated = currentFacilityData && currentFacilityData.facility && currentFacilityData.estimatedTotalExpenses ? formatter.format( currentFacilityData.estimatedTotalExpenses ) : '';


    const percentOfTotalRevenuetoStorageRevenueEstimated = 
    currentFacilityData && currentFacilityData.facility && !isNaN(parseFloat(currentFacilityData.estimatedTotalExpenses)) && !isNaN(parseFloat(currentFacilityData.estimatedTotalIncome)) && parseFloat(currentFacilityData.estimatedTotalExpenses) !== 0  && parseFloat(currentFacilityData.estimatedTotalIncome) !== 0 
    ? ((parseFloat(currentFacilityData.estimatedTotalExpenses) / parseFloat(currentFacilityData.estimatedTotalIncome)) * -100).toFixed(2) + "%" 
    : 0+ "%";

    const netOperatingIncomeEstimated = currentFacilityData && currentFacilityData.facility && currentFacilityData.estimatedTotalExpenses !== null && currentFacilityData.estimatedTotalIncome !== null ? formatter.format(parseFloat(currentFacilityData.estimatedTotalExpenses) + parseFloat(currentFacilityData.estimatedTotalIncome)): '';
    
    const profitabilityEstimated = 
    currentFacilityData && currentFacilityData.facility && !isNaN(parseFloat(currentFacilityData.estimatedTotalIncome)) &&  !isNaN(parseFloat(currentFacilityData.estimatedTotalExpenses)) && parseFloat(currentFacilityData.estimatedTotalIncome) !== 0
    ? ((parseFloat(currentFacilityData.estimatedTotalExpenses) + parseFloat(currentFacilityData.estimatedTotalIncome)) / parseFloat(currentFacilityData.estimatedTotalIncome) * 100).toFixed(2) + "%" 
    : 0+ "%";


    const [inputValues, setInputValues] = useState({});
    const [showBackgroundCalcuation, setShowBackgroundCalcuation] = useState("");

    useEffect(() => { 
        // console.log("currentFacility------------>",currentFacility)
        const updatedFacilities = currentFacility.reduce((acc, facility) => {
            if (facility.financialYear) {
                // Find the index of the financial year in the accumulated result
                const yearIndex = acc.findIndex(f => f.financialYear === facility.financialYear);                
                if (yearIndex > -1) {
                    // Merge the existing data with the new data for the same financial year
                    acc[yearIndex] = { ...acc[yearIndex], ...facility };
                } else {
                    // Add a new entry if the financial year is not found
                    acc.push(facility);
                }
            }
            return acc;
        }, []);
    
        // Now you have updatedFacilities containing grouped data for each financial year.
        // console.log("updatedFacilities", updatedFacilities);
        const initialValuesByYear = updatedFacilities.reduce((acc, facility) => {
            const initialValues = {};
            tableRowsEditableInputHeadings.forEach((heading) => {
                initialValues[heading] = facility[heading] || '';
            });
            initialValues['facilityId'] = facility.facilityId;
            initialValues['financialYear'] = facility.financialYear;
            initialValues['totalAdoptedStorageIncome'] = facility.totalAdoptedStorageIncome;
            initialValues['totalAdditionalNonStorageIncome'] = facility.totalAdditionalNonStorageIncome;
    
            acc[facility.financialYear] = initialValues;
            return acc;
        }, {});
    
        // console.log("initialValuesByYear", initialValuesByYear);
        setInputValues(initialValuesByYear);

    }, [currentFacility, selectedFacilityId]);

    // Remove formatting
    const removeFormatting = (value) => {
        return value.replace(/[^0-9.-]+/g, '');
    };

    const handleInputChange = (year, heading, value) => {
        if (!selectedFacilityId) {
            Swal.fire('Warning', 'Please Select a Facility!', 'warning');
            return;
        }
    
        const rawValue = value === '' ? '' : removeFormatting(value);
    
        // Validate the input to allow only integer or decimal values
        if (rawValue === '' || /^\d*\.?\d*$/.test(rawValue)) {
            setInputValues(prevValues => {
                // Check if facilityId and financialYear are already present
                const updatedYearData = {
                    ...prevValues[year],
                    [heading]: rawValue,
                    financialYear: prevValues[year]?.financialYear || year,
                    facilityId: prevValues[year]?.facilityId || selectedFacilityId,
                };
    
                return {
                    ...prevValues,
                    [year]: updatedYearData
                };
            });
        }
    };
    const handleTotalValueInputChange = (year, heading, value) => {
        const rawValue = value === '' ? '' : removeFormatting(value);

        setInputValues(prevValues => {
            // Ensure the `prevValues` is correctly formatted
            console.log("prevValues before update--", prevValues);
    
            // Get the existing data for the year
            const existingYearData = prevValues[year] || {};
            console.log("existingYearData--", existingYearData);
    
            // Add or update the heading with the new rawValue
            const updatedYearData = {
                ...existingYearData,
                [heading]: rawValue,
                financialYear: existingYearData.financialYear || year,
                facilityId: existingYearData.facilityId || selectedFacilityId,
            };
    
            console.log("updatedYearData--", updatedYearData);
    
            // Return the updated values
            const updatedValues = {
                ...prevValues,
                [year]: updatedYearData
            };
    
            console.log("updatedValues--", updatedValues);
    
            return updatedValues;
        });

      

        setCurrentFacility(prevData => {
            const dataArray = Array.isArray(prevData) ? prevData : [];
            let found = false;

            const updatedData = dataArray.map(item => {
                if (item.financialYear === year) {
                    found = true;
                    return {
                        ...item,
                        [heading]: rawValue
                    };
                }
                return item;
            });

            if (!found) {
                const newRecord = {
                    // id: updatedData.length + 1, // or handle ID differently
                    facilityId: selectedFacilityId, // set the appropriate facilityId if needed
                    financialYear: year,
                    // totalOperatingExpenses: "0.00", // or any default value
                    // totalAdoptedStorageIncome: "0.00", // or any default value
                    // percentOfOpExToStorageRevenue: "0.00", // or any default value
                    heading: rawValue,
                    // totalIncome: "0.00", // or any default value
                    // facility: {
                    //     id: null, // set the appropriate facilityId if needed
                    //     mostRecentFY: null, // set the appropriate year if needed
                    //     country: "" // set the appropriate country if needed
                    // }
                };
                return [...updatedData, newRecord];
            }

            return updatedData;
        });
        
        // const numericValue = parseFloat(value.replace(/[^0-9.-]+/g,"")); // Remove any non-numeric characters

        // if (numericValue > 0) {
        //     Swal.fire({
        //         title: 'Are you sure?',
        //         text: "You have entered a value greater than 0.",
        //         icon: 'warning',
        //         showCancelButton: true,
        //         confirmButtonText: 'Yes, proceed',
        //         cancelButtonText: 'No, cancel'
        //     }).then((result) => {
        //         if (result.isConfirmed) {
        //             // Proceed with the value change or further processing
        //             console.log("Confirmed value:", numericValue);
        //             // Call a function or update state here as needed
        //         } else {
        //             // If user cancels, revert the input change if necessary
        //             console.log("User cancelled the operation");
        //         }
        //     });
        // } else {
        //     console.log("Value is less than or equal to 0:", numericValue);
        //     // Handle the case for non-positive values if needed
        // }
    };

    const calculateFacilityValuation = async (facilityId) => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/calculateFacilityValuation?facilityId=${facilityId}`);
            setLoading(false)
            let result = response.data;
            if (result.success) {
                console.log("response.data.data", response.data);
            } else {
                Swal.fire("Facility Details Missing!", result.message, "info");
            }
        } catch (err) {
            setLoading(false)
            Swal.fire("Error", err.message, "error");
        }
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end", 
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        customClass: {
            container: 'custom-toast-position',
            popup: 'custom-toast-bg' 
        },    
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();

        console.log("inputValues===",inputValues)
        // return;
        if(inputValues.facilityId === ''){
            Swal.fire("Select facility")
            return false
        }
        try {
            const response = await axios.put('/api/saveOperatingExpenses', inputValues);
            // setLoading(false)
            // if (response.data.success) {
            //     Swal.fire(response.data.message, '', 'success').then(() => {
            //         getFacilityDetails(inputValues[CurrentFY].facilityId)
            //     });
            // } else {
            //     Swal.fire('Error', 'Failed to save input values', 'error');
            // }
            if (response.data.success) {
                setLoading(false);
                Toast.fire({
                    icon: 'success',
                    title: response.data.message || 'Update Successful!'
                });
                calculateFacilityValuation(inputValues[CurrentFY].facilityId)
                getFacilityDetails(inputValues[CurrentFY].facilityId)
                // Swal.fire(response.data.message, '', 'success').then(() => {
                //     calculateFacilityValuation(inputValues[CurrentFY].facilityId)
                //     getFacilityDetails(inputValues[CurrentFY].facilityId)
                // });
                // Swal.fire({
                //     title: response.data.message,
                //     text: "Do you want to recalculate this facility?",
                //     icon: 'success',
                //     showCancelButton: true,
                //     confirmButtonText: 'Yes, recalculate!',
                //     cancelButtonText: 'No, thanks',
                //     allowOutsideClick: false,
                //     showLoaderOnConfirm: true
                // }).then((result) => {
                //     if (result.isConfirmed) {
                //         Swal.fire({
                //             title: 'Recalculating...',
                //             allowOutsideClick: false,
                //         });
                //         calculateFacilityValuation(inputValues[CurrentFY].facilityId)
                //             .then(() => {
                //                 Swal.fire('Recalculation complete!', '', 'success');
                //                 getFacilityDetails(inputValues[CurrentFY].facilityId)
                //             })
                //             .catch((error) => {
                //                 Swal.fire('Recalculation failed', '', 'error');
                //             });
                //     } else {
                //         getFacilityDetails(inputValues[CurrentFY].facilityId)
                //         setLoading(false);
                //     }
                // });
            } else {
                // Swal.fire('Error', 'Failed to save input values', 'error');
                Toast.fire({
                    icon: 'error',
                    title: response.data.message || 'Failed to save input values!'
                });
                setLoading(false);
            }
        } catch (error) {
            setLoading(false)
            // Swal.fire('Error', error.message, 'error');
            Toast.fire({
                icon: 'error',
                title: error.response?.data?.message || error.message
            });
        }
    };

    // const facilityId = localStorage.getItem('selectedFacility') ? parseInt(localStorage.getItem('selectedFacility')) : null ;

    // const [currentlySelectedFacility, setCurrentlySelectedFacility] = useState({});
    // useEffect(() => {
    //     if (facilityId) {
    //         getCurrentFacilityDetails(facilityId)
    //     }
    // }, [facilityId]);

    // const getCurrentFacilityDetails = (facilityId) => {
    //     setLoading(true)
    //     axios.get(`api/facility?id=${facilityId}`).then(res => {
    //         setLoading(false)
    //         if(res.data.success){
    //             const result = res.data.data;
    //             setCurrentlySelectedFacility(result)
    //         }
    //     })
    // }
    return (
        <>
        <div className="bg-white p-3 mb-3">
            <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-Rg-1 view">
                    {/* {currentFacilityData ? */}
                    <form id="formOperatingExpenses" method="POST" onSubmit={handleSubmit}>
                        <div className="facilityTable table-responsive wrapper">
                            <table className="table table-bordered" id="Rj-scroll-fix">
                                <thead>
                                    <tr>
                                        <th className="table-blank-space sticky-col first-col" scope="col"></th>
                                        {/* <th scope="col">Est. Current<br/>FY Cost </th> */}
                                        <th scope="col">Estimated Current<br/>FY Cost </th>
                                        <th scope="col">Most Recent<br/> FY1</th>
                                        <th scope="col">Most Recent<br/> FY2</th>
                                        <th scope="col">Most Recent<br/> FY3</th>
                                        { user.role === 'SuperAdmin'?
                                            <>
                                            {
                                                showBackgroundCalcuation === '' ?
                                                <th colSpan={4}>Background Calculation  
                                                    <span style={{textAlign: 'end'}} >
                                                        <Tooltip title={'Hide Background Calculation'} 
                                                            style={{ 
                                                                color:  'white' , 
                                                                backgroundColor: '#2c383d' , 
                                                                padding: '4px',
                                                                marginLeft: '7px'
                                                            }}>
                                                                <IconButton className="hideMonths" onClick={() => setShowBackgroundCalcuation('d-none')}>
                                                                    <i className="bx bxs-left-arrow cursor-pointer"></i>
                                                                </IconButton>
                                                        </Tooltip>
                                                    </span>
                                                </th>
                                                :
                                                <th className="table-blank-space" scope="col" >
                                                    <span style={{textAlign: 'end', color: '#2c383d'}} >
                                                        <Tooltip title={'Show Background Calculation'} 
                                                        style={{ 
                                                            color: 'white' , 
                                                            backgroundColor: '#2c383d' , 
                                                            padding: '4px',
                                                            marginLeft: '7px'
                                                        }}>
                                                            <IconButton className="showMonths" onClick={() => setShowBackgroundCalcuation('')} >
                                                                <i className="bx bxs-right-arrow cursor-pointer"></i>
                                                            </IconButton>
                                                    </Tooltip>
                                                    </span>
                                                </th>
                                            }

                                                <th scope="col">Low<br/> Range</th>
                                                <th scope="col">In line</th>
                                                <th scope="col">High<br/> Range</th>
                                                <th scope="col">In line</th>
                                                <th className="table-blank-space" scope="col"></th>
                                                <th scope="col">Portfolio <br/> Expenses</th> 
                                            </>
                                            :""
                                        }

                                    </tr>
                                    <tr>
                                        <th className="bg-gray-table  sticky-col first-col" scope="col">Financial Year</th>
                                        <th className="bg-gray-table" scope="col">{CurrentFY ? CurrentFY : moment().format('YYYY')}</th>
                                        <th className="bg-gray-table" scope="col">{CurrentFY ? CurrentFY - 1 : moment().subtract(1, 'years').format('YYYY')}</th>
                                        <th className="bg-gray-table" scope="col">{CurrentFY ? CurrentFY - 2 : moment().subtract(2, 'years').format('YYYY')}</th>
                                        <th className="bg-gray-table" scope="col">{CurrentFY ? CurrentFY - 3 : moment().subtract(3, 'years').format('YYYY')}</th>
                                        { user.role === 'SuperAdmin'?
                                            <>    
                                            {
                                                showBackgroundCalcuation === '' ?   
                                                    <>                                     
                                                    <th colSpan={2} className={`bg-gray-table ${showBackgroundCalcuation}`}>Mature</th>
                                                    <th colSpan={2} className={`bg-gray-table ${showBackgroundCalcuation}`}>Build up</th>
                                                    </>
                                                :
                                                    <th className="table-blank-space" scope="col"></th>
                                                }
                                                <th className="bg-gray-table"></th>
                                                <th className="bg-gray-table"></th>
                                                <th className="bg-gray-table"></th>
                                                <th className="bg-gray-table"></th>
                                                <th className="table-blank-space" scope="col"></th>
                                                <th className="bg-gray-table">{moment().format('YYYY')}</th>
                                            </>
                                            :""
                                        }

                                    </tr>    
                                </thead>
                                <tbody>
                                    {tableRowsEditableInputHeadings.map((heading, rowIndex) => {
                                        const isClientOrSubClient = user.role === 'Client' || user.role === 'SubClient';
                                        const monthsToShow = pastFourYears.length;
                                        const monthsData = pastFourYears.slice(0, monthsToShow);
                                        return (
                                            <tr key={rowIndex}>
                                                <td className={heading === "rentPayable" ? " table-red-bg sticky-col first-col" : "theme-color-bg sticky-col first-col"}>{tableRowsEditableInputHeadingValues[heading]}</td>
                                                { 
                                                    monthsData.length > 0 && currentFacility.length > 0 ? 
                                                    monthsData.map((year, yearIndex) => {
                                                        const facility = currentFacility.find(facility => parseInt(facility.financialYear) === parseInt(year));
                                                        const value = facility ? facility[heading] : '';
                                                        return (
                                                            <td key={yearIndex} className={` ${year} = ${CurrentFY}`}>
                                                                { 
                                                                // inputValues[year] ?
                                                                    (
                                                                    <input 
                                                                        disabled = {user.role !== "SuperAdmin" && currentlySelectedFacility && parseInt(currentlySelectedFacility.isLocked) === 1}
                                                                        type="text" 
                                                                        className="form-control form-control-sm" 
                                                                        placeholder="$0" 
                                                                        value={inputValues[year] && inputValues[year][heading] ? formatter.format(inputValues[year][heading]) : ''}
                                                                        onChange={(e) => handleInputChange(year, heading, e.target.value)}
                                                                    />) 
                                                                    // : 
                                                                    // <input 
                                                                    //     type="text" 
                                                                    //     className="form-control form-control-sm" 
                                                                    //     placeholder="$0" 
                                                                    //     value={inputValues[year] && inputValues[year][heading] ? formatter.format(inputValues[year][heading]) : ''}
                                                                    //     onChange={(e) => handleInputChange(year, heading, e.target.value)}
                                                                    // /> 
                                                                }
                                                            </td>
                                                        );
                                                    })
                                                    :
                                                    <React.Fragment key={`noData-${rowIndex}`}>
                                                        <td className="">
                                                            <input 
                                                                type="text" 
                                                                disabled = {user.role !== "SuperAdmin" && currentlySelectedFacility && parseInt(currentlySelectedFacility.isLocked) === 1}
                                                                className="form-control form-control-sm" 
                                                                placeholder="$0" 
                                                                autoFocus=""                                                                
                                                                onChange={(e) => handleInputChange(CurrentFY, heading, e.target.value)}
                                                            ></input>
                                                        </td>
                                                        <td className=""></td>
                                                        <td className=""></td>
                                                        <td className=""></td>
                                                    </React.Fragment>
                                                }
                                                { !isClientOrSubClient ?
                                                    withoutFinancialYear.length > 0 && currentFacility.length > 0 ? 
                                                        withoutFinancialYear.map((dataValue, i) => {
                                                            return (
                                                                <>                                                                
                                                                { i === 3 && showBackgroundCalcuation === 'd-none' ?
                                                                    <td key={"empty_" + i} className="table-blank-space  column_hiding_columns"></td>
                                                                    :
                                                                    <td key={"other_" + i} className={
                                                                        i <= 3 
                                                                            ? `bg-table ${showBackgroundCalcuation}`
                                                                            : dataValue[heading] && (dataValue[heading] === 'TRUE' || dataValue[heading] === 'FALSE') 
                                                                                ? "color-red-table" : 
                                                                                ""
                                                                        }>
                                                                            { (typeof dataValue === 'object' ? dataValue[heading] : '') }
                                                                    </td>
                                                                }
                                                                </>
                                                            );
                                                        }).concat(
                                                            <React.Fragment key={`noData-${rowIndex}`}>
                                                                <td className="table-blank-space"></td>
                                                                <td key="current_facility_estimated" className="">
                                                                    {
                                                                        currentFacilityData && typeof currentFacilityData[heading] === 'object' 
                                                                        ? '' 
                                                                        : currentFacilityData && currentFacilityData[heading] && heading === 'landTax' 
                                                                            ? <p className="m-0" style={{cursor:'pointer'}} title={`landTax used : ${currentFacilityStateTax}%`}>{formatter.format(landTax)}</p>
                                                                            : currentFacilityData && currentFacilityData[heading] 
                                                                                ? formatter.format(currentFacilityData[heading]) 
                                                                                : ''
                                                                    }
                                                                </td>
                                                            </React.Fragment>
                                                        )
                                                    : 
                                                    Array.from({ length: 9 }).map((_, index) => (
                                                        <td key={index} className=""></td>
                                                    ))
                                                    :'' //client view nothing to show
                                                }
                                            </tr>
                                        );
                                    })}

                                    <tr key={"empty_row"} height="15px;">
                                        
                                        <td className="bg-white"></td> 

                                        <td className="bg-white"></td>   
                                        <td className="bg-white"></td>    
                                        <td className="bg-white"></td>  
                                        <td className="bg-white"></td>    
                                           
                                        { !(user.role === 'Client' || user.role === 'SubClient') ?  
                                        <> 
                                        {
                                            showBackgroundCalcuation === '' ?   
                                            <>
                                            <td className="bg-white background_calculation"></td>
                                            <td className="bg-white background_calculation"></td>  
                                            <td className="bg-white background_calculation"></td>    
                                            <td className="bg-white background_calculation"></td> 
                                            </> :
                                            <td className="bg-white table-blank-space column_hiding_columns"></td>
                                        }
                                        <td className="bg-white low_range"></td> 
                                        <td className="bg-white in_line"></td> 
                                        <td className="bg-white high_range"></td> 
                                        <td className="bg-white in_line"></td> 
                                        <td className="bg-white table-blank-space"></td>    
                                        <td className="bg-white estimated_expenses"></td> 
                                        </>  
                                        :""}   

                                    </tr>

                                    {tableRowsCalculatedHeadings.map((heading, rowIndex) => {
                                        const isClientOrSubClient = user.role === 'Client' || user.role === 'SubClient';
                                        const monthsToShow = pastFourYears.length;
                                        const monthsData = pastFourYears.slice(0, monthsToShow);
                                        return (
                                            <tr key={"calculated_rows" + rowIndex}>
                                                <td scope="colspan=2" className="sticky-col first-col bg-orange">{tableRowsEditableInputHeadingValues[heading]}</td>
                                                {
                                                    monthsData.length > 0 ? 
                                                        monthsData.map(year => {
                                                            const facility = currentFacility.find(facility => facility.financialYear === year);
                                                            const value = facility && facility[heading] ? facility[heading] : '';

                                                            return (
                                                                <td key={year} className={['totalOperatingExpenses','managementFee','totalExpenses'].includes(heading) 
                                                                        ? "bg-white color-red-table" 
                                                                        : ( ['totalAdoptedStorageIncome', 'totalAdditionalNonStorageIncome'].includes(heading) 
                                                                            ? 'bg-table' 
                                                                            : ( ['netOperatingIncome', 'totalIncome', 'totalAdoptedStorageIncome'].includes(heading) ? '' : 'bg-white')
                                                                        )
                                                                    }>
                                                                    {  year !== CurrentFY  && ['totalAdoptedStorageIncome', 'totalAdditionalNonStorageIncome'].includes(heading)
                                                                        ? <input 
                                                                            type="text" 
                                                                            disabled = {user.role !== "SuperAdmin" && currentlySelectedFacility && parseInt(currentlySelectedFacility.isLocked) === 1}
                                                                            className="form-control form-control-sm" 
                                                                            placeholder="$0" 
                                                                            autoFocus=""
                                                                            // value={ value ? formatter.format(value) : 0}                                                              
                                                                            // onChange={(e) => handleTotalValueInputChange(year, heading, e.target.value)} 
                                                                            value={inputValues[year] && inputValues[year][heading] ? formatter.format(inputValues[year][heading]) :  (value ? formatter.format(value) : 0) }
                                                                            onChange={(e) => handleTotalValueInputChange(year, heading, e.target.value)}
                                                                            ></input>

                                                                        : typeof value === 'object' ? '' : value && value !== undefined 
                                                                            ? ["percentOfOpExToStorageRevenue","percentOfTotalRevenuetoStorageRevenue","profitability"].includes(heading) 
                                                                                ? parseFloat(value).toFixed(2) + "%"
                                                                                : formatter.format(value)
                                                                            : ''
                                                                    }
                                                                </td>
                                                            );
                                                        })
                                                    :
                                                        <>
                                                            <td className=""></td>
                                                            <td className=""></td>
                                                            <td className=""></td>
                                                            <td className=""></td>
                                                        </>
                                                }
                                                {!isClientOrSubClient &&
                                                    <>                                                                                                   
                                                        {showBackgroundCalcuation === 'd-none' 
                                                            ? <td className="table-blank-space column_hiding_columns"></td> 
                                                            : <>
                                                            <td className={`bg-table ${showBackgroundCalcuation}`}></td>                                            
                                                            <td className={`bg-table ${showBackgroundCalcuation}`}></td>                                            
                                                            <td className={`bg-table ${showBackgroundCalcuation}`}></td>                                            
                                                            <td className={`bg-table ${showBackgroundCalcuation}`}></td> 
                                                            </>
                                                        }

                                                        <td className="">
                                                            {
                                                                operatingExpenseRanges && operatingExpenseRangesNames.includes(heading) 
                                                                ? operatingExpenseRanges.filter(item => item.name === heading).map(item => item.low).join('') + "%"
                                                                : ''
                                                            }
                                                        </td>
                                                        <td className="color-red-table">
                                                            {
                                                                heading === 'totalOperatingExpenses' 
                                                                ? (
                                                                    withoutFinancialYear.filter(item => 'totalOperatingExpenses' in item && item['totalOperatingExpenses'] !== undefined)[0]?.totalOperatingExpenses ?? ''
                                                                    )
                                                                : (
                                                                    operatingExpenseRanges && operatingExpenseRangesNames.includes(heading) && currentFacilityData && currentFacilityData[heading] !== undefined
                                                                    ? parseFloat(currentFacilityData[heading]) < parseFloat(operatingExpenseRanges.filter(item => item.name === heading).map(item => parseFloat(item.low).toFixed(2)).join('')) 
                                                                        ? "FALSE" 
                                                                        : "TRUE"
                                                                    : ''
                                                                )
                                                            }
                                                        </td>
                                                        <td className="">
                                                        {
                                                            operatingExpenseRanges && operatingExpenseRangesNames.includes(heading) 
                                                            ? operatingExpenseRanges.filter(item => item.name === heading).map(item => parseFloat(item.high).toFixed(2)).join('') + "%"
                                                            : ''
                                                        }
                                                        </td>
                                                        <td className="color-red-table">
                                                        {
                                                            heading === 'totalOperatingExpenses' 
                                                            ? (
                                                                withoutFinancialYear.filter(item => 'totalOperatingExpenses' in item && item['totalOperatingExpenses'] !== undefined)[1]?.totalOperatingExpenses ?? ''
                                                                )
                                                            : (
                                                                operatingExpenseRanges && operatingExpenseRangesNames.includes(heading) &&
                                                                currentFacilityData && currentFacilityData[heading] !== undefined
                                                                    ? parseFloat(currentFacilityData[heading]) > parseFloat(operatingExpenseRanges.filter(item => item.name === heading).map(item => parseFloat(item.high).toFixed(2)).join('')) 
                                                                        ? "FALSE" 
                                                                        : "TRUE"
                                                                    : ''
                                                                )
                                                        }
                                                        </td>

                                                        <td className="table-blank-space">
                                                            {
                                                                heading === 'totalOperatingExpenses' 
                                                                ? (() => {
                                                                    const values = withoutFinancialYear.filter(item => 'totalOperatingExpenses' in item && item['totalOperatingExpenses'] !== undefined);
                                                                    const value1 = values[0]?.totalOperatingExpenses ?? 0;
                                                                    const value2 = values[1]?.totalOperatingExpenses ?? 0;
                                                                    return value1 + value2;
                                                                    })()
                                                                : ''
                                                            }
                                                        </td>
                                                        <td className={
                                                                        ['totalOperatingExpenses','managementFee','totalExpenses'].includes(heading) ? 
                                                                        "bg-white color-red-table" : 
                                                                        (
                                                                            ['netOperatingIncome', 'totalIncome', 'totalAdoptedStorageIncome'].includes(heading) ? 
                                                                            '' : 
                                                                            'bg-white'
                                                                        )
                                                                        }>
                                                        {/* {`${eval(heading+"Estimated")}`} */}
                                                        {`${eval(heading+"Estimated")}` || ''}
                                                        </td>
                                                    </>
                                                }
                                            </tr>
                                        );
                                    })}
                                </tbody>

                            </table>
                        </div>                            
                        { selectedFacilityId ?
                        <div className="mt-4 mb-4 d-flex justify-content-end">
                            {/*<button type="submit" className="btn btn-primary me-2">Save Changes</button> */}
                            {user.role !== "SuperAdmin" && currentlySelectedFacility.isLocked === '1' ? (
                                <Tooltip 
                                    title="This facility is locked. The action cannot be completed at this time."
                                    size="small"
                                >
                                    <span className='cursor-not-allowed-disabled'>
                                        <button
                                            type="submit"
                                            className="btn btn-primary me-2"
                                            disabled={user.role !== "SuperAdmin" && currentlySelectedFacility.isLocked === '1'}
                                        >
                                            Save Changes
                                        </button>
                                    </span>
                                </Tooltip>
                            ) : (
                                <button
                                    type="submit"
                                    className="btn btn-primary me-2"
                                    disabled={user.role !== "SuperAdmin" && currentlySelectedFacility.isLocked === '1'}
                                >
                                    Save Changes
                                </button>
                            )}
                        </div>
                        :""}
                        
                    </form>
                </div>
            </div> 
        </div> 
        </>
    );
}