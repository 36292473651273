import moment from "moment";
import React, { forwardRef } from 'react';
import { useEffect, useState } from "react";
import HistoricValueChart from './charts/historicValue';
import HistoricRevenueChart from './charts/historicRevenue';

const trailEmptyRowAfter = [
    "Occupancy (by area) across entire NSA",
    "Discount From Rack Rate",
    "'Other Income' % of Gross Revenue",
    "Monthly Storage Revenue",
    "Non-Storage Income",
    "Total Gross Revenue",
    "Annualised Total Revenue",
    "Adopted Total Expenses",
    "Adopted Net Operating Profit",
    "Storage Revenue Stabilisation Adjustment",
    "Applied Capitalisation Rate",
    "Core Indicative Market Value",
    "Other 'below the line' adjustment",
    // "Adopt Rounded Estimated Value",
];

const highlightHeadingsBackground = [
    "Occupancy (by area) across entire NSA",
    "Effective Storage Unit Revenue",
    "Annualised Average Storage Fee Rate ($/m²)",
    "Monthly Storage Revenue",
    "Total Gross Revenue",
    "Annualised Total Revenue",
    "Adopted Total Expenses",
    "Adopted Net Operating Profit",
    "Applied Capitalisation Rate",
    "Core Indicative Market Value",
    "Adopt Rounded Estimated Value"
]

const highlightRow = [
    "Area Occupied (m²)",
    "Effective Storage Unit Revenue",
    "Other Income",
    "Monthly Storage Revenue"
]

const noFormatHeadings = [
    "Total Net Storage Area (m²)",
    "Area Occupied (m²)",
    "WALE (Years ) by Lettable Area"
];

const feeRateHeadings = [
    "Monthly Average Storage Fee Rate ($/m²)",
    "Annualised Average Storage Fee Rate ($/m²)"
]

const highlightTextWithRed = [
    "Adopted Operating Expenses",
    "Adopted Management Fee",
    "Adopted Total Expenses",
    "Storage Revenue Stabilisation Adjustment",
    "Non-Storage Income Rental Adjustment",
    "Cost of Expansion",
    "Capital Expenditure",
]

const showValuesInPercent = [
    "Occupancy (by area) across entire NSA",
    "Discount From Rack Rate",
    "'Other Income' % of Gross Revenue",
    "% Gross Revenue",
    "Profit Margin",
    "Applied Capitalisation Rate",
    "% Passing Revenue of Mature Revenue",
    "Profit Margin",
]

const adjustedHeadings = [
    "% Gross Revenue",
    "Profit Margin",
    "$/m² of NSA",
    "Capital Value Rate $/m² of NSA",
    "WALE (Years ) by Lettable Area"
]

const FacilityPDF = forwardRef(({ facilityId, currentFacility, adoptedRoundedEstimatedValue, previousMonthEstimatedValuePercentage, currentYearApiData, averageUnitSize, averageUnitSizeOccupied, facilityValuation, annualisedTotalRevenueIndex, profitMargin, percentageGrossRevenue, perMetreSquareOfNSA, capitalValueRatePerMetreSquareOfNSA, wALEByLettableArea, historicValueFilters, historicRevenueFilters }, ref) => {
    const [customClass, setCustomClass] = useState("col-md-10 col-12 mb-20");

    useEffect(() => {
        const handleResize = () => {
            const screenWidth = window.innerWidth;
            const screenPercentage = (screenWidth / window.outerWidth) * 100;
            if ((screenPercentage == 100) || (screenPercentage == 90)) {
                setCustomClass("col-md-8 col-12 mb-20");
            }
            else {
                setCustomClass("col-md-8 col-12 mb-20");
            }
        };

        window.addEventListener('resize', handleResize);

        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const formatterWithoutDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const formatterWithDecimals = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    const formatValue = (value) => {
        const numericValue = parseFloat(value);
        const hasSixDigitsBeforeDecimal = Math.floor(Math.abs(numericValue)).toString().length >= 3;

        return hasSixDigitsBeforeDecimal
            ? formatterWithoutDecimals.format(value)
            : formatterWithDecimals.format(value);
    };
    
    return (
        <div ref={ref}>
            <div className="container-xxl countries-header layout-navbar navbar-detached z-index-auto">
                <div className="flex-grow-1 container-p-y" style={{ backgroundColor: "#f6f6f6" }}>
                    {/* Facility Name and Logo */}
                    <div className="row align-items-md-center ss-title px-3">
                        <div className="col-md-8 col-12">
                            <div className="d-flex align-items-center">
                                <img src={process.env.REACT_APP_BASE_URL + "assets/img/storval-logo.png"} alt="logo" height="40px" style={{ marginRight: '10px', marginLeft: '10px' }} />
                                <h3 className="fw-semi-bold text-center m-0 p-0">{currentFacility ? currentFacility.name : ""}</h3>
                            </div>
                        </div>
                    </div>
                    {/* Facility Name and Logo */}
                    <div className="container-xxl flex-grow-1 container-p-y" style={{ backgroundColor: "#f6f6f6" }}>
                        <div className="row mb-3 report-cardBody lineHeight">
                            {/* Property Summary Card*/}
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-20">
                                <div className="card card-border-shadow-primary h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0">Property Summary</h6>
                                        </div>
                                        <div className="card-details">
                                            <p>Address: {currentFacility?.address || 'N/A'}</p>
                                            <p>Title: {currentFacility?.titleReference || 'N/A'}</p>
                                            <p>Ownership: {currentFacility?.interestValued || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Estimated Value Card*/}
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-20">
                                <div className="card card-border-shadow-primary h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0">Estimated Value</h6>
                                        </div>
                                        <div className="d-flex align-items-center ">
                                            <div className="card-amount">{formatValue(adoptedRoundedEstimatedValue) + " " + currentFacility?.currency || 'N/A'} </div>
                                            <p className={`card-percentage fw-700 fs-color- ${previousMonthEstimatedValuePercentage > 0
                                                ? 'green' : 'red'}`}>
                                                {previousMonthEstimatedValuePercentage}%
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Optimisation Review Recommended Card*/}
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-20">
                                <div className="card card-border-shadow-primary h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0">Optimisation Review Recommended</h6>
                                        </div>
                                        <div className="ss-flex mt-27">
                                            <div
                                                className={`${currentFacility?.RecommendValuationIsVerified === "Yes" ? "text-danger" : ""} card-Allow`}
                                            >{currentFacility?.RecommendValuationIsVerified || ''}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Trading Status Card*/}
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-20">
                                <div className="card card-border-shadow-primary h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0">Trading Status</h6>
                                        </div>
                                        <div className="card-details">
                                            <div className="ss-flex">
                                                <p>Is trading at Maturity?</p>
                                                <p className="fw-700 fs-right">{currentFacility?.isFacilityTradingAtMaturity || ''}</p>
                                            </div>
                                            <div className="ss-flex">
                                                <p>Expected Maturity date</p>
                                                <p className="fw-700 fs-right">{moment().add(parseInt(currentFacility?.monthRevenueReachesMaturity), 'months').format('DD-MMM-YY')}</p>
                                            </div>
                                            <div className="ss-flex">
                                                <p>No. of months to Maturity</p>
                                                <p className="fs-right">{parseInt(currentFacility?.monthRevenueReachesMaturity) || 0}</p>
                                            </div>
                                            <div className="ss-flex">
                                                <p>Expansion included?</p>
                                                <p className="fs-right">{currentFacility?.isFacilityExpandedIn12Months || ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Facility Units Card*/}
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-20">
                                <div className="card card-border-shadow-primary h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0">Facility Units</h6>
                                        </div>
                                        <div className="card-details">
                                            <div className="ss-flex">
                                                <p>Total no. of units</p>
                                                <p className="fw-700 fs-right">{currentYearApiData?.[0]?.totalUnits ?? 0}</p>
                                            </div>
                                            <div className="ss-flex">
                                                <p>Total no. of units occupied</p>
                                                <p className="fs-right">{currentYearApiData?.[0]?.unitsOccupied ?? 0}</p>
                                            </div>
                                            <div className="ss-flex">
                                                <p>Avg unit size</p>
                                                <p className="fw-700 fs-right">{averageUnitSize.toFixed(2)}</p>
                                            </div>
                                            <div className="ss-flex">
                                                <p>Avg unit size occupied</p>
                                                <p className="fs-right">{averageUnitSizeOccupied.toFixed(2)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Net Storage Area Card*/}
                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 mb-20">
                                <div className="card card-border-shadow-primary h-100">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title d-flex align-items-baseline justify-content-between">
                                            <h6 className="d-block fw-12 mb-0">Net Storage Area</h6>
                                        </div>
                                        <div className="card-details">
                                            <div className="ss-flex">
                                                <p>Total Net Storage Area</p>
                                                <p className="fs-right">
                                                    {parseFloat(facilityValuation?.[0]?.adoptInValuation ?? 0).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </p>
                                            </div>
                                            <div className="ss-flex">
                                                <p>Area occupied</p>
                                                <p className="fs-right">
                                                    {parseFloat(facilityValuation?.[1]?.adoptInValuation ?? 0).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </p>
                                            </div>
                                            <div className="ss-flex">
                                                <p>Currency occupied by area</p>
                                                <p className="fw-700 fs-right">
                                                    {(facilityValuation?.[0]?.adoptInValuation ?? null) && (facilityValuation?.[1]?.adoptInValuation ?? null)
                                                        ? (parseFloat(facilityValuation[1].adoptInValuation) / parseFloat(facilityValuation[0].adoptInValuation) * 100).toFixed(2) + '%'
                                                        : '0.00%'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Bar Graphs */}
                            <div className="col-md-12 mb-20">
                                <div className="card card-border-shadow-primary">
                                    <div className="card-body ss-cardBody report-cardBody">
                                        <div className="card-title">
                                            <h3 className="d-block fw-12 mb-0">Historic Value Trend</h3>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-md-10 col-12 mb-20"> */}
                                            <div className={customClass}>
                                                <div className="text-center">
                                                    <div className="graph">
                                                        {currentFacility ?
                                                            <HistoricValueChart
                                                                facilityId={facilityId}
                                                                filterType={'monthly'}
                                                            />
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <ul className="nav ss-nav nav-tabs border-bottom" id="myTab" role="tablist">
                                                <li className={`nav-item ${historicValueFilters === "monthly" && "active"
                                                    }`}>
                                                    <button>Monthly</button>
                                                </li>
                                                <li className={`nav-item ${historicValueFilters === "6-monthly" && "active"
                                                    }`}>
                                                    <button>6 Monthly</button>
                                                </li>
                                                <li className={`nav-item ${historicValueFilters === "yearly" && "active"
                                                    }`}>
                                                    <button>Annually</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mb-20">
                                <div className="card card-border-shadow-primary">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title">
                                            <h3 className="d-block fw-12 mb-0">Historic Revenue Trend</h3>
                                        </div>
                                        <div className="row">
                                            {/* <div className="col-md-10 col-12 mb-20"> */}
                                            <div className={customClass}>
                                                {/* <div className="card-title d-flex align-items-baseline justify-content-between">
                                                </div> */}
                                                <div className="text-center">
                                                    <div className="graph">
                                                        {currentFacility ?
                                                            <HistoricRevenueChart
                                                                facilityId={facilityId}
                                                                filterType={historicRevenueFilters}
                                                            />
                                                            : ""}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <ul className="nav ss-nav nav-tabs border-bottom" id="myTab" role="tablist">
                                                <li className={`nav-item ${historicRevenueFilters === "monthly" && "active"
                                                    }`}>
                                                    <button>Monthly</button>
                                                </li>
                                                <li className={`nav-item ${historicRevenueFilters === "6-monthly" && "active"
                                                    }`}>
                                                    <button>6 Monthly</button>
                                                </li>
                                                <li className={`nav-item ${historicRevenueFilters === "yearly" && "active"
                                                    }`}>
                                                    <button>Annually</button>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Bar Graphs */}

                            {/* Table */}
                            <div className="facilityTable table-responsive ss-facilityTable ss-ReportfacilityTable" style={{ marginTop: '12px' }}>
                                <table className="table table-bordered" style={{ width: '100%', }}>
                                    <thead>
                                        <tr key="header_tr">
                                            <th style={{ width: '30%' }} className="table-color" scope="col">
                                                <div className="d-flex align-items-baseline">
                                                    Valuation / Performance Summary
                                                </div>
                                            </th>
                                            <th scope="col" style={{ width: '10%' }}>Adopt in<br /> Valuation</th>
                                            <th scope="col" style={{ width: '10%' }}>Actual</th>
                                            <th scope="col" style={{ width: '10%' }}>Actual /<br /> Passing</th>
                                            <th className="bg-table-facility tb-color" scope="col" style={{ width: '10%' }}>Actual Avg <br /> 3-months</th>
                                            <th className="bg-table-facility tb-color" scope="col" style={{ width: '10%' }}>Actual Avg <br /> 6-months</th>
                                            <th className="bg-table-facility tb-color" scope="col" style={{ width: '10%' }}>Actual  Avg <br />12-months</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {facilityValuation.map((values, keyIndex) => (
                                            <React.Fragment key={keyIndex}>
                                                <tr key={keyIndex}
                                                    // style={{ height: '45px' }}
                                                    style={{
                                                        height: ["% Gross Revenue", "Profit Margin", "$/m² of NSA", "Capital Value Rate $/m² of NSA", "WALE (Years ) by Lettable Area"].includes(values.heading) ? '0px' : '45px'
                                                    }}
                                                >
                                                    {/* Render Headings or an empty cell for adjusted headings in headings column */}
                                                    {adjustedHeadings.includes(values.heading) ? (
                                                        <td className="bg-table-facility centered-content"></td>
                                                    ) : (
                                                        <td className={
                                                            `${highlightHeadingsBackground.includes(values.heading) ? "theme-color-bg" : "bg-white"} 
                                                                                ${highlightRow.includes(values.heading) && "bg-table"}
                                                                                sticky-col first-col centered-content`
                                                        }>
                                                            {values.heading}
                                                        </td>
                                                    )}

                                                    {/* Render Adopt in Valuation Values or an empty cell for adjusted headings in adopt in valuation column*/}
                                                    {adjustedHeadings.includes(values.heading) ? (
                                                        <td className="bg-table-facility centered-content"></td>
                                                    ) : (
                                                        <td className={`bg-white fw-700 centered-content
                                                                ${highlightTextWithRed.includes(values.heading) ? "color-red-table" : ""} 
                                                                ${values.heading === 'Adopt Rounded Estimated Value' ? 'theme-color-bg' : ''}
                                                                ${highlightRow.includes(values.heading) && "bg-table"}
                                                                `}
                                                        >
                                                            {showValuesInPercent.includes(values.heading) && !isNaN(parseFloat(values.adoptInValuation))
                                                                ? (`${parseFloat(values.adoptInValuation).toFixed(2)}%`)
                                                                : (
                                                                    values.adoptInValuation === ''
                                                                        ? (" ")
                                                                        : (
                                                                            noFormatHeadings.includes(values.heading)
                                                                                ? (parseFloat(values.adoptInValuation).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                                                                                : (
                                                                                    feeRateHeadings.includes(values.heading)
                                                                                        ? formatterWithDecimals.format(values.adoptInValuation)
                                                                                        : formatterWithoutDecimals.format(values.adoptInValuation)
                                                                                )
                                                                        )
                                                                )
                                                            }
                                                        </td>
                                                    )}

                                                    {/* % Gross Revenue Row */}
                                                    {values.heading === 'Adopted Total Expenses' ? (
                                                        <>
                                                            <td className="bg-table centered-content" >
                                                                % Gross Revenue
                                                            </td>
                                                            <td className="bg-white centered-content">
                                                                {!isNaN(parseFloat(percentageGrossRevenue)) ? (
                                                                    parseFloat(percentageGrossRevenue).toFixed(2) + "%"
                                                                ) : ''}
                                                            </td>
                                                        </>
                                                    ) : ''}

                                                    {/* Profit Margin Row */}
                                                    {values.heading === 'Adopted Net Operating Profit' ? (
                                                        <>
                                                            <td className="bg-table centered-content" colSpan={1}>
                                                                Profit Margin
                                                            </td>
                                                            <td className="bg-white centered-content">
                                                                {!isNaN(parseFloat(profitMargin)) ? (
                                                                    parseFloat(profitMargin).toFixed(2) + "%"
                                                                ) : ''}
                                                            </td>
                                                        </>
                                                    ) : ''}

                                                    {/* $/m² of NSA Row */}
                                                    {values.heading === 'Core Indicative Market Value' ? (
                                                        <>
                                                            <td className="bg-table centered-content" colSpan={1}>
                                                                $/m² of NSA
                                                            </td>
                                                            <td className="bg-white centered-content">
                                                                {!isNaN(parseFloat(perMetreSquareOfNSA)) ? (
                                                                    formatValue(parseFloat(perMetreSquareOfNSA))
                                                                ) : ''}
                                                            </td>
                                                        </>
                                                    ) : ''}

                                                    {/* Capital Value Rate $/m² of NSA Row */}
                                                    {values.heading === 'Adopt Rounded Estimated Value' ? (
                                                        <>
                                                            <td className="bg-table centered-content" colSpan={2}>
                                                                Capital Value Rate $/m² of NSA
                                                            </td>
                                                            <td className="bg-white centered-content">
                                                                {!isNaN(parseFloat(capitalValueRatePerMetreSquareOfNSA)) ? (
                                                                    formatValue(parseFloat(capitalValueRatePerMetreSquareOfNSA))
                                                                ) : ''}
                                                            </td>
                                                        </>
                                                    ) : ''}

                                                    {/* Render Actual Values */}
                                                    <td className={`bg-white centered-content
                                                                            ${highlightTextWithRed.includes(values.heading) ? "color-red-table" : ""} 
                                                                            ${values.actual === '' ? "bg-table-facility border-remove" : ""}
                                                                            ${highlightRow.includes(values.heading) && "bg-table"}
                                                                            `}
                                                    >
                                                        {showValuesInPercent.includes(values.heading) && values.actual !== ''
                                                            ? (`${parseFloat(values.actual).toFixed(2)}%`)
                                                            : (
                                                                values.actual === ''
                                                                    ? ("")
                                                                    : (
                                                                        noFormatHeadings.includes(values.heading)
                                                                            ? (parseFloat(values.actual).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                                                                            : (
                                                                                feeRateHeadings.includes(values.heading)
                                                                                    ? formatterWithDecimals.format(values.actual)
                                                                                    : formatterWithoutDecimals.format(values.actual)
                                                                            )
                                                                    )
                                                            )
                                                        }
                                                    </td>

                                                    {/* Render Actual By Passing Values */}
                                                    <td className={`bg-white centered-content
                                                                            ${highlightTextWithRed.includes(values.heading) ? "color-red-table" : ""} 
                                                                            ${values.actual === '' ? "bg-table-facility border-remove" : ""}
                                                                            ${highlightRow.includes(values.heading) && "bg-table"} 
                                                                            `}
                                                    >
                                                        {values.actualByPassing && values.actualByPassing !== null
                                                            ? (`${parseFloat(values.actualByPassing).toFixed(2)}%`)
                                                            : (
                                                                keyIndex <= annualisedTotalRevenueIndex
                                                                    ? (values.actualByPassing === '' ? (" ") : '0.00%')
                                                                    : ('')
                                                            )
                                                        }
                                                    </td>

                                                    {/* WALE (Years) by Lettable Area Value */}
                                                    {values.heading === 'Non-Storage Income' ? (
                                                        <>
                                                            <td className="bg-table centered-content" colSpan={2}>
                                                                WALE (Years) by Lettable Area
                                                            </td>
                                                            <td className="bg-white centered-content">
                                                                {wALEByLettableArea}
                                                            </td>
                                                        </>
                                                    ) : ''}

                                                    {/* Render Past 3 Months Average Values */}
                                                    <td className={`bg-white centered-content
                                                                            ${highlightTextWithRed.includes(values.heading) ? "color-red-table" : ""} 
                                                                            ${values.actualPast3MonthsAvg === '' ? "bg-table-facility border-remove" : ""}
                                                                            ${values.actual === '' ? "bg-table-facility border-remove" : ""}
                                                                            ${highlightRow.includes(values.heading) && "bg-table"} 
                                                                            `}
                                                    >
                                                        {showValuesInPercent.includes(values.heading) && values.actualPast3MonthsAvg !== ''
                                                            ? (`${parseFloat(values.actualPast3MonthsAvg).toFixed(2)}%`)
                                                            : (
                                                                values.actualPast3MonthsAvg === ''
                                                                    ? ("")
                                                                    : (
                                                                        noFormatHeadings.includes(values.heading)
                                                                            ? (parseFloat(values.actualPast3MonthsAvg).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                                                                            : (
                                                                                feeRateHeadings.includes(values.heading)
                                                                                    ? formatterWithDecimals.format(values.actualPast3MonthsAvg)
                                                                                    : formatterWithoutDecimals.format(values.actualPast3MonthsAvg)
                                                                            )
                                                                    )
                                                            )
                                                        }
                                                    </td>

                                                    {/* Render Actual Past 6 Months Average Values */}
                                                    <td className={`bg-white centered-content
                                                                            ${highlightTextWithRed.includes(values.heading) ? "color-red-table" : ""} 
                                                                            ${values.actualPast6MonthsAvg === '' ? "bg-table-facility" : ""}
                                                                            ${values.actual === '' ? "bg-table-facility" : ""}
                                                                            ${highlightRow.includes(values.heading) && "bg-table"} 
                                                                            `}
                                                    >
                                                        {showValuesInPercent.includes(values.heading) && values.actualPast6MonthsAvg !== ''
                                                            ? (`${parseFloat(values.actualPast6MonthsAvg).toFixed(2)}%`)
                                                            : (
                                                                values.actualPast6MonthsAvg === ''
                                                                    ? ("")
                                                                    : (
                                                                        noFormatHeadings.includes(values.heading)
                                                                            ? (parseFloat(values.actualPast6MonthsAvg).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                                                                            : (
                                                                                feeRateHeadings.includes(values.heading)
                                                                                    ? formatterWithDecimals.format(values.actualPast6MonthsAvg)
                                                                                    : formatterWithoutDecimals.format(values.actualPast6MonthsAvg)
                                                                            )
                                                                    )
                                                            )
                                                        }
                                                    </td>

                                                    {/* Render Actual Past 12 Months Average Values */}
                                                    <td className={`bg-white centered-content
                                                                            ${highlightTextWithRed.includes(values.heading) ? "color-red-table" : ""} 
                                                                            ${values.actualPast12MonthsAvg === '' ? "bg-table-facility" : ""}
                                                                            ${values.actual === '' ? "bg-table-facility" : ""}
                                                                            ${highlightRow.includes(values.heading) && "bg-table"} 
                                                                            `}
                                                    >
                                                        {showValuesInPercent.includes(values.heading) && values.actualPast12MonthsAvg !== ''
                                                            ? (`${parseFloat(values.actualPast12MonthsAvg).toFixed(2)}%`)
                                                            : (
                                                                values.actualPast12MonthsAvg === ''
                                                                    ? ("")
                                                                    : (
                                                                        noFormatHeadings.includes(values.heading)
                                                                            ? (parseFloat(values.actualPast12MonthsAvg).toLocaleString('en-AU', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
                                                                            : (
                                                                                feeRateHeadings.includes(values.heading)
                                                                                    ? formatterWithDecimals.format(values.actualPast12MonthsAvg)
                                                                                    : formatterWithoutDecimals.format(values.actualPast12MonthsAvg)
                                                                            )
                                                                    )
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                {trailEmptyRowAfter.includes(values.heading) &&
                                                    !['Core Indicative Market Value', 'Adopted Net Operating Profit', 'Adopted Total Expenses'].includes(values.heading) && (
                                                        <React.Fragment key={`empty_1row`}>
                                                            <tr key={`empty_1row`} height="35px">
                                                                <td colSpan={7} className="bg-table-facility"></td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {/* Table */}

                            {/* Disclaimer */}
                            <div className="col-md-12 mb-20">
                                <div className="card">
                                    <div className="card-body ss-cardBody">
                                        <div className="card-title">
                                            <h3 className="d-block fw-12 mb-0">
                                                Disclaimer
                                            </h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 col-12 mb-20 card-body">
                                                <p>
                                                    This indicative assessment of value is an estimate only and provides the “estimated market value (EMV)” of the
                                                    facility on a going concern (self storage use) basis. <span className="border-bottom-disclaimer">It does not constitute a formal valuation.</span> The EMV is based off
                                                    third party trading performance data and user inputs. Specific research into the property and the trading catchment
                                                    has not been undertaken. The EMV does not take into account any property specific attributes or external factors
                                                    which may impact value. An inspection of the facility has not occurred.
                                                </p>
                                                <p>
                                                    The EMV is a desktop estimate that can only be used by the licensee (“user”) as a tool for informative purposes. It
                                                    should not be relied upon for decision-making purposes. Specifically, the EMV must not be used, and is not suitable
                                                    for, mortgage security purposes, transaction purposes or investment decisions.
                                                </p>
                                                <p>
                                                    The EMV is based upon financial data provided by a third party and inputs by the user. Storval Pty Ltd does not make
                                                    any warranty as to the accuracy, completeness or reliability of the information; nor does Storval Pty Ltd accept any
                                                    liability arising in any way from any omissions or errors. The EMV should not be regarded as advice, nor should it be
                                                    relied upon by any party. Storval is an estimation tool only. Professional valuation advice is recommended before
                                                    making any investment decisions. A full valuation that adheres to the International Valuation Standards, inclusive of
                                                    a physical inspection, is recommended to confirm the estimated assessment shown.</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Disclaimer */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default FacilityPDF;