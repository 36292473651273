import React, { useEffect, useState } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from "react-chartjs-2";
import axios from "axios";
import Swal from "sweetalert2";
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, Title, Tooltip, Legend);

export default function HistoricRevenueChart({facilityId, filterType, ...rest}) {
    const [loading, setLoading] = useState(false);
    const [historicData, setHistoricData] = useState("");
    const formatter = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
    });

    useEffect(() => {
        if(parseInt(facilityId)){
            getHistoricChartData(facilityId);
        }
    }, [facilityId, filterType])

    const getHistoricChartData = async (facilityId) => {
        let condition = `?facilityId=${facilityId}&filterType=${filterType}`;
        setLoading(true);
        try {
            const response = await axios.get(`/api/facility/historicChartData${condition}`);
            setLoading(false)
            let result = response.data;
            if (result.success) {
                setHistoricData(result.data.historicRevenueData);
            } else {
                Swal.fire("Facility Details Missing!", result.message, "info");
            }
        } catch (err) {
            setLoading(false)
            Swal.fire("Error", err.message, "error");
        }
    };

    const labels = Array.isArray(historicData) ? historicData.map(data => data.period) : [];
    const totalRevenues = Array.isArray(historicData) ? historicData.map(data => data.totalRevenue) : [];
    const netOperatingProfits = Array.isArray(historicData) ? historicData.map(data => data.netOperatingProfit) : [];

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Monthly Gross Revenue",
                data: totalRevenues,
                backgroundColor: "#8ed973",
                borderColor: "#8ed973",
                // borderWidth: 1.5,
                categoryPercentage: 0.7,
                barPercentage: 0.7,
                order: 1,
            },
            {
                label: "Monthly Net Operating Profit",
                data: netOperatingProfits,
                backgroundColor: "#196b24",
                borderColor: "#196b24",
                // borderWidth: 1.5,
                categoryPercentage: 0.7,
                barPercentage: 0.7,
                order: 1,
            }
        ]
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    beginAtZero: false,
                    autoSkip: false,
                },
            },
            y: {
                grid: {
                    display: true,
                    color: '#dddddd75'
                },
                ticks: {
                    // beginAtZero: true,
                    callback: function (value) {
                        return formatter.format(value); // Formatting for dollar values
                    },
                    // stepSize: 10000, 
                    // suggestedMin: 0,
                    // suggestedMax: 1800000,
                    // ticks: [1000000, 1100000, 1200000, 1300000, 1400000, 1500000, 1600000, 1700000, 1800000]
                },
                // min: 10000,
                // max: 60000,
            },
        },
        elements: {
            point: {
                radius: 2
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: "start",
                labels: {
                    boxHeight: 7,
                    //   usePointStyle: true,
                    boxWidth: 28,
                    color: 'black',
                    font: {
                        family: 'Poppins',
                        size: 10,
                    },
                    padding: 20,
                },
            },
            title: {
                display: true,
                align: "start",
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            if (context.dataset.label) {
                                label += formatter.format(context.parsed.y); // Format as currency
                            }
                        }
                        return label;
                    }
                }
            }
        },
    };

    return (
        <div className="App" style={{ height: '400px', width: "100%" }}>
            <Bar data={data} options={options} />
        </div>
    );
}